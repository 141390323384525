import type { StyleFunctionProps } from '@chakra-ui/react'

const styles = {
  global: (props: StyleFunctionProps) => ({
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      listStyle: 'none',
    },
    'html, body, #__next': {
      minHeight: '100vh',
      background: 'white',
      fontFamily: 'Rawline, sans-serif',
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
  }),
}

export default styles
