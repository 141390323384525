import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { getAnalysisMyVolumes, getFormAnalysisVolume } from './services'
import {
  TGetAnalysisMyVolumesParams,
  TGetAnalysisMyVolumesResponse,
  TGetFormAnalysisEvaluationSupervisor,
  TGetFormAnalysisVolume,
  TGetFormAnalysisVolumeParams,
} from './types'

export const useQueryGetFormAnalysisVolume = (
  params: TGetFormAnalysisVolumeParams,
  options?: UseQueryOptions<TGetFormAnalysisVolume, AxiosError<Error>>
) => {
  const queryFunction = () => getFormAnalysisVolume(params)

  return useQuery<TGetFormAnalysisVolume, AxiosError<Error>>(
    useQueryGetFormAnalysisVolume.getFullQueryKey(params),
    queryFunction,
    options
  )
}

useQueryGetFormAnalysisVolume.getFullQueryKey = (
  params: TGetFormAnalysisVolumeParams
): QueryKey => ['formAnalysisVolume', params]

useQueryGetFormAnalysisVolume.queryKey = ['formAnalysisVolume']

export const useQueryGetAnalysisMyVolumes = (
  params: TGetAnalysisMyVolumesParams,
  options?: UseQueryOptions<TGetAnalysisMyVolumesResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getAnalysisMyVolumes(params)

  return useQuery<TGetAnalysisMyVolumesResponse, AxiosError<Error>>(
    useQueryGetAnalysisMyVolumes.getFullQueryKey(params),
    queryFunction,
    options
  )
}

useQueryGetAnalysisMyVolumes.getFullQueryKey = (
  params: TGetAnalysisMyVolumesParams
): QueryKey => ['analysisMyVolumes', params]

useQueryGetAnalysisMyVolumes.queryKey = ['analysisMyVolumes']

export const useQueryFormAnalysisEvaluationSupervisor = (
  params: TGetFormAnalysisVolumeParams,
  options?: UseQueryOptions<
    TGetFormAnalysisEvaluationSupervisor,
    AxiosError<Error>
  >
) => {
  const queryFunction = () => getFormAnalysisVolume(params)

  return useQuery<TGetFormAnalysisEvaluationSupervisor, AxiosError<Error>>(
    useQueryFormAnalysisEvaluationSupervisor.getFullQueryKey(params),
    queryFunction,
    options
  )
}

useQueryFormAnalysisEvaluationSupervisor.getFullQueryKey = (
  params: TGetFormAnalysisVolumeParams
): QueryKey => ['answersAnalysisEvaluationSupervisor', params]

useQueryFormAnalysisEvaluationSupervisor.queryKey = [
  'answersAnalysisEvaluationSupervisor',
]
