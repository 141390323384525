import React, { useState, useMemo } from 'react'
import { FaCircleCheck, FaChevronLeft } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import { useMutationPostDeeds } from '@/api/deeds/mutations'
import DataTable from '@/components/DataTable'

import { IObjectFileData } from '../ManualImport/types'
import {
  countFormats,
  mapCsvToTable,
  processHeaders,
  renameKeys,
} from './controller'
import ConfirmModal from './parts/confirmModal'
import DetailsModal from './parts/detailsModal'
import { ICollectionDetails } from './parts/detailsModal/types'
import { IRowData, VerifyImportProps } from './types'

const VerifyImport: React.FC<VerifyImportProps> = ({
  activeStep,
  setActiveStep,
  data,
}) => {
  const navigate = useNavigate()
  const toast = useToast()
  const [selectedRow, setSelectedRow] = useState({} as ICollectionDetails)
  const [isLoading, setIsLoading] = useState(Boolean<true>)

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure()

  const {
    isOpen: isDetailsOpen,
    onOpen: onDetailsOpen,
    onClose: onDetailsClose,
  } = useDisclosure()

  const handleConfirm = () => {
    onConfirmOpen()
  }

  const mapTableData = useMemo(() => {
    const values = mapCsvToTable(data)
    setIsLoading(!isLoading)
    return values
  }, [data])

  const mapHeaders = useMemo(() => {
    const countFormat = countFormats(mapTableData)
    const headers = processHeaders(countFormat)
    return headers
  }, [data])

  const { mutate: postDeeds, isLoading: isUpdating } = useMutationPostDeeds({
    onSuccess: () => {
      toast({
        title: 'CSV importado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      navigate('/objetos')
    },
    onError: () => {
      toast({
        title: 'Houve um erro ao cadastrar o CSV, tente novamente mais tarde',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  if (isUpdating) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        h="full"
      >
        <Text p="1" align="center">
          Salvando dados do arquivo. <br />
          Essa ação pode demorar alguns minutos.
        </Text>
        <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
      </Flex>
    )
  }

  const handleSubmit = (data: IObjectFileData[]) => {
    const renamedData = renameKeys(data)
    postDeeds(renamedData)
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-between"
      w="full"
      h="full"
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        gap="4"
        py="60px"
      >
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="4"
        >
          Verifique os dados do objeto
        </Text>
        <Box>
          <Text fontSize="12px" fontWeight="400" lineHeight="18px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </Text>

          <DataTable
            rowId="collectionCode"
            key="table-verify-import"
            data={mapTableData}
            headers={mapHeaders}
            selectableRow={false}
            onRowClick={row => {
              const handleRow = row.original as IRowData
              setSelectedRow(handleRow.modalData)
              onDetailsOpen()
            }}
          />
        </Box>
      </Flex>

      <Flex direction="row" justify="space-between" w="full">
        <Button
          color="brand.primary.dark_1"
          bg="white"
          _hover={{ bg: 'brand.primary.dark_2' }}
          gap="2"
          py="2"
          px="6"
          borderRadius="4"
          leftIcon={<Icon as={FaChevronLeft} />}
          onClick={() => setActiveStep(activeStep - 1)}
        >
          Voltar
        </Button>

        <Button
          color="white"
          bg="brand.success.base"
          _hover={{ bg: 'brand.success.dark' }}
          gap="2"
          py="2"
          px="6"
          borderRadius="4"
          leftIcon={<Icon as={FaCircleCheck} />}
          onClick={() => handleConfirm()}
          isLoading={isUpdating}
          loadingText="Enviando"
        >
          Importar objeto
        </Button>
      </Flex>
      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        onConfirm={() => handleSubmit(data)}
      />
      <DetailsModal
        isOpen={isDetailsOpen}
        onClose={onDetailsClose}
        data={selectedRow}
      />
    </Flex>
  )
}

export default VerifyImport
