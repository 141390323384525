import { QuestionManagementProvider } from '../QuestionManagementContext'
import { SidebarContextProvider } from '../SidebarContext/SidebarContext'
import { AuthProvider } from '../UserAuthContext/UserAuthContext'
import { composeProviders } from './Compose/ComposeProviders'

export const AllProvidersContext = composeProviders(
  AuthProvider,
  SidebarContextProvider,
  QuestionManagementProvider
)
