import { status } from '@/components/Tag/StatusUserSystem/types'

export interface IUser {
  name?: string
  cpf: string
  email: string
  statusCode?: string
  ncoProfile?: number[]
}

export interface IUserDetailsProps {
  userId: string
  onSuccess?: () => void
}

export const IUserStatusMapping: Record<string, status | 'default'> = {
  Ativo: 'ATIVO',
  Indisponível: 'INDISPONIVEL',
  Disponível: 'DISPONIVEL',
  'Pré-cadastro': 'PRE_CADASTRO',
}
