import React from 'react'

import { Box, SimpleGrid, Stack } from '@chakra-ui/react'

import { Input, BasicSelect, InputWithMask } from '@/components/Forms'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

import S from '../styles'

const PersonalInformation: React.FC = () => {
  const {
    isLoadingUserData,
    specialNeedsOptions,
    educationLevelsOptions,
    countries,
    states,
    cities,
    isLoadingCountries,
    isLoadingStates,
    isLoadingCities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    isStateSelectDisabled,
    isCitySelectDisabled,
  } = useProfileContext()

  return (
    <Box width="100%">
      <Stack gap={8}>
        <SimpleGrid columns={2} spacing={4}>
          <Input
            name="noUserName"
            label="Nome"
            placeholder="Nome"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="noUserSocialName"
            label="Nome Social"
            placeholder="Digite seu nome social"
            isLoading={isLoadingUserData}
          />
          <InputWithMask
            name="dsCpf"
            label="CPF"
            mask="999.999.999-99"
            placeholder="000.000.000-00"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dtBirthDate"
            label="Data de nascimento"
            helperText="Obrigatório"
            type="date"
            inputProps={{ max: '2999-12-31' }}
            isLoading={isLoadingUserData}
          />
          <BasicSelect
            name="coSpecialNeed"
            label="Deficiência"
            placeholder="Selecione uma opção"
            helperText="Obrigatório"
            options={specialNeedsOptions}
            isLoading={isLoadingUserData}
          />
          <BasicSelect
            name="coEducationLevel"
            label="Nível de Escolaridade"
            placeholder="Selecione uma opção"
            helperText="Obrigatório"
            options={educationLevelsOptions}
            isLoading={isLoadingUserData}
          />
        </SimpleGrid>
        <Stack gap={4}>
          <S.HeaderText>Contatos</S.HeaderText>
          <SimpleGrid columns={2} spacing={4}>
            <Input
              name="dsInstitutionalEmail"
              label="E-mail institucional"
              placeholder="nome@nees.ufal.br"
              helperText="Obrigatório. Escreva aqui o e-mail que deseja receber notificações do SARE."
              type="email"
              isLoading={isLoadingUserData}
            />
            <Input
              name="dsGovbrEmail"
              label="E-mail do gov.br"
              placeholder="nome@email.com"
              type="text"
              isDisabled
              isLoading={isLoadingUserData}
            />
            <InputWithMask
              name="dsCellphone"
              label="Telefone"
              mask={['+55 (99) 9999-9999', '+55 (99) 99999-9999']}
              placeholder="+55 (99) 99999-9999"
              helperText="Obrigatório"
              type="tel"
              isLoading={isLoadingUserData}
            />
          </SimpleGrid>
        </Stack>
        <Stack gap={4}>
          <S.HeaderText>Endereço</S.HeaderText>
          <SimpleGrid columns={3} spacing={4}>
            <BasicSelect
              name="dsCountry"
              label="País"
              placeholder="Selecione um país"
              helperText="Obrigatório"
              options={countries}
              onChange={value => setSelectedCountry(value)}
              isDisabled={isLoadingCountries}
              isLoading={isLoadingUserData}
            />
            <BasicSelect
              name="dsUfProvince"
              label="Estado"
              placeholder="Selecione um estado"
              helperText="Obrigatório"
              options={states}
              onChange={value => setSelectedState(value)}
              isDisabled={isStateSelectDisabled || isLoadingStates}
              isLoading={
                isLoadingUserData || (isLoadingStates && !isStateSelectDisabled)
              }
            />
            <BasicSelect
              name="dsCity"
              label="Cidade"
              placeholder="Selecione uma cidade"
              helperText="Obrigatório"
              options={cities}
              onChange={value => setSelectedCity(value)}
              isDisabled={isCitySelectDisabled || isLoadingCities}
              isLoading={
                isLoadingUserData || (isLoadingCities && !isCitySelectDisabled)
              }
            />
          </SimpleGrid>
        </Stack>
      </Stack>
    </Box>
  )
}

export default PersonalInformation
