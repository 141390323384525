import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'

import { Stack, HStack } from '@chakra-ui/react'

import { postAuthRefresh } from '@/api/auth/services'
import LayoutLoading from '@/common/Layouts/LayoutLoading'
import { sidebarProps, useBreadcrumbProps } from '@/data/template'
import { stringToBoolean } from '@/utils/string'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

const REFRESH_MARGIN = 5 * 60

const TemplatePage: React.FC = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const breadcrumbProps = useBreadcrumbProps()

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh-token')

    if (!refreshToken) {
      setIsAuthenticated(false)
      return
    }

    try {
      const response = await postAuthRefresh({ refresh: refreshToken })
      localStorage.setItem('access-token', response.access)
      setIsAuthenticated(true)

      scheduleTokenRefresh(response.access)
    } catch (error) {
      setIsAuthenticated(false)
    }
  }

  const scheduleTokenRefresh = (token: string) => {
    try {
      const { exp, iat } = jwtDecode<{ exp: number; iat: number }>(token)
      const currentTime = Math.floor(Date.now() / 1000)
      const tokenLifetime = exp - iat
      const timeToExpiration = exp - currentTime

      const refreshTime = Math.max(
        tokenLifetime * 0.5,
        timeToExpiration - REFRESH_MARGIN
      )

      if (timeToExpiration > REFRESH_MARGIN) {
        setTimeout(refreshAccessToken, refreshTime * 1000)
      } else {
        refreshAccessToken()
      }
    } catch {
      setIsAuthenticated(false)
    }
  }

  const checkAuthentication = async () => {
    const accessToken = localStorage.getItem('access-token')
    const refreshToken = localStorage.getItem('refresh-token')

    if (!accessToken || !refreshToken) {
      setIsAuthenticated(false)
      setIsAuthenticating(false)
      return
    }

    try {
      scheduleTokenRefresh(accessToken)
      setIsAuthenticated(true)
    } catch (error) {
      refreshAccessToken()
    }

    setIsAuthenticating(false)
  }

  useEffect(() => {
    if (!stringToBoolean(process.env.REACT_APP_DISABLE_AUTH)) {
      checkAuthentication()
    } else {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    }
  }, [])

  if (isAuthenticating) {
    return <LayoutLoading />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return (
    <Sidebar {...sidebarProps}>
      <Stack alignItems="center" flexGrow={1}>
        <Stack flexGrow={1} spacing={4} width="100%" maxWidth="container.xl">
          <HStack justifyContent="space-between">
            <Breadcrumbs {...breadcrumbProps} />
          </HStack>
          <Outlet />
        </Stack>
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
