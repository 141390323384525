import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import {
  getFormQuestions,
  getFormQuestionById,
  getFormQuestionFieldValues,
} from './services'
import {
  TGetFormQuestionsParams,
  TGetFormQuestionsResponse,
  TGetFormQuestionByIdParams,
  TGetFormQuestionByIdResponse,
  TGetFormQuestionFieldValuesResponse,
} from './types'

export const useQueryGetFormQuestions = (
  filters: TGetFormQuestionsParams,
  options?: UseQueryOptions<TGetFormQuestionsResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getFormQuestions(filters)

  return useQuery<TGetFormQuestionsResponse, AxiosError<Error>>(
    useQueryGetFormQuestions.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetFormQuestions.getFullQueryKey = (
  filters: TGetFormQuestionsParams
): QueryKey => ['formQuestions', filters]

useQueryGetFormQuestions.queryKey = ['formQuestions']

export const useQueryGetFormQuestionById = (
  params: TGetFormQuestionByIdParams,
  options?: UseQueryOptions<TGetFormQuestionByIdResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getFormQuestionById(params)

  return useQuery<TGetFormQuestionByIdResponse, AxiosError<Error>>(
    useQueryGetFormQuestionById.getFullQueryKey(params),
    queryFunction,
    options
  )
}

useQueryGetFormQuestionById.getFullQueryKey = (
  params: TGetFormQuestionByIdParams
): QueryKey => ['formQuestion', params.coFormQuestion]

useQueryGetFormQuestionById.queryKey = ['formQuestion']

export const useQueryGetFormQuestionFieldValues = (
  options?: UseQueryOptions<
    TGetFormQuestionFieldValuesResponse,
    AxiosError<Error>
  >
) => {
  return useQuery<TGetFormQuestionFieldValuesResponse, AxiosError<Error>>(
    useQueryGetFormQuestionFieldValues.queryKey,
    getFormQuestionFieldValues,
    options
  )
}

useQueryGetFormQuestionFieldValues.queryKey = ['formQuestionFieldValues']
