import { useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'

import {
  useMutationActivateFormQuestion,
  useMutationPatchFormQuestion,
} from '@/api/forms/questions/mutations'
import {
  useQueryGetFormQuestionById,
  useQueryGetFormQuestionFieldValues,
} from '@/api/forms/questions/queries'
import { FormQuestion } from '@/types/form.types'
import { getErrorDetails } from '@/utils/error'

import { ISelectOptions } from '../Questions/parts/RegisterQuestionModal/types'
import { TQuestionDetailsFormValues } from './types'

export const useQuestionDetailsController = (coQuestion: string) => {
  const {
    data: questionData,
    isLoading: isLoadingQuestion,
    isError: isErrorQuestion,
    error: errorQuestion,
  } = useQueryGetFormQuestionById(
    { coFormQuestion: Number(coQuestion) },
    {
      enabled: !!coQuestion,
      refetchOnWindowFocus: false,
    }
  )

  const {
    data: fieldValues,
    isLoading: isLoadingFields,
    isError: isErrorFields,
    error: errorFields,
  } = useQueryGetFormQuestionFieldValues()

  const selectOptions: ISelectOptions = {
    formats:
      fieldValues?.formats.map(format => ({
        value: format.coFormat.toString(),
        label: format.dsFormat,
      })) ?? [],
    exemplaries:
      fieldValues?.exemplaries.map(exemplary => ({
        value: exemplary.coExemplary.toString(),
        label: exemplary.dsExemplary,
      })) ?? [],
    failureTypes:
      fieldValues?.failureTypes.map(type => ({
        value: type.coFormFailureType.toString(),
        label: type.dsFailureType,
      })) ?? [],
    answerModels:
      fieldValues?.answerTypes.map(answer => ({
        value: answer.tpAnswer.toString(),
        label: answer.dsAnswerType,
      })) ?? [],
    volumeVersion: [
      {
        value: 'CARACTERIZADO',
        label: 'Caracterizado',
      },
      {
        value: 'DESCARACTERIZADO',
        label: 'Descaracterizado',
      },
    ],
    showPages: [
      { value: 'true', label: 'Sim' },
      { value: 'false', label: 'Não' },
    ],
  }

  const mapQuestionToFormValues = (
    question: FormQuestion
  ): TQuestionDetailsFormValues => {
    return {
      title: question.dsQuestion,
      formatVolume: question.coFormat?.toString() ?? '',
      versionVolume: question.dsVolumeVersion ?? '',
      exemplaryVolume: question.coExemplary?.toString() ?? '',
      diligence: question.failureType.coFormFailureType?.toString() ?? '',
      answerModel: question.answerType.tpAnswer?.toString() ?? '',
      description: question.dsHelpText ?? '',
    }
  }

  const initialFormValues: TQuestionDetailsFormValues = questionData
    ? mapQuestionToFormValues(questionData)
    : {
        title: '',
        formatVolume: '',
        versionVolume: '',
        exemplaryVolume: '',
        diligence: '',
        answerModel: '',
        description: '',
      }

  const isLoading = isLoadingQuestion || isLoadingFields
  const isError = isErrorQuestion || isErrorFields
  const error = errorQuestion || errorFields

  return {
    questionData,
    isLoading,
    isError,
    error,
    initialFormValues,
    selectOptions,
  }
}

export const useUpdateQuestionController = (coFormQuestion: string) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { mutate: updateQuestion, isLoading: isUpdating } =
    useMutationPatchFormQuestion(Number(coFormQuestion), {
      onSuccess: async () => {
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'Questão atualizada com sucesso!',
          isClosable: true,
        })
        await queryClient.invalidateQueries(['formQuestions'])
        await queryClient.invalidateQueries([
          'formQuestion',
          Number(coFormQuestion),
        ])
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const handleSubmit = (
    values: TQuestionDetailsFormValues,
    isActiveQuestion: boolean
  ) => {
    updateQuestion({
      dsQuestion: values.title,
      coFormat: Number(values.formatVolume),
      coExemplary: Number(values.exemplaryVolume),
      coFormFailureType: Number(values.diligence),
      tpAnswer: Number(values.answerModel),
      dsHelpText: values.description,
      dsVolumeVersion: values.versionVolume,
      // TODO: Avaliar se vai ser possível alterar esse campo
      // stActive: isActiveQuestion,
      // stInformPage: true,
    })
  }

  return {
    handleSubmit,
    isUpdating,
  }
}

type ActivationMutationContext = {
  previousQuestion: FormQuestion | undefined
}

export const useActivateQuestionController = (questionId: string) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const questionKey = ['formQuestion', Number(questionId)]

  const { mutate: activateQuestion, isLoading } =
    useMutationActivateFormQuestion({
      onMutate: async ({ stActive }) => {
        await queryClient.cancelQueries(questionKey)
        const previousQuestion =
          queryClient.getQueryData<FormQuestion>(questionKey)

        queryClient.setQueryData<FormQuestion>(questionKey, old =>
          old ? { ...old, stActive: stActive } : old
        )

        return { previousQuestion }
      },

      onError: (err, _, context) => {
        const typedContext = context as ActivationMutationContext
        if (typedContext?.previousQuestion) {
          queryClient.setQueryData<FormQuestion>(
            questionKey,
            typedContext.previousQuestion
          )
        }

        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(err),
          isClosable: true,
        })
      },

      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'Status da questão atualizado com sucesso!',
          isClosable: true,
        })

        queryClient.invalidateQueries(['formQuestions'])
      },
    })

  const handleActivation = (isActive: boolean) => {
    activateQuestion({
      coFormQuestion: Number(questionId),
      stActive: isActive,
    })
  }

  return {
    handleActivation,
    isActivating: isLoading,
  }
}
