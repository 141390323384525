import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { getVolume, getVolumes } from './services'
import {
  TGetVolumeParams,
  TGetVolumeResponse,
  TGetVolumesParams,
  TGetVolumesResponse,
} from './types'

export const useQueryGetVolume = (
  idVolume: TGetVolumeParams,
  options?: UseQueryOptions<TGetVolumeResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getVolume(idVolume.idVolume)

  return useQuery<TGetVolumeResponse, AxiosError<Error>>(
    useQueryGetVolume.getFullQueryKey(idVolume),
    queryFunction,
    options
  )
}

useQueryGetVolume.getFullQueryKey = (idVolume: TGetVolumeParams): QueryKey => [
  'volume',
  idVolume,
]

useQueryGetVolume.queryKey = ['volume']

export const useQueryGetVolumes = (
  filters: TGetVolumesParams,
  options?: UseQueryOptions<TGetVolumesResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getVolumes(filters)

  return useQuery<TGetVolumesResponse, AxiosError<Error>>(
    useQueryGetVolumes.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetVolumes.getFullQueryKey = (filters: TGetVolumesParams): QueryKey => [
  'volumes',
  filters,
]

useQueryGetVolumes.queryKey = ['volumes']
