import * as yup from 'yup'

import { useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'

import { useMutationPostFormQuestion } from '@/api/forms/questions/mutations'
import { useQueryGetFormQuestionFieldValues } from '@/api/forms/questions/queries'
import { AnswerEnum, FormQuestion } from '@/types/form.types'
import { getErrorDetails } from '@/utils/error'

import {
  IRegisterQuestionFormValues,
  IAlternativeQuestion,
  IQuestionRequestData,
  ISelectOptions,
  IAnswerTypeConfig,
} from './types'

export const validationSchema = yup.object().shape({
  title: yup.string().required('O título é obrigatório'),
  formatVolume: yup.string().required('O formato é obrigatório'),
  exemplaryVolume: yup.string().required('O exemplar é obrigatório'),
  answerModel: yup.string().required('O modelo de resposta é obrigatório'),
  showPages: yup.boolean().required('Selecione uma opção'),
  versionVolume: yup.string().required('A versão é obrigatória'),
  description: yup.string().required('A descrição é obrigatória'),
  options: yup.array().when('answerModel', {
    is: AnswerEnum.multipleChoice.toString(),
    then: schema =>
      schema
        .of(
          yup.object().shape({
            label: yup.string().required('O texto da opção é obrigatório'),
            value: yup.string().required('O valor é obrigatório'),
            generatesAlternative: yup.boolean(),
            generatesDiligence: yup.boolean(),
            hasAttachment: yup.boolean(),
            isAttachmentRequired: yup.boolean(),
          })
        )
        .min(2, 'Pelo menos duas opções são obrigatórias'),
    otherwise: schema => schema.optional(),
  }),
  alternativeQuestions: yup.array().of(
    yup.object().shape({
      title: yup.string().required('O título é obrigatório'),
      formatVolume: yup.string().required('O formato é obrigatório'),
      exemplaryVolume: yup.string().required('O exemplar é obrigatório'),
      answerModel: yup.string().required('O tipo de resposta é obrigatório'),
      showPages: yup.boolean().required('Selecione uma opção'),
      versionVolume: yup.string().required('A versão é obrigatória'),
      description: yup.string().required('A descrição é obrigatória'),
      options: yup.array().when('answerModel', {
        is: AnswerEnum.multipleChoice.toString(),
        then: schema =>
          schema
            .of(
              yup.object().shape({
                label: yup.string().required('O texto da opção é obrigatório'),
                value: yup.string().required('O valor é obrigatório'),
              })
            )
            .min(2, 'Pelo menos duas opções são obrigatórias'),
        otherwise: schema => schema.optional(),
      }),
    })
  ),
})

export const useRegisterQuestionController = (
  onSuccess: (data: FormQuestion) => void,
  onClose: () => void
) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    data: fieldValues,
    isLoading: isLoadingFields,
    isError: isErrorFields,
    error: errorFields,
  } = useQueryGetFormQuestionFieldValues()

  const { mutate: registerQuestion, isLoading: isSubmitting } =
    useMutationPostFormQuestion({
      onSuccess: async data => {
        onSuccess(data)
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'Questão cadastrada! Atualizando tabela...',
          isClosable: true,
        })
        await queryClient.invalidateQueries(['formQuestions'])
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const staticOptions = {
    showPages: [
      { value: 'true', label: 'Sim' },
      { value: 'false', label: 'Não' },
    ],
    volumeVersion: [
      { value: 'CARACTERIZADO', label: 'Caracterizado' },
      { value: 'DESCARACTERIZADO', label: 'Descaracterizado' },
    ],
  }

  const selectOptions: ISelectOptions = {
    formats:
      fieldValues?.formats.map(format => ({
        value: format.coFormat.toString(),
        label: format.dsFormat,
      })) || [],
    exemplaries:
      fieldValues?.exemplaries.map(exemplary => ({
        value: exemplary.coExemplary.toString(),
        label: exemplary.dsExemplary,
      })) || [],
    failureTypes:
      fieldValues?.failureTypes.map(type => ({
        value: type.coFormFailureType.toString(),
        label: type.dsFailureType,
      })) || [],
    answerModels:
      fieldValues?.answerTypes.map(answer => ({
        value: answer.tpAnswer.toString(),
        label: answer.dsAnswerType,
      })) || [],
    ...staticOptions,
  }

  const createNewQuestion = (): IAlternativeQuestion => ({
    id: Date.now(),
    title: '',
    formatVolume: '',
    versionVolume: '',
    exemplaryVolume: '',
    diligence: '',
    answerModel: '',
    showPages: null,
    description: '',
    defaultYesNo: false,
    generatesDiligence: false,
    hasAttachment: false,
    isAttachmentRequired: false,
    options: [
      {
        value: '',
        label: '',
        generatesAlternative: false,
        generatesDiligence: false,
        hasAttachment: false,
        isAttachmentRequired: false,
      },
      {
        value: '',
        label: '',
        generatesAlternative: false,
        generatesDiligence: false,
        hasAttachment: false,
        isAttachmentRequired: false,
      },
    ],
  })

  const prepareAnswerTypeConfig = (
    values: IRegisterQuestionFormValues | IAlternativeQuestion
  ): IAnswerTypeConfig => {
    const config: IAnswerTypeConfig = {}
    const answerModel = values.answerModel

    switch (answerModel) {
      case AnswerEnum.bool.toString():
        config.defaultValue = values.defaultYesNo
        break
      case AnswerEnum.text.toString():
      case AnswerEnum.numeric.toString():
        if ('generatesDiligence' in values) {
          config.generatesDiligence = values.generatesDiligence
          config.hasAttachment = values.hasAttachment
          config.isAttachmentRequired = values.isAttachmentRequired
        }
        break
      case AnswerEnum.multipleChoice.toString():
        if ('options' in values && values.options) {
          config.options = values.options.map((option, index) => ({
            ...option,
            value: option.value || String(index + 1),
            generatesDiligence: option.generatesDiligence || false,
            hasAttachment: option.hasAttachment || false,
            isAttachmentRequired: option.isAttachmentRequired || false,
          }))
        }
        break
    }

    return config
  }

  const prepareQuestionData = (
    question: IRegisterQuestionFormValues | IAlternativeQuestion
  ): IQuestionRequestData => ({
    dsQuestion: question.title,
    coFormat: Number(question.formatVolume),
    coExemplary: Number(question.exemplaryVolume),
    coFormFailureType: Number(question.diligence) || 0,
    tpAnswer: Number(question.answerModel),
    stInformPage: Boolean(question.showPages),
    dsHelpText: question.description,
    dsVolumeVersion: question.versionVolume,
    answerTypeConfig: prepareAnswerTypeConfig(question),
  })

  const onSubmit = (values: IRegisterQuestionFormValues) => {
    const mainQuestionData = prepareQuestionData(values)

    if (values.alternativeQuestions?.length > 0) {
      mainQuestionData.alternativeQuestions = values.alternativeQuestions.map(
        question => prepareQuestionData(question)
      )
    }

    registerQuestion(mainQuestionData)
  }

  const initialValues: IRegisterQuestionFormValues = {
    title: '',
    formatVolume: '',
    exemplaryVolume: '',
    diligence: '',
    answerModel: '',
    versionVolume: '',
    description: '',
    showPages: null,
    defaultYesNo: false,
    generatesDiligence: false,
    hasAttachment: false,
    isAttachmentRequired: false,
    options: [
      {
        value: '',
        label: '',
        generatesAlternative: false,
        generatesDiligence: false,
        hasAttachment: false,
        isAttachmentRequired: false,
      },
      {
        value: '',
        label: '',
        generatesAlternative: false,
        generatesDiligence: false,
        hasAttachment: false,
        isAttachmentRequired: false,
      },
    ],
    alternativeQuestions: [],
  }

  return {
    selectOptions,
    isLoadingFields,
    isErrorFields,
    errorFields,
    isSubmitting,
    onSubmit,
    initialValues,
    createNewQuestion,
  }
}

export const cleanSubmit = (
  values: IRegisterQuestionFormValues,
  showAlternativeSection: boolean
) => {
  const baseFields = {
    title: values.title,
    formatVolume: values.formatVolume,
    versionVolume: values.versionVolume,
    exemplaryVolume: values.exemplaryVolume,
    diligence: values.diligence,
    answerModel: values.answerModel,
    showPages: values.showPages,
    description: values.description,
  }

  const answerTypeFields = getAnswerTypeFields(values)

  const alternativeQuestions = showAlternativeSection
    ? values.alternativeQuestions.map(question => {
        const altBaseFields = {
          id: question.id,
          title: question.title,
          formatVolume: question.formatVolume,
          versionVolume: question.versionVolume,
          exemplaryVolume: question.exemplaryVolume,
          diligence: question.diligence,
          answerModel: question.answerModel,
          showPages: question.showPages,
          description: question.description,
        }

        const altAnswerTypeFields = getAnswerTypeFields(question)

        return { ...altBaseFields, ...altAnswerTypeFields }
      })
    : []

  return {
    ...baseFields,
    ...answerTypeFields,
    alternativeQuestions,
  }
}

const getAnswerTypeFields = (
  question: IRegisterQuestionFormValues | IAlternativeQuestion
) => {
  switch (question.answerModel) {
    case AnswerEnum.bool.toString():
      return 'alternativeQuestions' in question &&
        question.alternativeQuestions.length > 0
        ? {
            defaultYesNo: question.defaultYesNo,
          }
        : {}

    case AnswerEnum.text.toString():
    case AnswerEnum.numeric.toString():
      return {
        generatesDiligence: question.generatesDiligence,
        hasAttachment: question.hasAttachment,
        isAttachmentRequired: question.isAttachmentRequired,
      }
    case AnswerEnum.multipleChoice.toString():
      return {
        options: question.options,
      }
    default:
      return {}
  }
}
