import {
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import { getForms, getForm, getFieldValues } from './services'
import {
  TGetFormsParams,
  TGetFormsResponse,
  TGetFormResponse,
  TGetFormParams,
  TGetFieldValuesResponse,
} from './types'

export const useQueryForms = (
  input: TGetFormsParams,
  options?: UseQueryOptions<TGetFormsResponse>
) => {
  const queryFunction = () => getForms(input)

  return useQuery<TGetFormsResponse>(
    useQueryForms.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryForms.getFullQueryKey = (input: TGetFormsParams): QueryKey => [
  'forms',
  input,
]

useQueryForms.queryKey = (input: TGetFormsParams): QueryKey => ['forms', input]

export const useQueryForm = (
  input: TGetFormParams,
  options?: UseQueryOptions<TGetFormResponse>
) => {
  const queryFunction = () => getForm(input)

  return useQuery<TGetFormResponse>(
    useQueryForm.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryForm.getFullQueryKey = (input: TGetFormParams): QueryKey => [
  'form',
  input,
]

useQueryForm.queryKey = (input: TGetFormParams): QueryKey => ['form', input]

export const useQueryFieldValues = (
  options?: UseQueryOptions<TGetFieldValuesResponse>
) => {
  const queryFunction = () => getFieldValues()

  return useQuery<TGetFieldValuesResponse>(
    useQueryFieldValues.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryFieldValues.getFullQueryKey = (): QueryKey => ['fieldValues']

useQueryFieldValues.queryKey = (): QueryKey => ['fieldValues']
