import React from 'react'

import { Formik } from 'formik'

import { Stack, Text, Flex, Button, SimpleGrid, Box } from '@chakra-ui/react'

import Accordion from '@/components/Accordion'
import DataFilter, { IFilter } from '@/components/DataFilter'
import { Input } from '@/components/Forms'

import { ITeamTabProps } from './types'

const TeamTab = ({ accordionItems }: ITeamTabProps) => {
  const filters: Array<IFilter> = [
    {
      name: 'analyst',
      label: 'Analista',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
  ]

  return (
    <Stack gap={4}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <SimpleGrid columns={3} spacing={4}>
          <Input isDisabled name="supervisor" label="Supervisor" />
          <Input isDisabled name="analyst_num" label="Qnt de Analistas" />
          <Input isDisabled name="volumes_num" label="Qnt de Volumes" />
        </SimpleGrid>
      </Formik>
      <DataFilter onlySearch filters={filters} onChangeFilter={() => {}} />
      <Stack>
        <Accordion items={accordionItems}></Accordion>
      </Stack>
    </Stack>
  )
}

export default TeamTab
