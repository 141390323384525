import React, { useState, useMemo } from 'react'
import { FaChevronLeft, FaCircleChevronRight } from 'react-icons/fa6'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import {
  Button,
  CircularProgress,
  Flex,
  Icon,
  Text,
  useToast,
} from '@chakra-ui/react'

import { useQueryGetNoticeObjects } from '@/api/notices/queries'
import { Select } from '@/components/Forms'
import { IOption } from '@/components/Forms/Select/types'
import { NoticeObject } from '@/types/notice.types'
import { getErrorDetails } from '@/utils/error'

import { AutomaticImportProps } from './types'

const validationSchema = yup.object().shape({
  notice: yup.string().required('Selecione o edital'),
  object: yup.string().required('Selecione o objeto'),
})

const AutomaticImport = ({
  activeStep,
  setActiveStep,
  setData,
}: AutomaticImportProps) => {
  const toast = useToast()
  const [objectOptions, setObjectOptions] = useState<Array<IOption>>([])

  const {
    data: noticeObjects,
    isLoading,
    error,
  } = useQueryGetNoticeObjects({
    onError: error => {
      toast({
        title: 'Erro ao carregar os editais e objetos',
        description: getErrorDetails(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const noticeOptions = useMemo(() => {
    if (!noticeObjects) return []
    return noticeObjects.map((noticeObject: NoticeObject) => ({
      value: String(noticeObject.coNotice),
      label: String(noticeObject.coNotice),
    }))
  }, [noticeObjects])

  if (isLoading) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        h="full"
      >
        <Text>Carregando lista de editais e objetos...</Text>
        <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
      </Flex>
    )
  }

  const handleNoticeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: string | number) => void
  ) => {
    const noticeValue = e.target.value

    if (!noticeValue) {
      setFieldValue('notice', '')
      setFieldValue('object', '')
      setObjectOptions([])
      return
    }

    const selectedNoticeObject = noticeObjects?.find(
      (noticeObject: NoticeObject) =>
        noticeObject.coNotice === Number(noticeValue)
    )

    const options =
      selectedNoticeObject?.objects.map(object => ({
        value: String(object.coObject),
        label: String(object.coObject),
      })) || []

    setFieldValue('notice', noticeValue)
    setFieldValue('object', '')
    setObjectOptions(options)
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-between"
      w="full"
      h="full"
    >
      <Formik
        initialValues={{ notice: '', object: '' }}
        validationSchema={validationSchema}
        onSubmit={values => {
          setData(values)
          setActiveStep(activeStep + 1)
        }}
      >
        {({ setFieldValue }) => (
          <Form
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              flex="1"
              w="full"
              maxW="400px"
              gap="8"
              mx="auto"
              py="60px"
            >
              <Text
                color="brand.primary.dark_1"
                textAlign="center"
                fontSize="20px"
                fontWeight="700"
                lineHeight="120%"
              >
                Escolha o edital e objeto
              </Text>
              <Select
                name="notice"
                label="Edital"
                options={noticeOptions}
                placeholder="Selecione o edital"
                isLoading={!noticeObjects}
              />
              <Select
                name="object"
                label="Objeto"
                options={objectOptions}
                placeholder="Selecione o objeto"
                isLoading={!objectOptions.length || !!error}
              />
            </Flex>
            <Flex direction="row" justify="space-between" w="full" p="0" m="0">
              <Button
                size={{ base: 'sm', sm: 'md' }}
                color="brand.primary.dark_1"
                bg="white"
                _hover={{ bg: 'brand.primary.dark_2' }}
                gap="2"
                py="2"
                px="6"
                borderRadius="4"
                leftIcon={<Icon as={FaChevronLeft} />}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Voltar
              </Button>

              <Button
                size={{ base: 'sm', sm: 'md' }}
                type="submit"
                color="white"
                bg="brand.primary.dark_1"
                _hover={{ bg: 'brand.primary.dark_2' }}
                gap="2"
                py="2"
                px="6"
                borderRadius="4"
                rightIcon={<Icon as={FaCircleChevronRight} />}
              >
                Próximo
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default AutomaticImport
