import React from 'react'

import { Flex } from '@chakra-ui/react'

import ManagementObjects from './ManagementObjects'

const Objects = () => {
  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      <ManagementObjects />
    </Flex>
  )
}

export default Objects
