import React, { useMemo, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'

import { Form, Formik } from 'formik'

import { Button, Flex, Stack, useToast } from '@chakra-ui/react'

import { useMutationUpdateTeamAnalyst } from '@/api/teams/mutations'
import { useQueryUserProfiles } from '@/api/teams/queries'
import { useQueryUsers } from '@/api/user/management/queries'
import { BasicSelect } from '@/components/Forms'
import { IOption } from '@/components/Forms/BasicSelect/types'
import Modal from '@/components/Modal'

import { IAddAnalystModalProps } from './types'

const AddAnalystModal: React.FC<IAddAnalystModalProps> = ({
  coTeam,
  analystsId,
  isOpen,
  onClose,
  refetchTeam,
}) => {
  const toast = useToast()
  const [selectedAnalysts, setSelectedAnalysts] = useState('')
  const {
    data: profiles = {
      pagination: {
        count: 0,
        totalPages: 1,
        currentPage: 1,
        pageSize: 50,
      },
      results: [],
    },
    isLoading: isLoadingProfiles,
  } = useQueryUserProfiles()
  const filteredProfiles =
    profiles.results?.filter(
      profile => profile.name === 'Analista de volumes'
    ) || []

  const {
    data: users = {
      pagination: {
        count: 0,
        totalPages: 1,
        currentPage: 1,
        pageSize: 50,
      },
      results: [],
    },
    isLoading: isLoadingUsers,
  } = useQueryUsers()

  const analystsOptions: IOption[] = useMemo(() => {
    return users.results
      ? users.results
          .filter(
            user =>
              user.profile?.some(profile =>
                filteredProfiles.includes(profile)
              ) && !analystsId.includes(user.coUser)
          )
          .reduce((acc, user) => {
            acc.push({ value: user.coUser, label: String(user?.noUserName) })
            return acc
          }, [] as IOption[])
      : []
  }, [users, filteredProfiles, analystsId])

  const { mutate: postAddAnalyst, isLoading: isPostLoading } =
    useMutationUpdateTeamAnalyst({
      onSuccess: () => {
        toast({
          title: 'Analista adicionado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
        refetchTeam()
      },
      onError: () => {
        toast({
          title: 'Erro ao adicionar analista, tente novamente mais tarde',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  const handleAddAnalyst = () => {
    const payload = {
      coTeam: coTeam,
      ncoAnalyst: [
        {
          coUser: Number(selectedAnalysts),
          sendEmail: false,
        },
      ],
    }
    postAddAnalyst(payload)
  }

  const hasSelectedUsers = () => {
    if (!selectedAnalysts) {
      toast({
        title: 'Nenhum analista selecionado',
        description: 'Selecione pelo menos um usuário',
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
      return false
    }

    return true
  }

  return (
    <Modal
      title="Adicionar analista a equipe"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Stack gap={8}>
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
          }}
          onSubmit={handleAddAnalyst}
        >
          <Form>
            <BasicSelect
              name="name"
              label="Escolha do analista disponível"
              placeholder="Selecione um analista"
              isLoading={isLoadingUsers}
              onChange={value => setSelectedAnalysts(value)}
              options={analystsOptions}
            />
          </Form>
        </Formik>
        <Flex gap={4} direction={'column'} justifyContent={'flex-end'}>
          <Button
            size="md"
            borderRadius="full"
            leftIcon={<FaPlusCircle size="14px" color="white" />}
            backgroundColor="brand.primary.dark_1"
            color="white"
            isLoading={isPostLoading}
            onClick={() => {
              if (hasSelectedUsers()) {
                handleAddAnalyst()
              }
            }}
          >
            Sim, quero adicionar
          </Button>
          <Button
            size="md"
            variant={'outline'}
            borderRadius="full"
            onClick={onClose}
          >
            Voltar
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}

export default AddAnalystModal
