import React from 'react'
import { Outlet } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Stack, Link, Image } from '@chakra-ui/react'

import LogoFNDE from '@/assets/img/fnde/logo/logo-fnde-b-nd-m.svg'
import LogoMEC from '@/assets/img/mec/logo/logo-mec-b-nd-m.svg'
import LogoNEES from '@/assets/img/nees/logo/logo-nees-b-nd-m-B.svg'
import ColorfulLogoSARE from '@/assets/img/sare/logo/16px/horizontal/logo-sare-16px-h-a-d-nm-RL.svg'

import S from './styles'

const TemplateLogin: React.FC = () => {
  return (
    <Stack minHeight="100vh" backgroundColor="brand.highlight.dark">
      <S.Header backgroundColor="white">
        <S.HeaderContent>
          <Link as={ReactRouterLink} to="/login">
            <Image height="36px" src={ColorfulLogoSARE} alt="Logo Sare" />
          </Link>
        </S.HeaderContent>
      </S.Header>
      <S.Main>
        <Outlet />
      </S.Main>
      <S.Footer>
        <Image
          height={{ base: '2.625rem', sm: '4.5rem' }}
          src={LogoNEES}
          alt="Logo NEES"
        />
        <Image
          height={{ base: '2.25rem', sm: '3.75rem' }}
          src={LogoMEC}
          alt="Logo MEC"
        />
        <Image
          height={{ base: '1.625rem', sm: '2.375rem' }}
          src={LogoFNDE}
          alt="Logo FNDE"
        />
      </S.Footer>
    </Stack>
  )
}

export default TemplateLogin
