import http from '@/api/http'

import {
  TGetVolumeResponse,
  TGetVolumesParams,
  TGetVolumesResponse,
} from './types'

export const getVolume = async (idVolume: string) => {
  const response = await http.get<TGetVolumeResponse>(
    `/volumes/volume_detail/${idVolume}/`
  )

  return response.data
}

export const getVolumes = async (filters: TGetVolumesParams) => {
  const response = await http.get<TGetVolumesResponse>('/volumes/', {
    params: filters,
  })

  return response.data
}
