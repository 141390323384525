import { IHeader } from '@/components/DataTable/types'
import { Deed } from '@/types/deed.types'

import { IObjectFileData } from '../ManualImport/types'
import { IRowData } from './types'

export const processHeaders = (
  formatCounts: Record<FormatType, number>
): Array<IHeader> => {
  return [
    {
      name: 'Editora',
      key: '',
      type: 'element',
      width: 'auto',
      subHeader: [
        {
          name: ' ',
          key: 'publisher',
          type: 'element',
          width: 'auto',
          footer: 'Total Geral',
        },
      ],
    },
    {
      name: 'Código de Coleção',
      key: 'collectionCode',
      type: 'element',
      width: 'auto',
    },
    {
      name: 'Exemplar',
      key: 'sample',
      type: 'element',
      width: 'auto',
      subHeader: [
        {
          name: 'Formato',
          key: 'format',
          type: 'element',
          width: 'auto',
        },
      ],
    },
    {
      name: 'Livro do Estudante',
      key: 'student_book',
      type: 'element',
      width: 'auto',
      subHeader: [
        {
          name: 'Impresso',
          key: 'printed',
          type: 'element',
          width: 'auto',
          footer: formatCounts.printed.toString(),
        },
        {
          name: 'PDF',
          key: 'pdf',
          type: 'element',
          width: 'auto',
          footer: formatCounts.pdf.toString(),
        },
        {
          name: 'Vídeo',
          key: 'video',
          type: 'element',
          width: 'auto',
          footer: formatCounts.video.toString(),
        },
        {
          name: 'Áudio',
          key: 'audio',
          type: 'element',
          width: 'auto',
          footer: formatCounts.audio.toString(),
        },
        {
          name: 'HTML',
          key: 'html',
          type: 'element',
          width: 'auto',
          footer: formatCounts.html.toString(),
        },
        {
          name: 'Vídeo Tutorial',
          key: 'videoTutorial',
          type: 'element',
          width: 'auto',
          footer: formatCounts.videoTutorial.toString(),
        },
      ],
    },
  ]
}

type FormatType =
  | 'printed'
  | 'pdf'
  | 'video'
  | 'audio'
  | 'videoTutorial'
  | 'html'

export const mapCsvToTable = (data: IObjectFileData[]): IRowData[] => {
  if (!data) return []

  type FormatKeysType = { [key: string]: FormatType }

  const formatKeys: FormatKeysType = {
    impresso: 'printed',
    pdf: 'pdf',
    video: 'video',
    audio: 'audio',
    video_tutorial: 'videoTutorial',
    html: 'html',
  }

  const uniqueData: Record<string, IRowData> = {}

  data.forEach(item => {
    const key = `${item.editora}-${item.codigo_colecao}`
    if (!uniqueData[key]) {
      uniqueData[key] = {
        publisher: item.editora,
        collectionCode: item.codigo_colecao,
        format: null,
        printed: 0,
        pdf: 0,
        video: 0,
        audio: 0,
        videoTutorial: 0,
        html: 0,
        modalData: {
          notice: item.edital,
          object: item.objeto,
          publisher: item.editora,
          collectionTitle: item.codigo_colecao,
          collectionCode: item.titulo_colecao,
          details: [],
        },
      }
    }

    uniqueData[key].modalData.details.push({
      volumeCode: item.codigo_volume,
      version: item.versao,
      sample: item.exemplar,
      format: item.formato,
    })

    const formatKey = formatKeys[item.formato.toLowerCase()]
    if (formatKey) {
      uniqueData[key][formatKey]++
    }
  })

  return Object.values(uniqueData)
}

export const countFormats = (rowData: IRowData[]): Record<string, number> => {
  const countFormat: Record<FormatType, number> = {
    printed: 0,
    pdf: 0,
    video: 0,
    audio: 0,
    videoTutorial: 0,
    html: 0,
  }

  rowData.forEach(item => {
    countFormat.printed += item.printed
    countFormat.pdf += item.pdf
    countFormat.video += item.video
    countFormat.audio += item.audio
    countFormat.videoTutorial += item.videoTutorial
    countFormat.html += item.html
  })

  return countFormat
}

export const renameKeys = (data: IObjectFileData[]): Deed[] => {
  const newDeedDTO: Deed[] = []

  data.forEach(item => {
    const newDeed: Deed = {
      coCategory: parseInt(item.id_categoria),
      coCnpj: item.cnpj,
      coCollectionDigitalPnld: item.id_inscricao_colecao,
      coComponent: parseInt(item.id_componente),
      coExemplary: parseInt(item.fixed_field_copy_id),
      coFormat: parseInt(item.fixed_field_format_id),
      coKnowledgeArea: parseInt(item.id_area),
      coNoticeDigitalPnld: item.id_edital,
      coObject: parseInt(item.object_id),
      coResource: parseInt(item.fixed_field_resource_id),
      coSeries: parseInt(item.fixed_field_grade_id),
      coVolumeDigitalPnld: item.id_inscricao_volume,
      coVolumeFileDigitalPnld: item.document_id,
      dsAuthors: item.autoria,
      dsBrandDescription: item.descricao_marca,
      dsCategory: item.categoria,
      dsCollectionCode: item.codigo_colecao,
      dsCollectionTitle: item.titulo_colecao,
      dsComponent: item.componente,
      dsDuration: item.duracao == '[NULL]' ? 'null' : item.duracao,
      dsEmail: item.responsavel_email,
      dsExemplary: item.exemplar,
      dsFormat: item.formato,
      dsKnowledgeArea: item.area,
      dsMetadata:
        item.palavras_chave == '[NULL]' ? 'null' : item.palavras_chave,
      dsObject: item.objeto,
      dsOriginalityDeclaration: item.declaracao_ineditismo,
      dsPedagogicalEvaluation: item.avaliacao_pedagogica,
      dsPhysicalAtributes: item.atributos_fisicos,
      dsPublisherResponsible: item.responsavel_nome,
      dsRegistryValidation: item.validacao_inscricao,
      dsResource: item.recurso,
      dsSeries: item.serie,
      dsSocialReason: item.editora,
      dsStreetAddress: item.endereco,
      dsTitle: item.edital,
      dsVersion: item.versao,
      dsVolumeCode: item.codigo_volume,
      dsVolumeTitle: item.titulo_volume,
      dtPublication: item.data_registro,
      nuCellphone: item.responsavel_celular,
      nuEditionYear: parseInt(item.numero_edicao_1),
      nuEdition: parseInt(item.numero_edicao),
      nuPages: parseInt(item.paginas),
      nuPhone: item.responsavel_telefone,
    }

    newDeedDTO.push(newDeed)
  })

  return newDeedDTO
}
