import { Flex, styled } from '@chakra-ui/react'

const ModalActionButtons = styled(Flex, {
  baseStyle: {
    gap: '3',
    justifyContent: 'flex-end',
    marginTop: '8',
  },
})

export default {
  ModalActionButtons,
}
