import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Flex, Image, Hide, Stack } from '@chakra-ui/react'

import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import S from '@/common/Templates/TemplateLogin/styles'

const LoginAccessDenied: React.FC = () => {
  return (
    <S.MainBox maxWidth="596px" backgroundColor="white">
      <Hide below="sm">
        <Box display="flex" justifyContent="center">
          <Image height="75px" src={ColorfulLogoSARE} alt="Logo Sare" />
        </Box>
      </Hide>
      <Stack gap={4}>
        <S.Title color="brand.error.base">Acesso Negado</S.Title>
        <S.Subtitle color="brand.neutral.dark_2">
          Lamentamos, mas no momento seu acesso foi negado. Nossa equipe revisou
          suas credenciais e, infelizmente, sua solicitação não foi autorizada
          neste momento.
          <br />
          <br />
          Para esclarecimentos ou assistência, entre em contato com nosso
          suporte
        </S.Subtitle>
        <Flex align="center" justify="center">
          <Link to="https://atendimento.nees.ufal.br/" target="_blank">
            <S.MainButton
              variant="solid"
              border="none"
              color="white"
              backgroundColor="brand.primary.dark_1"
              _hover={{
                backgroundColor: 'brand.primary.dark_2',
                color: 'white',
              }}
            >
              Fale conosco
            </S.MainButton>
          </Link>
        </Flex>
      </Stack>
    </S.MainBox>
  )
}

export default LoginAccessDenied
