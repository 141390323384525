import React from 'react'
import { useParams } from 'react-router-dom'

import { FieldArray, useFormikContext } from 'formik'

import { Box, Stack, Text } from '@chakra-ui/react'

import AlternativeQuestion from '@/pages/Checklists/Questions/parts/RegisterQuestionModal/AlternativeQuestion'
import { AnswerEnum, Alternative } from '@/types/form.types'

import { useQuestionDetailsController } from '../../../controller'
import { TQuestionDetailsFormValues } from '../../../types'

const AlternativeQuestionsTab: React.FC = () => {
  const { coQuestion } = useParams()
  const { questionData, selectOptions } = useQuestionDetailsController(
    coQuestion as string
  )
  const { values } = useFormikContext<TQuestionDetailsFormValues>()

  const answerType =
    values.answerModel || questionData?.answerType?.tpAnswer?.toString() || ''

  const canHaveAlternativeQuestions = (answerModel: string): boolean => {
    return ![
      AnswerEnum.numeric.toString(),
      AnswerEnum.text.toString(),
      '',
    ].includes(answerModel)
  }

  const isFormConnected = (questionData?.forms?.length ?? 0) > 0
  const hasAlternatives = canHaveAlternativeQuestions(answerType)
  const alternatives = questionData?.alternatives || []

  const alternativeQuestions = alternatives.map(
    (alt: Alternative, index: number) => ({
      id: alt.coAlternative,
      title: alt.dsAlternative,
      formatVolume: questionData?.coFormat?.toString() || '',
      versionVolume: questionData?.dsVolumeVersion || '',
      exemplaryVolume: questionData?.coExemplary?.toString() || '',
      diligence: questionData?.failureType?.coFormFailureType?.toString() || '',
      answerModel: questionData?.answerType?.tpAnswer?.toString() || '',
      showPages: questionData?.stInformPage ? true : false,
      description: alt.dsAlternative,
      defaultYesNo: false,
      generatesDiligence: alt.stGeneratesDiligence,
      hasAttachment: alt.stHasAttachment,
      isAttachmentRequired: alt.stMandatoryAttachment,
    })
  )

  return (
    <Box>
      {!hasAlternatives ? (
        <Text color="gray.500">
          Este tipo de resposta não permite questões alternativas.
        </Text>
      ) : alternatives.length === 0 ? (
        <Text color="gray.500">
          Esta questão não possui questões alternativas cadastradas.
        </Text>
      ) : (
        <FieldArray
          name="alternativeQuestions"
          render={() => (
            <Stack spacing={4}>
              {alternativeQuestions.map((question, index) => (
                <AlternativeQuestion
                  key={question.id}
                  index={index}
                  selectOptions={selectOptions}
                  onRemove={() => {}}
                  readOnly={isFormConnected}
                />
              ))}
            </Stack>
          )}
        />
      )}
    </Box>
  )
}

export default AlternativeQuestionsTab
