import React from 'react'
import {
  FaCopy,
  FaEdit,
  FaGripVertical,
  FaQuestionCircle,
  FaTrash,
} from 'react-icons/fa'

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Divider,
  Icon,
  Circle,
} from '@chakra-ui/react'

import StatusTag from '@/components/Tag/StatusCardTeam'

import { ICardComponentProps } from './types'

const CardComponent: React.FC<ICardComponentProps> = ({
  id,
  idText,
  title,
  description,
  status,
  children,
  dragHandleProps,
  iconUrl,
  statusTag,
  onCopy = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      alignSelf="stretch"
      padding={4}
      borderRadius="lg"
      borderWidth="1px 1px 3px 1px"
      my={2}
      borderColor="gray.200"
      backgroundColor="white"
      boxShadow="sm"
    >
      <Flex direction="row" alignItems="center" gap={4} alignSelf="stretch">
        <IconButton
          aria-label="Grip"
          icon={<FaGripVertical />}
          color="brand.primary.dark_1"
          padding={3}
          borderRadius="3xl"
          variant="ghost"
          _hover={{ backgroundColor: 'gray.100' }}
          {...dragHandleProps}
        />

        <Flex flex="1" direction="row" alignItems="center" gap={4}>
          {iconUrl && (
            <Circle
              color="base.seccess.light"
              size="48px"
              bg="brand.success.light"
            >
              <Icon
                as={FaQuestionCircle}
                w={6}
                h={6}
                color="brand.success.base"
              />
            </Circle>
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            flex="1"
          >
            {idText && (
              <Text
                color="brand.neutral.dark_2"
                fontSize="sm"
                fontWeight="normal"
                lineHeight="150%"
              >
                {id}
              </Text>
            )}
            {title && (
              <Text
                alignSelf="stretch"
                color="brand.neutral.dark_2"
                fontSize="md"
                fontWeight="bold"
                lineHeight="140%"
              >
                {title}
              </Text>
            )}
            {description && (
              <Text
                color="brand.neutral.dark_2"
                fontSize="sm"
                fontWeight="normal"
                lineHeight="150%"
              >
                {description}
              </Text>
            )}
          </Box>

          <Divider
            orientation="vertical"
            height={8}
            bg="brand.neutral.light_1"
          />
          {!statusTag && <StatusTag tag={status.tag} />}
          {onEdit && (
            <Button
              paddingY={3}
              paddingX={6}
              borderRadius="3xl"
              border="2px solid"
              borderColor="brand.neutral.dark_1"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'gray.50' }}
              leftIcon={<FaEdit />}
              color="brand.primary.dark_1"
              onClick={() => onEdit(id)}
            >
              Editar
            </Button>
          )}
          {onCopy && (
            <IconButton
              aria-label="Copiar"
              icon={<FaCopy />}
              variant="ghost"
              borderRadius="3xl"
              colorScheme="blue"
              color="brand.primary.dark_1"
              onClick={() => onCopy(id)}
            />
          )}
          {onDelete && (
            <IconButton
              aria-label="Delete card"
              icon={<FaTrash />}
              variant="ghost"
              color="red"
              size="sm"
              onClick={() => onDelete(id)}
            />
          )}
        </Flex>
      </Flex>

      {children}
    </Flex>
  )
}

export default CardComponent
