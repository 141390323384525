import React from 'react'

import { Button, Flex, useDisclosure, useToast } from '@chakra-ui/react'

import { useMutationAddQuestions } from '@/api/forms/form/mutations'
import Modal from '@/components/Modal'
import { useQuestionManagement } from '@/contexts/QuestionManagementContext'
import { FormQuestion } from '@/types/form.types'
import { getErrorDetails } from '@/utils/error'

import RegisterQuestionModal from '../../Questions/parts/RegisterQuestionModal'
import { ModalExistingQuestion } from '../ExistingQuestionModal'
import { IModalProps } from '../types'

export const ModalQuestion = ({
  coForm,
  isOpen,
  onClose,
  onSuccess,
}: IModalProps): JSX.Element => {
  const toast = useToast()
  const { addNewQuestions, setQuestionsToAdd } = useQuestionManagement()
  const newQuestionDisclosure = useDisclosure()
  const existingQuestionDisclosure = useDisclosure()

  const { mutate: addQuestions, isLoading: isAddingQuestions } =
    useMutationAddQuestions({
      onSuccess: () => {
        onClose()
        onSuccess?.()
        toast({
          title: 'Questões adicionadas com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: error => {
        toast({
          title: 'Erro ao adicionar questões',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const handleNewQuestion = (question: FormQuestion) => {
    addNewQuestions([
      {
        id: question.coFormQuestion,
        name: question.dsQuestion,
        type: question.answerType.dsAnswerType,
        dsStatus: question.stActive ? 'Ativo' : 'Inativo',
      },
    ])
    setQuestionsToAdd(prevState => [...prevState, question.coFormQuestion])
    // const payload = {
    //   coForm: Number(coForm),
    //   ncoFormQuestion: [data.coFormQuestion],
    // }

    // addQuestions(payload)
  }

  return (
    <Modal
      title="Adicionar questão existente"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Flex
        justifyContent="center"
        gap="12px"
        alignItems="center"
        flexDirection="row"
      >
        <Button
          variant="outline"
          borderRadius="full"
          onClick={newQuestionDisclosure.onOpen}
        >
          Adicionar nova questão
        </Button>
        <Button borderRadius="full" onClick={existingQuestionDisclosure.onOpen}>
          Adicionar questão existente
        </Button>
      </Flex>
      <RegisterQuestionModal
        isOpen={newQuestionDisclosure.isOpen}
        onClose={newQuestionDisclosure.onClose}
        onSuccess={question => {
          newQuestionDisclosure.onClose()
          onClose()
          handleNewQuestion(question)
        }}
      />
      <ModalExistingQuestion
        isOpen={existingQuestionDisclosure.isOpen}
        onClose={existingQuestionDisclosure.onClose}
        onSuccess={() => {
          existingQuestionDisclosure.onClose()
          onClose()
        }}
      />
    </Modal>
  )
}
