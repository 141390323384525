import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { IoHelpCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import { Flex, Stack, Text, Button, Skeleton, Icon } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'

import { useChecklistController } from './controller'
import { ModalDeleteForm } from './DeleteFormModal'

const tableHeaders: Array<IHeader> = [
  {
    name: 'Fomulário',
    key: 'checklist',
    type: 'element',
    width: '40%',
  },
  {
    name: 'Objeto',
    key: 'object',
    type: 'element',
    width: '25%',
  },
  {
    name: 'Tipo',
    key: 'type',
    type: 'element',
    width: '20%',
    align: 'center',
  },
  {
    name: 'Ações',
    key: 'actions',
    type: 'element',
    width: '15%',
    align: 'center',
  },
]

const Checklist: React.FC = () => {
  const {
    formsTableData,
    refetchForms,
    handleFilters,
    statusTab,
    setStatusTab,
    pagination,
    isFormsFetching,
    handleOnChangePageInfo,
    coFormToDelete,
    isDeleteModalOpen,
    onDeleteModalClose,
  } = useChecklistController()
  const navigate = useNavigate()

  const handleCreateForm = () => {
    navigate('/formularios/cadastrar-formulario')
  }

  const checkListTabs: Array<ITabItem> = [
    {
      id: 'PENDENTE',
      label: 'Aguardando validação',
    },
    {
      id: 'AGUARDANDO_VINCULO',
      label: 'Aguardando vínculo',
    },
    {
      id: 'AUTORIZADO',
      label: 'Aprovados',
    },
  ]

  return (
    <>
      <Stack p={4} boxShadow="md" borderRadius="md">
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
            Lista de formulários
          </Text>
          <Button
            leftIcon={<FaPlusCircle />}
            onClick={handleCreateForm}
            borderRadius="full"
            size="sm"
          >
            Cadastrar novo formulário
          </Button>
        </Flex>
        <Tabs
          variant="rounded"
          items={checkListTabs}
          initialTab={statusTab}
          onTabClick={({ id }) => {
            handleFilters({
              search: '',
            })

            setStatusTab(id)
          }}
        />
        {formsTableData && formsTableData.length > 0 ? (
          <Stack gap={8}>
            <DataFilter
              onlySearch
              filters={[]}
              onChangeFilter={handleFilters}
            />
            <Skeleton isLoaded={!isFormsFetching}>
              <DataTable headers={tableHeaders} data={formsTableData} />
            </Skeleton>
            <Pagination
              pageIndex={pagination.page - 1}
              optionsItemsPerPage={pagination.pageSizeOptions}
              itemsPerPage={pagination.pageSize}
              pageCount={pagination.pageCount}
              itemsCount={pagination.itemsCount}
              onChangePageInfo={handleOnChangePageInfo}
            />
          </Stack>
        ) : (
          <>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
              padding={5}
              gap={4}
            >
              <Icon
                as={IoHelpCircle}
                boxSize="128px"
                color="brand.primary.dark_1"
                rotate="30deg"
              />
              <Text
                color="brand.primary.dark_2"
                textAlign="center"
                fontSize="20px"
                fontWeight="700"
                lineHeight="120%"
              >
                Cadastre seu primeiro formulário!
              </Text>
            </Flex>
          </>
        )}
      </Stack>
      <ModalDeleteForm
        coForm={coFormToDelete}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onSuccess={refetchForms}
      />
    </>
  )
}

export default Checklist
