import React from 'react'

import { Button, Text } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import { useDeleteQuestionController } from './controller'
import S from './styles'
import { IDeleteQuestionModalProps } from './types'

const DeleteQuestionModal: React.FC<IDeleteQuestionModalProps> = ({
  isOpen,
  onClose,
  coFormQuestion,
}) => {
  const { handleDelete, isDeleting } = useDeleteQuestionController(
    coFormQuestion,
    onClose
  )

  return (
    <Modal
      title="Excluir questão"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: 'sm',
        md: 'xl',
      }}
    >
      <Text fontSize="sm" fontWeight="normal" color="brand.primary.dark_1">
        <b>Esta ação é irreversível! </b>Tem certeza que deseja excluir essa
        questão permanentemente de todos os formulários vinculados?
      </Text>
      <S.ModalActionButtons>
        <Button
          key="delete"
          size="sm"
          backgroundColor="brand.error.base"
          _hover={{ bg: 'brand.error.dark' }}
          onClick={handleDelete}
          isLoading={isDeleting}
          borderRadius={50}
        >
          Excluir questão
        </Button>
        <Button
          key="cancel"
          variant="outline"
          size="sm"
          borderRadius={50}
          onClick={onClose}
          disabled={isDeleting}
        >
          Cancelar
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default DeleteQuestionModal
