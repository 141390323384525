import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import {
  postAnalysisVolumeSupervisor,
  postFormAnalysisVolume,
  postReanalysisVolumesCoordinator,
  postRenalysisVolumeSupervisor,
} from './services'
import {
  TPostAnalysisVolumeSupervisorBody,
  TPostFormAnalysisVolumeBody,
  TPostReanalysisVolumesCoordinatorBody,
  TPostReanalysisVolumeSupervisorBody,
} from './types'

export const useMutationSendFormAnalysisVolume = (
  options?: UseMutationOptions<
    [],
    AxiosError<Error>,
    TPostFormAnalysisVolumeBody
  >
) => {
  return useMutation(postFormAnalysisVolume, options)
}

export const useMutationAnalysisVolumeSupervisor = (
  options?: UseMutationOptions<
    [],
    AxiosError<Error>,
    TPostAnalysisVolumeSupervisorBody
  >
) => {
  return useMutation(postAnalysisVolumeSupervisor, options)
}

export const useMutationReanalysisVolumeSupervisor = (
  options?: UseMutationOptions<
    [],
    AxiosError<Error>,
    TPostReanalysisVolumeSupervisorBody
  >
) => {
  return useMutation(postRenalysisVolumeSupervisor, options)
}

export const useMutationReanalysisVolumesCoordinator = (
  options?: UseMutationOptions<
    [],
    AxiosError<Error>,
    TPostReanalysisVolumesCoordinatorBody
  >
) => {
  return useMutation(postReanalysisVolumesCoordinator, options)
}
