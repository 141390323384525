import React, { useMemo, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { FaExchangeAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import {
  Flex,
  Box,
  Button,
  Text,
  Stack,
  Select,
  useToast,
} from '@chakra-ui/react'

import { useQueryTeam } from '@/api/teams/queries'
import { Input } from '@/components/Forms'
import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import { formatDate } from '@/utils/date'

import TeamAnalystTable from './parts/Details/TeamAnalystTable'
import TeamVolumeTable from './parts/Details/TeamVolumeTable'
import { ITeamStatusMapping } from './types'

const TeamDetails: React.FC = () => {
  const { teamId } = useParams()
  const toast = useToast()
  const [activeTabId, setActiveTabId] = useState('informacoes-pessoais')
  const navigate = useNavigate()

  const {
    data: teamData,
    isLoading: isLoadingTeamData,
    isError,
    isFetching: isFetchingTeam,
    refetch: refetchTeamData,
  } = useQueryTeam(
    { coTeam: teamId as unknown as number },
    {
      enabled: !!teamId,
      onError: () => {
        toast({
          title: 'Erro ao buscar dados do team',
          description: 'Não foi possível obter os dados do usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const tabs: Array<ITabItem> = [
    {
      id: 'analysts',
      label: 'Analistas',
      render: () => {
        return (
          teamData && (
            <TeamAnalystTable
              refetchTeam={refetchTeamData}
              coTeam={teamData.coTeam}
              analysts={teamData.members.filter(
                member => !member.coUserRole.dsUserRole.includes('Supervisor')
              )}
              isAnalystsLoading={isLoadingTeamData}
            />
          )
        )
      },
    },
    {
      id: 'volumes',
      label: 'Volumes',
      render: () => (
        <TeamVolumeTable
          coTeam={teamData?.coTeam}
          coObject={teamData?.object.coObject}
        />
      ),
    },
  ]

  const teamSchema = yup.object().shape({
    name: yup.string(),
    description: yup.string(),
    code: yup.number(),
    notice: yup.number(),
    fase: yup.string(),
    supervisor: yup.string(),
    date: yup.date(),
  })

  const teamValues = useMemo(() => {
    return {
      name: teamData?.noTeam || '',
      code: teamData?.object?.coObject || '',
      notice: teamData?.notice?.nuNotice || '',
      fase: teamData?.stage?.dsStage || '',
      supervisor: teamData?.coSupervisor.noUserName || '',
      date: formatDate(String(teamData?.dtCreatedAt) || ''),
    }
  }, [teamData])

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            {teamData?.noTeam}
          </Text>
          {teamData?.stTeamStatus && (
            <Tag
              group="statusTeamSystem"
              value={ITeamStatusMapping[teamData?.stTeamStatus]}
            />
          )}
        </Flex>
        <Formik
          enableReinitialize
          loading={isLoadingTeamData}
          initialValues={teamValues}
          validationSchema={teamSchema}
          onSubmit={() => {}}
        >
          <Form>
            <Flex gap={4}>
              <Input
                isDisabled
                name="notice"
                label="Edital"
                flex={1}
                isLoading={isLoadingTeamData}
              />
              <Input
                isDisabled
                name="name"
                label="Objeto"
                flex={2}
                isLoading={isLoadingTeamData}
              />
              <Input
                isDisabled
                name="code"
                label="Código do Objeto"
                flex={1}
                isLoading={isLoadingTeamData}
              />
            </Flex>
            <Flex gap={4}>
              <Input
                isDisabled
                name="fase"
                label="Fase"
                flex={1}
                isLoading={isLoadingTeamData}
              />
              <Input
                isDisabled
                name="supervisor"
                label="Supervisor"
                flex={2}
                isLoading={isLoadingTeamData}
              />
              <Input
                isDisabled
                name="date"
                label="Data de criação"
                flex={1}
                isLoading={isLoadingTeamData}
              />
            </Flex>
          </Form>
        </Formik>
        <Flex justifyContent="flex-end" gap={4}>
          <Button
            leftIcon={<FaExchangeAlt size="14px" color="white" />}
            size="sm"
            fontSize="sm"
            fontWeight="semibold"
            py={0}
            px={3}
            borderRadius="6px"
            backgroundColor="brand.error.base"
            color="white"
            _hover={{ bg: 'brand.error.dark' }}
            // TODO: Implement the redistribute team function
            // onClick={handleRedistributeTeam}
          >
            Redistribuir
          </Button>
        </Flex>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Box>
          <Tabs
            items={tabs}
            initialTab={activeTabId}
            onTabClick={({ id }: ITabInfo) => {
              setActiveTabId(id)
            }}
          />
          <Flex
            direction="row"
            justifyContent="flex-start"
            gap="2"
            marginTop="10"
          >
            <Button
              size="sm"
              leftIcon={<BsChevronLeft />}
              variant="ghost"
              fontSize="sm"
              color="brand.primary.dark_1"
              onClick={() => navigate('/configuracoes/equipe')}
            >
              Voltar
            </Button>
          </Flex>
        </Box>
      </Stack>
    </>
  )
}

export default TeamDetails
