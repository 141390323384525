import React, { useEffect, useState, useCallback } from 'react'

import {
  Text,
  Tabs as ChakraTabs,
  TabList as ChakraTabList,
  Tab as ChakraTab,
  TabPanels as ChakraTabPanels,
  TabPanel as ChakraTabPanel,
  Box,
} from '@chakra-ui/react'

import { findIndexWithDefault } from '@/utils/array'

import { ITabsProps } from './types'

const Tabs: React.FC<ITabsProps> = ({
  index = 0,
  items,
  initialTab,
  onTabClick,
  variant = 'line',
  ...rest
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  useEffect(() => {
    if (initialTab) {
      const index = findIndexWithDefault(
        items,
        item => item.id === initialTab,
        0
      )
      setSelectedTab(index)
    }
  }, [initialTab, items])

  useEffect(() => {
    setSelectedTab(index)
  }, [index])

  const handleOnChange = useCallback(
    (index: number) => {
      if (onTabClick) {
        onTabClick?.({ id: items[index].id, index })
        return
      }

      setSelectedTab(index)
    },
    [items, onTabClick]
  )

  return (
    <ChakraTabs
      variant={variant}
      width="100%"
      onChange={handleOnChange}
      index={selectedTab}
      {...rest}
    >
      <Box
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'gray.300',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.400',
            borderRadius: '24px',
          },
        }}
      >
        <ChakraTabList>
          {items.map(item => (
            <ChakraTab key={`tab-${item.id}`}>
              <Text fontSize="sm">{item.label}</Text>
            </ChakraTab>
          ))}
        </ChakraTabList>
      </Box>
      {items.some(item => item.render) ? (
        <ChakraTabPanels>
          {items.map(item =>
            item.render ? (
              <ChakraTabPanel key={`tab-panel-${item.id}`}>
                {item.render()}
              </ChakraTabPanel>
            ) : null
          )}
        </ChakraTabPanels>
      ) : null}
    </ChakraTabs>
  )
}

export default Tabs
