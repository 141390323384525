import React from 'react'

import { Button, Flex, Stack, Text, useToast } from '@chakra-ui/react'

import { useMutationDeleteForm } from '@/api/forms/form/mutations'
import Modal from '@/components/Modal'
import { getErrorDetails } from '@/utils/error'

import { IModalProps } from '../types'

export const ModalDeleteForm = ({
  coForm,
  isOpen,
  onClose,
  onSuccess,
}: IModalProps): JSX.Element => {
  const toast = useToast()
  const { mutate: deleteForm, isLoading: isDeleting } = useMutationDeleteForm({
    onSuccess: () => {
      onClose()
      onSuccess?.()
      toast({
        status: 'success',
        title: 'Formulário deletado com sucesso!',
      })
    },
    onError: error => {
      toast({
        status: 'error',
        title: 'Ops! Ocorreu um erro',
        description: getErrorDetails(error),
        isClosable: true,
      })
    },
  })

  const handleDelete = () => {
    if (coForm) {
      deleteForm({
        coForm,
      })
    }
  }

  return (
    <Modal
      title="Excluir formulário"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Stack spacing="4">
        <Text fontSize="sm" color="brand.primary.dark_2">
          <strong>Esta ação é irreversível!</strong> Tem certeza que deseja
          excluir esse formulário permanentemente?
        </Text>
        <Flex
          justifyContent="right"
          gap="12px"
          alignItems="center"
          flexDirection="row"
        >
          <Button
            variant="error"
            borderRadius="full"
            isLoading={isDeleting}
            onClick={handleDelete}
          >
            Excluir formulário
          </Button>
          <Button
            variant="outline"
            borderRadius="full"
            isDisabled={isDeleting}
            onClick={onClose}
          >
            Cancelar
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
