import { useNavigate } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'

import { useMutationDeleteFormQuestion } from '@/api/forms/questions/mutations'
import { getErrorDetails } from '@/utils/error'

export const useDeleteQuestionController = (
  coFormQuestion: string,
  onClose: () => void
) => {
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: deleteQuestion, isLoading: isDeleting } =
    useMutationDeleteFormQuestion({
      onSuccess: async () => {
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'Questão excluída com sucesso!',
          isClosable: true,
        })
        await queryClient.invalidateQueries(['formQuestions'])
        onClose()
        navigate('/formularios')
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const handleDelete = () => {
    deleteQuestion({ coFormQuestion: Number(coFormQuestion) })
  }

  return {
    handleDelete,
    isDeleting,
  }
}
