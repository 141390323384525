import React from 'react'
import { useMemo, useState } from 'react'
import { FaClipboardList } from 'react-icons/fa6'
import { useNavigate, useParams } from 'react-router-dom'

import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  CircularProgress,
  Flex,
  IconButton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { useQueryGetAnalysisMyVolumes } from '@/api/analysis/queries'
import { useQueryGetObjectsVolumes } from '@/api/objects/queries'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'
import ExmptyObjectsPanel from '@/pages/Panel/Objects/EmptyObjects'
import { getErrorDetails } from '@/utils/error'

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
  {
    name: '',
    key: 'viewVolume',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

export const tableHeadersCoordinator: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Estado',
    key: 'status',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: '',
    key: 'viewVolume',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

export const statusFilter: IFilter = {
  name: 'status',
  label: 'Status',
  type: 'select',
  options: [
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
    { label: 'Finalizado', value: 'finished' },
    { label: 'Em andamento', value: 'ongoing' },
    { label: 'Aguardando vínculo', value: 'waiting_bond' },
    { label: 'Fase Externa', value: 'external_phase' },
    { label: 'Não iniciado', value: 'not_started' },
  ],
}

const TableVolumesDetailsObject: React.FC = () => {
  const { coObject } = useParams()

  const toast = useToast()
  const navigate = useNavigate()

  // TODO: RQ-12 Waiting for api to implement validation
  const isProfileCoordinator = false

  const [filter, setFilter] = useState({
    search: '',
    stage: '',
  })

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  // #TODO: Verificar volumes da tabela
  // const { data: myVolumes } = useQueryGetAnalysisMyVolumes(
  //   { coStageObject: String(1) }, // PENDENTES OU FINALIZADOS
  //   {
  //     onSuccess: data => {
  //       setPagination({
  //         ...pagination,
  //         page: data?.pagination?.currentPage,
  //         pageSize: data?.pagination?.pageSize || 10,
  //         itemsCount: data?.pagination?.count,
  //         pageCount: data?.pagination?.totalPages,
  //       })
  //     },
  //     onError: error => {
  //       toast({
  //         title: 'Erro recebendo os dados, contate o suporte.',
  //         description: getErrorDetails(error),
  //         status: 'error',
  //         duration: 3000,
  //         isClosable: true,
  //       })
  //     },
  //   }
  // )

  const { data: volumes, isLoading } = useQueryGetObjectsVolumes(
    { idObject: String(coObject) },
    {
      coObjectDigitalPnld: filter.search,
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data?.pagination?.currentPage,
          pageSize: data?.pagination?.pageSize || 10,
          itemsCount: data?.pagination?.count,
          pageCount: data?.pagination?.totalPages,
        })
      },
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const filterOptions: Array<IFilter> = useMemo(
    () => [
      {
        name: 'stage',
        label: 'Fase',
        type: 'text',
        icon: <SearchIcon />,
      },
    ],
    []
  )

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      stage: filters.stage || '',
    })
  }

  const data = useMemo(() => {
    if (!volumes) return []

    return volumes?.results?.map(volume => ({
      id: volume.dsVolumeTitle,
      volumeCode: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {volume.dsVolumeCode}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {volume.nuEdition || 'Não informado'} |{' '}
              {volume.coDeed.dsCollectionTitle || 'Não informado'} | Versão
            </Text>
          </Box>
        </VStack>
      ),
      exemplar: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Livro do aluno
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Formato | Duração/páginas | Média de tempo
            </Text>
          </Box>
        </VStack>
      ),
      status: <Tag group="statusAnalysisVolume" value={'awaitingApproval'} />,
      viewVolume: (
        <IconButton
          aria-label="Ver detalhes do objeto"
          variant="ghost"
          size="sm"
          icon={<FaClipboardList size="20px" />}
          color="brand.primary.dark_1"
          onClick={() =>
            navigate(
              `../../objetos/detalhes-objeto-volumes/${coObject}/formulario-volume/${volume.coVolume}`
            )
          }
        />
      ),
    }))
  }, [volumes])

  const renderObjectsVolumes = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
        >
          <VStack spacing={4}>
            <Text>Buscando dados dos volumes...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    if (!volumes) {
      return <ExmptyObjectsPanel />
    }

    return (
      <Flex direction="column" flex={1}>
        <Box overflowX="auto" flex={1} py={4}>
          <DataTable
            key="table-objects-volumes"
            data={data}
            headers={
              isProfileCoordinator ? tableHeadersCoordinator : tableHeaders
            }
            selectableRow={false}
          />
        </Box>
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          itemsCount={pagination.itemsCount}
          pageCount={pagination.pageCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Flex>
    )
  }

  return (
    <Box w="full">
      <Box py={4}>
        <DataFilter
          onlySearch
          filters={filterOptions}
          onChangeFilter={handleFilters}
        />
      </Box>
      <Box>{renderObjectsVolumes()}</Box>
    </Box>
  )
}

export default TableVolumesDetailsObject
