import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusQuestions'

import { useQuestionDetailsController } from '../../../controller'
import ContentEmptyTab from '../../ContentEmptyTab'
import {
  BoxInfoTable,
  filtersTableForms,
  getFormStatus,
  InfoFormTable,
  tableHeaders,
} from './DataTableForms'

const FormsTab: React.FC = () => {
  const { coQuestion } = useParams()
  const { questionData } = useQuestionDetailsController(coQuestion as string)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: questionData?.forms?.length || 0,
    pageCount: Math.ceil((questionData?.forms?.length || 0) / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil((questionData?.forms?.length || 0) / itemsPerPage),
    })
  }

  const tableData =
    questionData?.forms?.map(formDetail => ({
      form: (
        <InfoFormTable
          formId={formDetail.form.dsForm}
          status={formDetail.form.stLink}
        />
      ),
      object: (
        <BoxInfoTable
          title={formDetail.form.dsObject || 'Não informado'}
          subtitle={formDetail.form.dsNotice || 'Não informado'}
        />
      ),
      status: StatusTag({ tag: getFormStatus(formDetail.form.stLink) }),
      date: (
        <BoxInfoTable
          title={new Date(formDetail.dtCreatedAt).toLocaleDateString()}
          subtitle={new Date(formDetail.dtCreatedAt).toLocaleTimeString()}
          alignCenter
        />
      ),
    })) || []

  return (
    <>
      {!tableData.length ? (
        <ContentEmptyTab />
      ) : (
        <>
          <DataFilter filters={filtersTableForms} onChangeFilter={() => {}} />
          <Box mt={10}>
            <DataTable headers={tableHeaders} data={tableData} />
            <Pagination
              pageIndex={pagination.page - 1}
              optionsItemsPerPage={pagination.pageSizeOptions}
              itemsPerPage={pagination.pageSize}
              pageCount={pagination.pageCount}
              itemsCount={pagination.itemsCount}
              onChangePageInfo={handleOnChangePageInfo}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default FormsTab
