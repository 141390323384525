import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Image, Hide } from '@chakra-ui/react'

import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import S from '@/common/Templates/TemplateLogin/styles'

const FirstAccess: React.FC = () => {
  const navigate = useNavigate()

  const onClickContinue = () => {
    navigate('/')
  }

  return (
    <S.MainBox maxWidth="596px" backgroundColor="white">
      <Hide below="sm">
        <Box display="flex" justifyContent="center">
          <Image height="75px" src={ColorfulLogoSARE} alt="Logo Sare" />
        </Box>
      </Hide>
      <Box>
        <S.Title color="green.600" marginBottom={4}>
          Bem-vindo(a)!
        </S.Title>
        <S.Subtitle color="brand.primary.dark_1">
          Ficamos felizes em informar que seu acesso foi liberado com sucesso!
          Nossa equipe revisou suas credenciais e agora você pode desfrutar de
          todas as funcionalidades do sistema.
        </S.Subtitle>
      </Box>
      <Box display="flex" justifyContent="center">
        <S.MainButton
          variant="solid"
          border="none"
          color="white"
          backgroundColor="brand.primary.dark_1"
          _hover={{ backgroundColor: 'brand.primary.dark_2', color: 'white' }}
          onClick={onClickContinue}
        >
          Entrar
        </S.MainButton>
      </Box>
    </S.MainBox>
  )
}

export default FirstAccess
