import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import {
  postAuthGovBr,
  postAuthLocalLogin,
  postAuthRefresh,
  postAuthLogout,
} from './services'
import {
  PostAuthGovBrParams,
  PostAuthGovBrResponse,
  PostAuthLocalLoginParams,
  PostAuthLocalLoginResponse,
  PostAuthRefreshParams,
  PostAuthRefreshResponse,
  PostAuthLogoutParams,
  PostAuthLogoutResponse,
} from './types'

export const useMutationPostAuthGovBr = (
  options?: UseMutationOptions<
    PostAuthGovBrResponse,
    AxiosError<Error>,
    PostAuthGovBrParams
  >
) => {
  return useMutation(postAuthGovBr, options)
}

export const useMutationPostAuthLocalLogin = (
  options?: UseMutationOptions<
    PostAuthLocalLoginResponse,
    AxiosError<Error>,
    PostAuthLocalLoginParams
  >
) => {
  return useMutation(postAuthLocalLogin, options)
}

export const useMutationPostAuthRefresh = (
  options?: UseMutationOptions<
    PostAuthRefreshResponse,
    AxiosError<Error>,
    PostAuthRefreshParams
  >
) => {
  return useMutation(postAuthRefresh, options)
}

export const useMutationPostAuthLogout = (
  options?: UseMutationOptions<
    PostAuthLogoutResponse,
    AxiosError<Error>,
    PostAuthLogoutParams
  >
) => {
  return useMutation(postAuthLogout, options)
}
