import React, { useState } from 'react'
import {
  FaChevronLeft,
  FaChevronCircleRight,
  FaCheckCircle,
} from 'react-icons/fa'

import { Stack, Text, Flex, Button, Box } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabInfo } from '@/components/Tabs/types'

import { IStepControlProps } from '../types'
import TeamTab from './TeamTab'
import VolumesTable from './VolumesTables'

const EvaluatorsSelection: React.FC<IStepControlProps> = ({ goToPrevious }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const accordionItems = [
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
      tag: 'Teste',
    },
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
    },
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
    },
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
    },
  ]

  const tabsItems = Array.from({ length: 10 }).map((_, index) => ({
    id: `tab-${index}`,
    label: `Volume ${index + 1}`,
    render: () => <TeamTab accordionItems={accordionItems} />,
  }))

  return (
    <Stack>
      <Stack gap={2}>
        <Text fontSize="xl" color="#0C326F" fontWeight="bold">
          Seleção de avaliadores
        </Text>
        <Text fontSize="xs">
          Selecione dois avaliadores para cada volume de cada equipe:
        </Text>
      </Stack>
      <Box>
        <Tabs
          index={tabIndex}
          items={tabsItems}
          variant="rounded"
          size="sm"
          onTabClick={({ index }: ITabInfo) => {
            setTabIndex(index)
          }}
        />
      </Box>
      <Flex justify="space-between">
        <Button
          size="sm"
          key="go-back"
          variant="ghost"
          leftIcon={<FaChevronLeft />}
          color="brand.primary.dark_1"
          onClick={() =>
            tabIndex !== 0 ? setTabIndex(tabIndex - 1) : goToPrevious?.()
          }
        >
          Voltar
        </Button>
        {tabIndex !== tabsItems.length - 1 ? (
          <Button
            type="submit"
            size="sm"
            bg="brand.primary.dark_1"
            _hover={{ bg: 'brand.primary.dark_2' }}
            color="white"
            leftIcon={<FaChevronCircleRight />}
            isDisabled={tabIndex === tabsItems.length - 1}
            onClick={() => setTabIndex(tabIndex + 1)}
          >
            Próxima equipe
          </Button>
        ) : (
          <Button
            type="submit"
            size="sm"
            bg="brand.success.base"
            _hover={{ bg: 'brand.success.dark' }}
            color="white"
            leftIcon={<FaCheckCircle />}
          >
            Finalizar distribuição
          </Button>
        )}
      </Flex>
    </Stack>
  )
}

export default EvaluatorsSelection
