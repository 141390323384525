import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { postDeeds } from './services'
import { TPostDeedsParams, TPostDeedsResponse } from './types'

export const useMutationPostDeeds = (
  options?: UseMutationOptions<
    TPostDeedsResponse,
    AxiosError<Error>,
    TPostDeedsParams
  >
) => {
  return useMutation(postDeeds, options)
}
