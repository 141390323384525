import React from 'react'

import { Field, ErrorMessage, FieldProps } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Skeleton,
} from '@chakra-ui/react'

import { MaskedInput } from '@/components/MaskedInput'

import { IInputProps } from './types'

export const InputWithMask: React.FC<IInputProps> = ({
  name,
  label,
  mask,
  type,
  placeholder,
  helperText,
  inputProps,
  isLoading,
  isDisabled,
  ...rest
}) => {
  return (
    <FormControl {...rest} isDisabled={isDisabled}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <Field name={name}>
          {({ field, form }: FieldProps) => (
            <MaskedInput
              mask={mask}
              variant="filled"
              bg="brand.neutral.light_2"
              fontSize="sm"
              type={type}
              placeholder={placeholder}
              _focusVisible={{ bg: 'gray.100' }}
              {...inputProps}
              {...field}
              onChange={e => form.setFieldValue(field.name, e.target.value)}
            />
          )}
        </Field>
      </Skeleton>
      {helperText && (
        <FormHelperText opacity={isDisabled ? 0.4 : 1}>
          {helperText}
        </FormHelperText>
      )}
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
