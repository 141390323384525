import React, { useEffect, useRef, useState } from 'react'

import { Form, Formik, FormikProps, FieldArray } from 'formik'

import {
  Box,
  Button,
  Divider,
  Flex,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'

import { Input, BasicSelect } from '@/components/Forms'
import Modal from '@/components/Modal'
import Switch from '@/components/Switch'
import { AnswerEnum, FormQuestion } from '@/types/form.types'

import AlternativeQuestion from './AlternativeQuestion'
import AnswerTypeFields from './AnswerTypeFields'
import {
  cleanSubmit,
  useRegisterQuestionController,
  validationSchema,
} from './controller'
import {
  IRegisterQuestionModalProps,
  IRegisterQuestionFormValues,
} from './types'

const RegisterQuestionModal: React.FC<IRegisterQuestionModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const formikRef = useRef<FormikProps<IRegisterQuestionFormValues>>(null)
  const toast = useToast()

  const {
    selectOptions,
    isSubmitting,
    onSubmit,
    initialValues,
    createNewQuestion,
  } = useRegisterQuestionController((data: FormQuestion) => {
    onSuccess?.(data)
  }, onClose)

  const [showAlternativeSection, setShowAlternativeSection] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setShowAlternativeSection(false)
    }
  }, [isOpen])

  const handleClose = () => {
    setShowAlternativeSection(false)
    if (formikRef.current) {
      formikRef.current.resetForm()
    }
    onClose()
  }

  const canHaveAlternativeQuestions = (answerModel: string): boolean => {
    return ![
      AnswerEnum.numeric.toString(),
      AnswerEnum.text.toString(),
      '',
    ].includes(answerModel)
  }

  const handleSubmit = (values: IRegisterQuestionFormValues) => {
    const finalValues = cleanSubmit(values, showAlternativeSection)

    if (
      showAlternativeSection &&
      finalValues.alternativeQuestions.length === 0
    ) {
      toast({
        title: 'Erro',
        description: 'Adicione ao menos uma questão alternativa',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    // TODO: Quando a API estiver pronta, alterar as variaveis para combinar com a API e descomentar a linha abaixo
    console.log(finalValues)
    // onSubmit(finalValues)
  }

  const handleSwitchChange = (checked: boolean) => {
    setShowAlternativeSection(checked)
  }

  const renderFooter = () => (
    <Flex justify="flex-end" width="100%">
      <Button
        variant="outline"
        size="sm"
        onClick={onClose}
        type="button"
        borderRadius={50}
        mr={3}
      >
        Cancelar
      </Button>
      <Button
        size="sm"
        bg="brand.primary.dark_1"
        color="white"
        _hover={{ bg: 'brand.primary.dark_2' }}
        type="submit"
        isLoading={isSubmitting}
        borderRadius={50}
        form="question-form"
      >
        Cadastrar
      </Button>
    </Flex>
  )

  return (
    <Modal
      title="Cadastrar questão"
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size={{ base: 'sm', md: '3xl', xl: '4xl' }}
      scrollBehavior="inside"
      footer={renderFooter()}
    >
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ values, setFieldValue }) => (
          <Form id="question-form">
            <Stack gap={4}>
              <Input
                label="Título da questão principal"
                name="title"
                placeholder="---"
              />

              <Flex gap="4" flexDirection={{ base: 'column', md: 'row' }}>
                <BasicSelect
                  options={selectOptions.formats}
                  label="Formato do volume"
                  name="formatVolume"
                  placeholder="---"
                />
                <BasicSelect
                  options={selectOptions.volumeVersion}
                  label="Versão do volume"
                  name="versionVolume"
                  placeholder="---"
                />
                <BasicSelect
                  options={selectOptions.exemplaries}
                  label="Exemplar do volume"
                  name="exemplaryVolume"
                  placeholder="---"
                />
              </Flex>

              <Flex gap="4" flexDirection={{ base: 'column', md: 'row' }}>
                <BasicSelect
                  options={selectOptions.failureTypes}
                  label="Código da falha"
                  name="diligence"
                  placeholder="---"
                  isDisabled
                />
                <BasicSelect
                  options={selectOptions.answerModels}
                  label="Tipo de resposta"
                  name="answerModel"
                  placeholder="---"
                  onChange={value => {
                    const previousValue = values.answerModel
                    const previousSupportsAlternatives =
                      canHaveAlternativeQuestions(previousValue)
                    const newSupportsAlternatives =
                      canHaveAlternativeQuestions(value)

                    setFieldValue('answerModel', value)

                    if (
                      !newSupportsAlternatives ||
                      (previousSupportsAlternatives && newSupportsAlternatives)
                    ) {
                      setShowAlternativeSection(false)
                    }
                  }}
                />
                <BasicSelect
                  options={selectOptions.showPages}
                  label="Informar as páginas?"
                  name="showPages"
                  placeholder="---"
                />
              </Flex>
              <Flex align="center">
                <Switch
                  key={values.answerModel}
                  label="Esse item possui questões alternativas?"
                  onChange={handleSwitchChange}
                  disabled={!canHaveAlternativeQuestions(values.answerModel)}
                />
                <Text
                  fontSize="sm"
                  ml={2}
                  color={
                    values.answerModel === AnswerEnum.bool.toString() ||
                    values.answerModel === AnswerEnum.multipleChoice.toString()
                      ? 'brand.neutral.dark_2'
                      : 'brand.neutral.light_1'
                  }
                >
                  {showAlternativeSection ? 'Sim' : 'Não'}
                </Text>
              </Flex>

              <Input
                type="textarea"
                label="Descrição"
                name="description"
                placeholder="Descrição"
                helperText="Caso necessário, descreva informações para auxiliar analistas à responderem a questão"
                resize="none"
              />
            </Stack>
            {values.answerModel && (
              <Box>
                <Divider my={4} borderColor="gray.300" />
                <AnswerTypeFields
                  answerType={values.answerModel}
                  hasAlternatives={showAlternativeSection}
                />
              </Box>
            )}
            {showAlternativeSection && (
              <Box mt={6}>
                <Divider my={4} borderColor="gray.300" />

                <FieldArray
                  name="alternativeQuestions"
                  render={arrayHelpers => (
                    <>
                      {values.alternativeQuestions &&
                        values.alternativeQuestions.length > 0 &&
                        values.alternativeQuestions.map((question, index) => (
                          <AlternativeQuestion
                            key={question.id}
                            index={index}
                            selectOptions={selectOptions}
                            onRemove={() => arrayHelpers.remove(index)}
                          />
                        ))}

                      <Button
                        onClick={() => arrayHelpers.push(createNewQuestion())}
                        width="100%"
                        size="sm"
                        colorScheme="orange"
                        bg="#E9AB65"
                        _hover={{ bg: '#D99B55' }}
                        mb={4}
                      >
                        Adicionar questão alternativa
                      </Button>
                    </>
                  )}
                />
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default RegisterQuestionModal
