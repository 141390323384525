import http from '@/api/http'

import {
  TGetAnalysisMyVolumesParams,
  TGetAnalysisMyVolumesResponse,
  TGetFormAnalysisEvaluationSupervisor,
  TGetFormAnalysisVolume,
  TGetFormAnalysisVolumeParams,
  TPostAnalysisVolumeSupervisorBody,
  TPostFormAnalysisVolumeBody,
  TPostReanalysisVolumesCoordinatorBody,
  TPostReanalysisVolumeSupervisorBody,
} from './types'

export const getFormAnalysisVolume = async (
  params: TGetFormAnalysisVolumeParams
) => {
  const response = await http.get<TGetFormAnalysisVolume>(
    '/analysis/volume_analysis/retrieve_form/',
    {
      params,
    }
  )

  return response.data
}

export const getAnalysisMyVolumes = async (
  params: TGetAnalysisMyVolumesParams
) => {
  const response = await http.get<TGetAnalysisMyVolumesResponse>(
    '/analysis/volume_analysis/my_volumes/',
    {
      params,
    }
  )

  return response.data
}

export const postFormAnalysisVolume = async (
  body: TPostFormAnalysisVolumeBody
) => {
  const response = await http.post('/analysis/volume_analysis/', {
    body,
  })

  return response.data
}

export const getFormAnalysisEvaluationSupervisor = async (
  params: TGetFormAnalysisVolumeParams
) => {
  const response = await http.get<TGetFormAnalysisEvaluationSupervisor>(
    '/analysis/evaluation/retrieve_form/',
    {
      params,
    }
  )

  return response.data
}

export const postRenalysisVolumeSupervisor = async (
  body: TPostReanalysisVolumeSupervisorBody
) => {
  const response = await http.post('/analysis/volume_analysis/reanalysis/', {
    body,
  })

  return response.data
}

export const postAnalysisVolumeSupervisor = async (
  body: TPostAnalysisVolumeSupervisorBody
) => {
  const response = await http.post('/analysis/evaluation/', {
    body,
  })

  return response.data
}

export const postReanalysisVolumesCoordinator = async (
  body: TPostReanalysisVolumesCoordinatorBody
) => {
  const response = await http.post('/analysis/evaluation/reanalysis', {
    body,
  })

  return response.data
}
