import React from 'react'

import { Field, ErrorMessage, FieldProps } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Skeleton,
} from '@chakra-ui/react'

import CustomSelect from '@/components/Select'

import { ISelectProps } from './types'

export const Select: React.FC<ISelectProps> = ({
  name,
  label,
  options,
  placeholder,
  helperText,
  inputProps,
  onChange,
  isLoading,
  isMultiple,
  isSearchable,
  noOptionsMessage,
  isDisabled,
  ...rest
}) => {
  return (
    <FormControl {...rest} isDisabled={isDisabled}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <Field name={name}>
          {({ form }: FieldProps) => (
            <CustomSelect
              variant="filled"
              options={options}
              placeholder={placeholder}
              noOptionsMessage={() =>
                noOptionsMessage || 'Nenhuma opção encontrada'
              }
              isMulti={isMultiple}
              isSearchable={isSearchable}
              onChange={value => {
                form.setFieldValue(name, value)
                onChange?.(value)
              }}
              {...inputProps}
            />
          )}
        </Field>
      </Skeleton>
      {helperText && (
        <FormHelperText opacity={isDisabled ? 0.4 : 1}>
          {helperText}
        </FormHelperText>
      )}
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
