import React, { useCallback, useMemo, useState } from 'react'
import { FaEye } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  CircularProgress,
  Flex,
  IconButton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { useQueryGetObjects } from '@/api/objects/queries'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'
import { statusObject } from '@/components/Tag/StatusObject/types'
import { reverseDate } from '@/utils/date'
import { getErrorDetails } from '@/utils/error'

import ExmptyObjectsPanel from '../EmptyObjects'

const TableHeaders: Array<IHeader> = [
  {
    name: 'Objeto',
    key: 'object',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Fase',
    key: 'phase',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: '',
    key: 'viewDetailsObject',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

const ManagementObjects = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const [filter, setFilter] = useState({
    search: '',
    startDate: '',
    endDate: '',
    stage: '',
  })

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const filterOptions: Array<IFilter> = useMemo(
    () => [
      {
        name: 'stage',
        label: 'Fase',
        type: 'text',
        icon: <SearchIcon />,
      },
    ],
    []
  )

  const getObjectStatus = (status: string) => {
    switch (status) {
      case 'ATIVO':
        return 'active'
      case 'INATIVO':
        return 'finished_red'
      default:
        return 'default'
    }
  }

  const { data: objects, isLoading } = useQueryGetObjects(
    {
      coObjectDigitalPnld: filter.search,
      initialDate: filter.startDate,
      finalDate: filter.endDate,
      dsStage: filter.stage,
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage,
          pageSize: data.pagination.pageSize || 10,
          itemsCount: data.pagination.count,
          pageCount: data.pagination.totalPages,
        })
      },
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      startDate: reverseDate(filters.startDate) || '',
      endDate: reverseDate(filters.endDate) || '',
      stage: filters.stage || '',
    })
  }

  const randomStatus = useCallback((index: number) => {
    const statuses: statusObject[] = [
      'finished_red',
      'active',
      'inactive',
      'waiting_bond',
    ]
    return statuses[index % statuses.length]
  }, [])

  const data = useMemo(() => {
    if (!objects) return []
    return objects?.results?.map(object => ({
      id: object.coObject,
      object: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {object.dsObject || 'Não informado'}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {new Date(object?.notice?.dtCreatedAt).getFullYear()} |{' '}
              {new Date(object?.notice?.dtPublication).getFullYear()}
            </Text>
          </Box>
        </VStack>
      ),
      phase: object.dsStage || 'Não informado',
      status: object?.dsStatus && (
        <Tag group="statusObjects" value={getObjectStatus(object?.dsStatus)} />
      ),
      viewDetailsObject: (
        <IconButton
          aria-label="Ver detalhes do objeto"
          variant="ghost"
          size="sm"
          icon={<FaEye size="20px" />}
          color="brand.primary.dark_1"
          onClick={() =>
            navigate(`../../objetos/detalhes-objeto-volumes/${object.coObject}`)
          }
        />
      ),
    }))
  }, [objects, randomStatus])

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const renderObjects = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
        >
          <VStack spacing={4}>
            <Text>Buscando dados dos objetos...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    if (!objects) {
      return <ExmptyObjectsPanel />
    }

    return (
      <Flex direction="column" flex={1}>
        <Box overflowX="auto" flex={1} py={4}>
          <DataTable data={data} headers={TableHeaders} />
        </Box>
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          itemsCount={pagination.itemsCount || objects?.results?.length}
          pageCount={pagination.pageCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        boxShadow="md"
        p={4}
      >
        <DataFilter filters={filterOptions} onChangeFilter={handleFilters} />
      </Flex>
      <Flex
        borderRadius="md"
        boxShadow="md"
        direction="column"
        flex={1}
        w="100%"
        p={5}
        sx={{
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            'th, td': { whiteSpace: 'nowrap' },
          },
        }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          sx={{
            '@media (max-width: 500px)': {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              'th, td': { whiteSpace: 'nowrap' },
            },
          }}
        >
          <Text
            fontSize="xl"
            color="brand.primary.dark_1"
            fontWeight="semibold"
          >
            Gestão de Objetos
          </Text>
        </Flex>
        {renderObjects()}
      </Flex>
    </Flex>
  )
}

export default ManagementObjects
