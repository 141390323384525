import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusUserStytemTagProps, IStatusUserStytemTagStyles } from './types'

const styles: IStatusUserStytemTagStyles = {
  ATIVO: {
    name: 'Ativo',
    backgroundColor: '#E3F5E1',
    color: '#168821',
  },
  INDISPONIVEL: {
    name: 'Indisponível',
    backgroundColor: '#FFF3F2',
    color: '#E60000',
  },
  DISPONIVEL: {
    name: 'Disponível',
    backgroundColor: '#EDF5FF',
    color: 'brand.primary.dark_1',
  },
  PRE_CADASTRO: {
    name: 'Pré-cadastro',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
  default: {
    name: 'default',
    backgroundColor: 'gray.500',
  },
}

const StatusTag: React.FC<IStatusUserStytemTagProps> = ({ tag, ...rest }) => {
  const { name, backgroundColor, color } = styles[tag] || styles.default

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
