import React from 'react'
import { IoHelpCircle } from 'react-icons/io5'

import { Text, Flex, Icon } from '@chakra-ui/react'

export const EmptyQuestions: React.FC = () => {
  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        padding="5"
        gap="8"
      >
        <Icon
          as={IoHelpCircle}
          boxSize="128px"
          color="brand.primary.dark_1"
          rotate="30deg"
        />
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Cadastre sua primeira questão
        </Text>
      </Flex>
    </>
  )
}
