import React, { useContext, createContext, useState, useEffect } from 'react'

import { QuestionGroup } from '@/pages/Checklists/Checklist/types'

interface QuestionManagementContextType {
  questions: QuestionGroup[]
  setQuestions: React.Dispatch<React.SetStateAction<QuestionGroup[]>>
  addNewQuestions: (question: QuestionGroup[]) => void
  setQuestionsToAdd: React.Dispatch<React.SetStateAction<number[]>>
  setQuestionsToRemove: React.Dispatch<React.SetStateAction<number[]>>
  removeQuestionById: (questionId: number) => void
  resetQuestions: () => void
  getUniqueQuestions: () => {
    questionsToAdd: number[]
    questionsToRemove: number[]
  }
}

const QuestionManagementContext = createContext<
  QuestionManagementContextType | undefined
>(undefined)

export const QuestionManagementProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [questions, setQuestions] = useState<QuestionGroup[]>([])
  const [questionsToAdd, setQuestionsToAdd] = useState<number[]>([])
  const [questionsToRemove, setQuestionsToRemove] = useState<number[]>([])

  const addNewQuestions = (newQuestions: QuestionGroup[]) => {
    setQuestions([...questions, ...newQuestions])
  }

  const removeQuestionById = (questionId: number) => {
    setQuestions(questions.filter(question => question.id !== questionId))
  }

  const resetQuestions = () => {
    setQuestions([])
    setQuestionsToAdd([])
    setQuestionsToRemove([])
  }

  const getUniqueQuestions = () => {
    const set1 = new Set(questionsToAdd)
    const set2 = new Set(questionsToRemove)

    const uniqueQuestionsToAdd = questionsToAdd.filter(num => !set2.has(num))
    const uniqueQuestionsToRemove = questionsToRemove.filter(
      num => !set1.has(num)
    )

    return {
      questionsToAdd: uniqueQuestionsToAdd,
      questionsToRemove: uniqueQuestionsToRemove,
    }
  }

  return (
    <QuestionManagementContext.Provider
      value={{
        questions,
        setQuestions,
        addNewQuestions,
        setQuestionsToAdd,
        setQuestionsToRemove,
        removeQuestionById,
        resetQuestions,
        getUniqueQuestions,
      }}
    >
      {children}
    </QuestionManagementContext.Provider>
  )
}

export const useQuestionManagement = () => {
  const context = useContext(QuestionManagementContext)

  if (!context) {
    throw new Error(
      'useQuestionManagement must be used within an QuestionManagementProvider'
    )
  }

  return context
}
