import React from 'react'

import { Flex } from '@chakra-ui/react'

import ManagementObjects from '@/pages/Panel/Objects/ManagementObjects'

import ManagementDetailsObjects from './ManagementDetailsObjects'

const Objects = () => {
  const roleProfile: 'admin' | 'analyst' = 'admin'

  const renderObjects = () => {
    const pageByRole = {
      admin: <ManagementDetailsObjects />,
      analyst: <ManagementObjects />,
    }

    return pageByRole[roleProfile]
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      {renderObjects()}
    </Flex>
  )
}

export default Objects
