import React, { useCallback, useMemo, useState } from 'react'
import { FaEye } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  CircularProgress,
  Flex,
  IconButton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { useQueryGetVolumes } from '@/api/volumes/queries'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'
import { statusObject } from '@/components/Tag/StatusObject/types'
import { reverseDate } from '@/utils/date'
import { getErrorDetails } from '@/utils/error'

import ExmptyVolumes from '../EmptyVolumes'

const TableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volume',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplary',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Fase',
    key: 'phase',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: '',
    key: 'viewDetailsVolume',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

const ManagementVolumes: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const [filter, setFilter] = useState({
    search: '',
    startDate: '',
    endDate: '',
    stage: '',
  })

  const filterOptions: Array<IFilter> = useMemo(
    () => [
      {
        name: 'stage',
        label: 'Fase',
        type: 'text',
        icon: <SearchIcon />,
      },
    ],
    []
  )

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      startDate: reverseDate(filters.startDate) || '',
      endDate: reverseDate(filters.endDate) || '',
      stage: filters.stage || '',
    })
  }

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const { data: volumes, isLoading } = useQueryGetVolumes(
    {
      dsVolumeCode: filter.search,
      initialDate: filter.startDate,
      finalDate: filter.endDate,
      // dsStage: filter.stage,
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage,
          pageSize: data.pagination.pageSize || 10,
          itemsCount: data.pagination.count,
          pageCount: data.pagination.totalPages,
        })
      },
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const randomStatus = useCallback((index: number) => {
    const statuses: statusObject[] = [
      'finished_red',
      'active',
      'inactive',
      'waiting_bond',
    ]
    return statuses[index % statuses.length]
  }, [])

  const data = useMemo(() => {
    if (!volumes) return []
    return volumes?.results?.map((volume, index) => ({
      id: volume.coVolume,
      volume: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {volume.dsVolumeCode}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {volume.nuEdition} | {volume.coDeed.dsCollectionTitle} |{' '}
              {volume.nuEditionYear}
            </Text>
          </Box>
        </VStack>
      ),
      exemplary: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Livro do aluno
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              PDF | 300 pg | 30 min/av
            </Text>
          </Box>
        </VStack>
      ),
      phase: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Validação de inscrição
            </Text>
          </Box>
        </VStack>
      ),
      status: (
        <Tag
          group="statusVolumes"
          // value="active"
          value={randomStatus(index)}
        />
      ),
      viewDetailsVolume: (
        <IconButton
          aria-label="Ver detalhes do volume"
          variant="ghost"
          size="sm"
          icon={<FaEye size="20px" />}
          color="brand.primary.dark_1"
          onClick={() =>
            navigate(
              `../../objetos/detalhes-objeto-volumes/${volume.coVolume}/formulario-volume/${volume.coVolume}`
            )
          }
        />
      ),
    }))
  }, [volumes, randomStatus])

  const renderVolumes = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
        >
          <VStack spacing={4}>
            <Text>Buscando dados dos volumes...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    if (!volumes || volumes?.results.length === 0) {
      return <ExmptyVolumes />
    }

    return (
      <Flex direction="column" flex={1}>
        <Box overflowX="auto" flex={1} py={4}>
          <DataTable headers={TableHeaders} data={data} />
        </Box>
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount || volumes?.results?.length}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      <Flex direction="column" flex={1}>
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="md"
          boxShadow="md"
          p={4}
        >
          <DataFilter filters={filterOptions} onChangeFilter={handleFilters} />
        </Flex>
        <Flex
          borderRadius="md"
          boxShadow="md"
          direction="column"
          flex={1}
          w="100%"
          p={5}
          sx={{
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              'th, td': { whiteSpace: 'nowrap' },
            },
          }}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            sx={{
              '@media (max-width: 500px)': {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                'th, td': { whiteSpace: 'nowrap' },
              },
            }}
          >
            <Text
              fontSize="xl"
              color="brand.primary.dark_1"
              fontWeight="semibold"
            >
              Gestão de Volumes
            </Text>
          </Flex>
          {renderVolumes()}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ManagementVolumes
