import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusAnalystTeamProps, IStatusAnalystTeamTagStyles } from './types'

const styles: IStatusAnalystTeamTagStyles = {
  active: {
    name: 'Ativo',
    backgroundColor: '#E3F5E1',
    color: '#168821',
  },
  inactive: {
    name: 'Inativo',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
}

const StatusTag: React.FC<IStatusAnalystTeamProps> = ({ tag, ...rest }) => {
  const { name, backgroundColor, color } = styles[tag] || styles.inactive

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
