import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { getObject, getObjects, getObjectsVolumes } from './services'
import {
  TGetObjectParams,
  TGetObjectResponse,
  TGetObjectsParams,
  TGetObjectsResponse,
  TGetObjectsVolumesResponse,
  TGetObjectVolumesParams,
} from './types'

export const useQueryGetObject = (
  idObject: TGetObjectParams,
  options?: UseQueryOptions<TGetObjectResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getObject(idObject.idObject)

  return useQuery<TGetObjectResponse, AxiosError<Error>>(
    useQueryGetObject.getFullQueryKey(idObject),
    queryFunction,
    options
  )
}

useQueryGetObject.getFullQueryKey = (idObject: TGetObjectParams): QueryKey => [
  'object',
  idObject,
]

useQueryGetObject.queryKey = ['object']

export const useQueryGetObjects = (
  filters: TGetObjectsParams,
  options?: UseQueryOptions<TGetObjectsResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getObjects(filters)

  return useQuery<TGetObjectsResponse, AxiosError<Error>>(
    useQueryGetObjects.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetObjects.getFullQueryKey = (filters: TGetObjectsParams): QueryKey => [
  'objects',
  filters,
]

useQueryGetObjects.queryKey = ['objects']

export const useQueryGetObjectsVolumes = (
  idObject: TGetObjectVolumesParams,
  filters: TGetObjectsParams,
  options?: UseQueryOptions<TGetObjectsVolumesResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getObjectsVolumes(idObject.idObject, filters)

  return useQuery<TGetObjectsVolumesResponse, AxiosError<Error>>(
    useQueryGetObjectsVolumes.getFullQueryKey(idObject, filters),
    queryFunction,
    options
  )
}

useQueryGetObjectsVolumes.getFullQueryKey = (
  idObject: TGetObjectVolumesParams,
  filters: TGetObjectsParams
): QueryKey => ['objectsVolumes', idObject, filters]

useQueryGetObjectsVolumes.queryKey = ['objectsVolumes']
