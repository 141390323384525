import React, { useMemo, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { FaCircleExclamation } from 'react-icons/fa6'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Icon,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import { useMutationReanalysisVolumesCoordinator } from '@/api/analysis/mutations'
import { useQueryGetAnalysisMyVolumes } from '@/api/analysis/queries'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import { Input, BasicSelect } from '@/components/Forms'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import { getErrorDetails } from '@/utils/error'

import S from './styles'
import {
  IOpenReanalysisVolumesModalProps,
  TOpenReanalysisVolumesModalFormValues,
} from './types'

const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
]

const optionsSelect = [
  {
    value: '1',
    label: 'Analista 1',
  },
  {
    value: '2',
    label: 'Analista 2',
  },
  {
    value: '3',
    label: 'Analista 3',
  },
]

const OpenReanalysisVolumesModal: React.FC<
  IOpenReanalysisVolumesModalProps
> = ({ isOpen, onClose }) => {
  const toast = useToast()

  const [selectedRows, setSelectedRows] = useState<Array<RowData>>([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const disabledButtonReanalysis = selectedRows.length === 0

  const { data: myVolumesReanalysis, isLoading } = useQueryGetAnalysisMyVolumes(
    { coStageObject: String(1) }, // #TODO: Deve listar somente volumes pendentes
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data?.pagination?.currentPage,
          pageSize: data?.pagination?.pageSize || 10,
          itemsCount: data?.pagination?.count,
          pageCount: data?.pagination?.totalPages,
        })
      },
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const { mutate: openReanalise } = useMutationReanalysisVolumesCoordinator({
    onSuccess: () => {
      toast({
        title: 'Reanálise enviada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      onClose()
    },
    onError: () => {
      toast({
        title: 'Erro ao enviar reanálise',
        description: 'Tente novamente mais tarde',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      onClose()
    },
  })

  const validationSchema = yup.object({
    analyst: yup.string().required('O analista é obrigatório'),
    limitDate: yup.string().required('A data é obrigatória'),
  })

  const onSubmit = (values: TOpenReanalysisVolumesModalFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ncoVolumesIDs = selectedRows.map((row: any) => row?.id)

    const payload = {
      stReanalysisActor: 'SUPERVISOR', // values.analyst
      dtLimitDate: '2025-03-20T00:39:26.961Z', // values.limitDate, // EXEMPLO DO ENVIO: "dt_limit_date": "2025-03-20T00:39:26.961Z"
      ncoVolumeSupervisorEvaluation: ncoVolumesIDs,
    }

    openReanalise(payload)
    onClose()
  }

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const dataVolumesReanalysis = useMemo(() => {
    if (!myVolumesReanalysis) return []

    return myVolumesReanalysis?.results?.map(volume => ({
      id: volume?.coVolume,
      volumeCode: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {volume?.dsVolume}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {/* #TODO: Falta informar editora */}
              {volume?.dsCollection || 'Coleção não informada'} |{' '}
              {volume?.dsVersion?.toLocaleLowerCase() || 'Versão não informada'}
            </Text>
          </Box>
        </VStack>
      ),
      exemplar: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Livro do aluno
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {volume?.dsFormat}
              {/* #TODO: Falta informar quantidade de páginas */}
              {/* #TODO: Falta informar média de tempo */}
            </Text>
          </Box>
        </VStack>
      ),
    }))
  }, [myVolumesReanalysis])

  const renderMyVolumesReanalysis = () => {
    if (isLoading) {
      return (
        <Flex justifyContent="center" alignItems="center" flex={1} h={200}>
          <VStack spacing={4}>
            <Text>Buscando dados dos volumes...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    if (!myVolumesReanalysis || myVolumesReanalysis?.results?.length == 0) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height={200}
        >
          <Icon
            as={FaCircleExclamation}
            boxSize="64px"
            color="brand.primary.dark_1"
            rotate="30deg"
          />
          <Text
            color="brand.primary.dark_2"
            textAlign="center"
            fontSize="16px"
            fontWeight="700"
            lineHeight="120%"
            marginTop={6}
          >
            Nenhum volume encontrado para reanalisar
          </Text>
        </Flex>
      )
    }

    return (
      <Flex direction="column" flex={1}>
        <DataTable
          key="table-reanalysis-modal"
          rowId="id"
          data={dataVolumesReanalysis}
          headers={tableHeaders}
          onRowSelectionChange={rows => setSelectedRows(rows)}
          selectableRow
        />
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Flex>
    )
  }

  return (
    <Modal
      title="Deseja abrir reanálise dos volumes?"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: '5xl',
      }}
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          analyst: '',
          limitDate: '',
          ncoVolumeSupervisorEvaluation: [],
        }}
      >
        <Form>
          <Flex gap={8} mb={8}>
            <BasicSelect
              name="analyst"
              label="Quem irá fazer a reanálise dos volumes selecionados?"
              placeholder="Selecione o analista"
              options={optionsSelect}
            />
            <Input
              label="Tempo limite de reanálise"
              name="limitDate"
              type="date"
            />
          </Flex>
          {renderMyVolumesReanalysis()}
          <S.ModalActionButtons>
            <Button
              key="cancel"
              variant="outline"
              size="sm"
              borderRadius={50}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              key="confirm"
              type="submit"
              size="sm"
              leftIcon={<FaCheckCircle />}
              disabled={disabledButtonReanalysis}
              borderRadius={50}
            >
              Abrir reanálise
            </Button>
          </S.ModalActionButtons>
        </Form>
      </Formik>
    </Modal>
  )
}

export default OpenReanalysisVolumesModal
