import React from 'react'

import { Box, SimpleGrid, Stack } from '@chakra-ui/react'

import { Input, BasicSelect } from '@/components/Forms'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

import { IUserDetailsProps } from '../types'
import S from './styles'

const UserInfoForm: React.FC<IUserDetailsProps> = ({ userId }) => {
  const {
    isLoadingUserData,
    specialNeedsOptions,
    educationLevelsOptions,
    countries,
    states,
    cities,
    isLoadingCountries,
    isLoadingStates,
    isLoadingCities,
  } = useProfileContext()

  return (
    <Stack width="100%" gap={8}>
      <Stack gap={4}>
        <S.HeaderText>Dados Pessoais</S.HeaderText>
        <SimpleGrid columns={2} spacing={4}>
          <Input
            name="noUserName"
            label="Nome"
            placeholder="Nome"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="noUserSocialName"
            label="Nome Social"
            placeholder="Digite seu nome social"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dsCpf"
            label="CPF"
            placeholder="00.000.000-00"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dtBirthDate"
            label="Data de nascimento"
            type="date"
            inputProps={{ max: '2999-12-31' }}
            isDisabled
            isLoading={isLoadingUserData}
          />
          <BasicSelect
            name="coSpecialNeed"
            label="Deficiência"
            placeholder="Selecione uma opção"
            options={specialNeedsOptions || []}
            isDisabled
            isLoading={isLoadingUserData}
          />
          <BasicSelect
            name="coEducationLevel"
            label="Nível de Escolaridade"
            placeholder="Selecione uma opção"
            options={educationLevelsOptions || []}
            isDisabled
            isLoading={isLoadingUserData}
          />
        </SimpleGrid>
      </Stack>
      <Stack gap={4}>
        <S.HeaderText>Contatos</S.HeaderText>
        <SimpleGrid columns={2} spacing={4}>
          <Input
            name="dsInstitutionalEmail"
            label="E-mail institucional"
            placeholder="nome@nees.ufal.br"
            helperText="Escreva aqui o e-mail que deseja receber notificações do SARE."
            type="email"
            isLoading={isLoadingUserData}
          />
          <Input
            name="dsGovbrEmail"
            label="E-mail do gov.br"
            placeholder="nome@email.com"
            type="text"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dsCellphone"
            label="Telefone"
            placeholder="+55 (82) 99999-9999"
            type="tel"
            isDisabled
            isLoading={isLoadingUserData}
          />
        </SimpleGrid>
      </Stack>
      <Stack gap={4}>
        <S.HeaderText>Endereço</S.HeaderText>
        <SimpleGrid columns={3} spacing={4}>
          <BasicSelect
            name="dsCountry"
            label="País"
            placeholder="Selecione um país"
            options={countries}
            isDisabled
            isLoading={isLoadingUserData || isLoadingCountries}
          />
          <BasicSelect
            name="dsUfProvince"
            label="Estado"
            placeholder="Selecione um estado"
            options={states}
            isDisabled
            isLoading={isLoadingUserData || isLoadingStates}
          />
          <BasicSelect
            name="dsCity"
            label="Cidade"
            placeholder="Selecione uma cidade"
            options={cities}
            isDisabled
            isLoading={isLoadingUserData || isLoadingCities}
          />
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}

export default UserInfoForm
