import React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabItem, ITabInfo } from '@/components/Tabs/types'

const Checklists = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const initialPath = location.pathname.split('/').pop()

  const tabs: Array<ITabItem> = [
    {
      id: 'formulario',
      label: 'Formulário',
    },
    // {
    //   id: 'diligencias',
    //   label: 'Diligências',
    // },
    {
      id: 'questoes',
      label: 'Questões',
    },
    // {
    //   id: 'falhas',
    //   label: 'Falhas',
    // },
  ]

  const handleTabClick = (id: string) => {
    navigate(`/formularios/${id}`)
  }

  return (
    <Flex direction="column" flexGrow={1} gap="6">
      <Tabs
        items={tabs}
        initialTab={initialPath}
        onTabClick={({ id }: ITabInfo) => handleTabClick(id)}
      />
      <Outlet />
    </Flex>
  )
}

export default Checklists
