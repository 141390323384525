import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { getAuthGovBrGetUrl } from './services'
import { GetAuthGovBrGetUrlResponse } from './types'

export const useQueryAuthGovBrGetUrl = (
  options?: UseQueryOptions<GetAuthGovBrGetUrlResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getAuthGovBrGetUrl()

  return useQuery<GetAuthGovBrGetUrlResponse, AxiosError<Error>>(
    useQueryAuthGovBrGetUrl.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryAuthGovBrGetUrl.getFullQueryKey = (): QueryKey => [
  'auth_gov_br_get_url',
]

useQueryAuthGovBrGetUrl.queryKey = ['auth_gov_br_get_url']
