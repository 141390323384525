import React from 'react'
import { FaUpload } from 'react-icons/fa'

import { Flex, Text, Icon, Input } from '@chakra-ui/react'

import { useControllerFileUpload } from './controller'
import ViewFile from './parts/ViewFile'
import { IFileUploadProps } from './types'

const FileUpload: React.FC<IFileUploadProps> = ({
  id,
  name,
  helperText,
  acceptedFileTypes = [],
  maxFileSize = 1,
  isMultiple = false,
}) => {
  const {
    values,
    fileInputRef,
    openFileDialog,
    handleAddFiles,
    handleRemoveFile,
    handleDragOver,
    handleDrop,
  } = useControllerFileUpload({
    name,
    isMultiple,
    maxFileSize,
  })

  return (
    <Flex direction="column" align="left" gap={2}>
      {!values[name] || isMultiple ? (
        <Flex
          align="center"
          gap={4}
          paddingY={3}
          paddingX={4}
          border="2px dashed"
          borderRadius="lg"
          borderColor="brand.primary.dark_2"
          cursor="pointer"
          onClick={openFileDialog}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Icon as={FaUpload} color="brand.primary.dark_2" />
          <Text
            color="brand.primary.dark_2"
            fontSize="sm"
            fontWeight="medium"
            fontStyle="italic"
          >
            Clique aqui para selecionar
            {isMultiple ? ' os arquivos' : ' o arquivo'}
          </Text>
        </Flex>
      ) : (
        <ViewFile
          file={values[name] as File}
          onRemove={() => handleRemoveFile(values[name] as File)}
        />
      )}
      <Input
        id={id}
        name={name}
        ref={fileInputRef}
        type="file"
        accept={acceptedFileTypes.join(',')}
        multiple={isMultiple}
        onChange={handleAddFiles}
        display="none"
      />
      <Flex justify="space-between" align="center">
        <Text fontSize="sm" color="brand.neutral.dark_1">
          {helperText}
        </Text>
        <Text fontSize="sm" color="brand.neutral.dark_1">
          Até {maxFileSize}MB
        </Text>
      </Flex>
      {isMultiple && values[name]?.length > 0 && (
        <Flex direction="column" gap={2}>
          {(values[name] as Array<File>)?.map(file => (
            <ViewFile
              key={file.name}
              file={file}
              onRemove={() => handleRemoveFile(file)}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default FileUpload
