import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'
import { FormQuestion } from '@/types/form.types'

import {
  activateFormQuestion,
  deleteFormQuestion,
  patchFormQuestion,
  postFormQuestion,
} from './services'
import {
  TActivateFormQuestionParams,
  TDeleteFormQuestionParams,
  TPatchFormQuestionParams,
  TPostFormQuestionParams,
} from './types'

export const useMutationPostFormQuestion = (
  options?: UseMutationOptions<
    FormQuestion,
    AxiosError<Error>,
    TPostFormQuestionParams
  >
) => {
  return useMutation<FormQuestion, AxiosError<Error>, TPostFormQuestionParams>(
    useMutationPostFormQuestion.mutationKey,
    postFormQuestion,
    options
  )
}

useMutationPostFormQuestion.mutationKey = ['postFormQuestion']

export const useMutationPatchFormQuestion = (
  coFormQuestion: number,
  options?: UseMutationOptions<
    FormQuestion,
    AxiosError<Error>,
    TPatchFormQuestionParams
  >
) => {
  return useMutation<FormQuestion, AxiosError<Error>, TPatchFormQuestionParams>(
    useMutationPatchFormQuestion.mutationKey,
    params => patchFormQuestion(coFormQuestion, params),
    options
  )
}

useMutationPatchFormQuestion.mutationKey = ['patchFormQuestion']

export const useMutationDeleteFormQuestion = (
  options?: UseMutationOptions<
    void,
    AxiosError<Error>,
    TDeleteFormQuestionParams
  >
) => {
  return useMutation<void, AxiosError<Error>, TDeleteFormQuestionParams>(
    useMutationDeleteFormQuestion.mutationKey,
    deleteFormQuestion,
    options
  )
}

useMutationDeleteFormQuestion.mutationKey = ['deleteFormQuestion']

export const useMutationActivateFormQuestion = (
  options?: UseMutationOptions<
    void,
    AxiosError<Error>,
    TActivateFormQuestionParams
  >
) => {
  return useMutation<void, AxiosError<Error>, TActivateFormQuestionParams>(
    useMutationActivateFormQuestion.mutationKey,
    activateFormQuestion,
    options
  )
}

useMutationActivateFormQuestion.mutationKey = ['activateFormQuestion']
