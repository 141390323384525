import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import {
  deleteForm,
  postForm,
  updateForm,
  approveForm,
  addQuestions,
  removeQuestions,
  activateForm,
} from './services'
import {
  TDeleteFormResponse,
  TDeleteFormParams,
  TPostFormParams,
  TPostFormResponse,
  TApproveFormParams,
  TApproveFormResponse,
  TPatchFormParams,
  TPatchFormResponse,
  TPostManageQuestionsParams,
  TPostManageQuestionsResponse,
  TActivateFormParams,
  TActivateFormResponse,
} from './types'

export const useMutationPostForm = (
  options?: UseMutationOptions<
    TPostFormResponse,
    AxiosError<Error>,
    TPostFormParams
  >
) => {
  return useMutation(postForm, options)
}

export const useMutationDeleteForm = (
  options?: UseMutationOptions<
    TDeleteFormResponse,
    AxiosError<Error>,
    TDeleteFormParams
  >
) => {
  return useMutation(deleteForm, options)
}

export const useMutationApproveForm = (
  options?: UseMutationOptions<
    TApproveFormResponse,
    AxiosError<Error>,
    TApproveFormParams
  >
) => {
  return useMutation(approveForm, options)
}

export const useMutationPatchForm = (
  options?: UseMutationOptions<
    TPatchFormResponse,
    AxiosError<Error>,
    TPatchFormParams
  >
) => {
  return useMutation(updateForm, options)
}

export const useMutationAddQuestions = (
  options?: UseMutationOptions<
    TPostManageQuestionsResponse,
    AxiosError<Error>,
    TPostManageQuestionsParams
  >
) => {
  return useMutation(addQuestions, options)
}

export const useMutationRemoveQuestions = (
  options?: UseMutationOptions<
    TPostManageQuestionsResponse,
    AxiosError<Error>,
    TPostManageQuestionsParams
  >
) => {
  return useMutation(removeQuestions, options)
}

export const useMutationActivateForm = (
  options?: UseMutationOptions<
    TActivateFormResponse,
    AxiosError<Error>,
    TActivateFormParams
  >
) => {
  return useMutation(activateForm, options)
}
