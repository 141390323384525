import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusChecklistTagProps, IStatusChecklistTagStyles } from './types'

const styles: IStatusChecklistTagStyles = {
  active: {
    name: 'Vinculado',
    backgroundColor: 'brand.neutral.base',
  },
  finished: {
    name: 'Finalizado',
    backgroundColor: 'brand.success.light',
  },
  on_hold: {
    name: 'Aguardando Vínculo',
    backgroundColor: 'brand.highlight.base',
  },
  PENDENTE: {
    name: 'Aguardando validação',
    backgroundColor: 'brand.highlight.base',
  },
  AUTORIZADO: {
    name: 'Aprovado',
    backgroundColor: 'brand.success.light',
  },
  AGUARDANDO_VINCULO: {
    name: 'Aguardando Vínculo',
    backgroundColor: 'brand.highlight.base',
  },
  default: {
    name: 'default',
    backgroundColor: 'gray.500',
  },
}

const StatusTag: React.FC<IStatusChecklistTagProps> = ({ tag, ...rest }) => {
  const { name, backgroundColor, color } = styles[tag] || styles.default

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
