import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'

import { Flex, Text, Icon } from '@chakra-ui/react'

import { IViewFileProps } from './types'

const ViewFile: React.FC<IViewFileProps> = ({ file, onRemove }) => {
  const convertBytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

    if (bytes === 0) return '0 Byte'

    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      padding={4}
      gap={4}
      rounded="lg"
      backgroundColor="gray.100"
    >
      <Text
        fontSize="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        color="brand.primary.dark_2"
      >
        {file.name}
      </Text>

      <Flex alignItems="center" gap={2}>
        <Text fontSize="sm" whiteSpace="nowrap" color="gray.500">
          {convertBytesToSize(file.size)}
        </Text>
        <Icon
          as={FaTrashAlt}
          color="brand.primary.dark_2"
          cursor="pointer"
          onClick={onRemove}
        />
      </Flex>
    </Flex>
  )
}

export default ViewFile
