import React, { useRef, useState, useEffect } from 'react'
import { FaChevronLeft } from 'react-icons/fa6'
import { IoIosCloseCircle, IoIosSave } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'

import { Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Stack,
  Switch,
  Text,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'

import { Input, BasicSelect } from '@/components/Forms'
import Tabs from '@/components/Tabs'
import StatusTag from '@/components/Tag/StatusQuestions'
import { AnswerEnum } from '@/types/form.types'

import {
  useActivateQuestionController,
  useQuestionDetailsController,
  useUpdateQuestionController,
} from './controller'
import DeleteQuestionModal from './parts/DeleteQuestionModal'
import AlternativeQuestionsTab from './parts/Tabs/AlternativeQuestionsTab'
import AnswersTab from './parts/Tabs/AnswersTab'
import FormsTab from './parts/Tabs/FormsTab'
import { ITabItemQuestionsDetails, TQuestionDetailsFormValues } from './types'

const QuestionDetails: React.FC = () => {
  const { coQuestion } = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('forms')
  const formikRef = useRef<FormikProps<TQuestionDetailsFormValues>>(null)

  const { questionData, isLoading, isError, initialFormValues, selectOptions } =
    useQuestionDetailsController(coQuestion as string)

  const { handleActivation, isActivating } = useActivateQuestionController(
    coQuestion as string
  )
  const { handleSubmit, isUpdating } = useUpdateQuestionController(
    coQuestion as string
  )

  const {
    isOpen: isDeleteQuestionOpen,
    onOpen: onDeleteQuestionOpen,
    onClose: onDeleteQuestionClose,
  } = useDisclosure()

  const validationSchema = yup.object({
    title: yup.string().required('O título é obrigatório'),
    formatVolume: yup.string().required('O formato é obrigatório'),
    versionVolume: yup.string().required('A versão é obrigatória'),
    exemplaryVolume: yup.string().required('O exemplar é obrigatório'),
    diligence: yup.string().required('A diligência é obrigatória'),
    answerModel: yup.string().required('O modelo de resposta é obrigatório'),
  })

  const handleSwitchChange = () => handleActivation(!questionData?.stActive)

  const isFormDisabled = (questionData?.forms?.length ?? 0) > 0

  const onSubmit = (values: TQuestionDetailsFormValues) => {
    if (!isFormDisabled) {
      handleSubmit(values, questionData?.stActive ?? false)
    }
  }

  const canHaveAlternativeQuestions = (answerModel: string): boolean => {
    return ![
      AnswerEnum.numeric.toString(),
      AnswerEnum.text.toString(),
      '',
    ].includes(answerModel)
  }

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" color="brand.primary.dark_1" />
      </Flex>
    )
  }

  if (isError) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Text color="brand.error.base">
          Erro ao carregar detalhes da questão
        </Text>
      </Flex>
    )
  }

  return (
    <>
      <DeleteQuestionModal
        isOpen={isDeleteQuestionOpen}
        onClose={onDeleteQuestionClose}
        coFormQuestion={coQuestion as string}
      />
      <Stack spacing={4}>
        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, handleChange }) => {
            const currentAnswerModel =
              values.answerModel ||
              questionData?.answerType?.tpAnswer?.toString() ||
              ''

            const hasAlternativeQuestions =
              canHaveAlternativeQuestions(currentAnswerModel)

            const questionsTabs: Array<ITabItemQuestionsDetails> = [
              ...(currentAnswerModel !== ''
                ? [
                    {
                      id: 'answers',
                      label: 'Respostas',
                      render: () => <AnswersTab />,
                    },
                  ]
                : []),
              ...(hasAlternativeQuestions
                ? [
                    {
                      id: 'alternative',
                      label: 'QPs Alternativas',
                      render: () => <AlternativeQuestionsTab />,
                    },
                  ]
                : []),
              { id: 'forms', label: 'Formulários', render: () => <FormsTab /> },
            ]

            useEffect(() => {
              if (
                currentAnswerModel &&
                questionsTabs.some(tab => tab.id === 'answers')
              ) {
                setActiveTab('answers')
              } else if (hasAlternativeQuestions) {
                setActiveTab('alternative')
              } else {
                setActiveTab('forms')
              }
            }, [currentAnswerModel, hasAlternativeQuestions])

            return (
              <Form>
                <Stack spacing={4}>
                  <Stack p={4} boxShadow="md" borderRadius="md">
                    <Flex justify="space-between" align="center" mb={4}>
                      <Box>
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          color="brand.primary.dark_1"
                          mb={2}
                        >
                          {questionData?.dsQuestion}
                        </Text>
                        <StatusTag
                          tag={questionData?.stActive ? 'active' : 'inactive'}
                        />
                      </Box>
                      <Flex align="center">
                        <FormLabel fontSize="sm" mb={1}>
                          {questionData?.stActive
                            ? 'Desativar questão'
                            : 'Ativar questão'}
                        </FormLabel>
                        <Switch
                          size="md"
                          isChecked={questionData?.stActive}
                          onChange={handleSwitchChange}
                          isDisabled={isActivating || isUpdating}
                          sx={{
                            '& .chakra-switch__track[data-checked]': {
                              backgroundColor: 'brand.primary.dark_1',
                            },
                          }}
                        />
                        {isActivating && (
                          <Spinner
                            size="sm"
                            color="brand.primary.dark_1"
                            ml={2}
                          />
                        )}
                      </Flex>
                    </Flex>

                    <Stack>
                      <Input
                        label="Título da questão"
                        name="title"
                        placeholder="---"
                        isDisabled={isFormDisabled}
                      />
                      <Flex
                        gap="4"
                        flexDirection={{ base: 'column', md: 'row', xl: 'row' }}
                      >
                        <BasicSelect
                          options={selectOptions.formats}
                          label="Formato do volume"
                          name="formatVolume"
                          placeholder="---"
                          isDisabled={isFormDisabled}
                        />
                        <BasicSelect
                          options={selectOptions.volumeVersion}
                          label="Versão do volume"
                          name="versionVolume"
                          placeholder="---"
                          isDisabled={isFormDisabled}
                        />
                        <BasicSelect
                          options={selectOptions.exemplaries}
                          label="Exemplar do volume"
                          name="exemplaryVolume"
                          placeholder="---"
                          isDisabled={isFormDisabled}
                        />
                      </Flex>
                      <Flex
                        gap="4"
                        flexDirection={{ base: 'column', md: 'row', xl: 'row' }}
                      >
                        <BasicSelect
                          options={selectOptions.failureTypes}
                          label="Diligência"
                          name="diligence"
                          placeholder="---"
                          isDisabled={isFormDisabled}
                        />
                        <BasicSelect
                          options={selectOptions.answerModels}
                          label="Tipo de resposta"
                          name="answerModel"
                          placeholder="---"
                          isDisabled={isFormDisabled}
                          onChange={handleChange}
                        />
                        <BasicSelect
                          options={[
                            { value: '1', label: 'Sim' },
                            { value: '0', label: 'Não' },
                          ]}
                          label="Essa pergunta possui questões alternativas?"
                          name="hasAlternativeQuestions"
                          placeholder="---"
                          isDisabled
                        />
                      </Flex>
                      <Flex
                        gap="4"
                        flexDirection={{ base: 'column', md: 'row', xl: 'row' }}
                      >
                        <Input
                          label="Descrição"
                          name="description"
                          placeholder="---"
                          isDisabled={isFormDisabled}
                        />
                      </Flex>
                    </Stack>
                  </Stack>

                  <Stack p={4} boxShadow="md" borderRadius="md">
                    <Tabs variant="rounded" items={questionsTabs} />

                    <Flex justify="space-between" direction={['column', 'row']}>
                      <Button
                        size="sm"
                        key="go-back"
                        variant="ghost"
                        leftIcon={<FaChevronLeft />}
                        color="brand.primary.dark_1"
                        onClick={() => navigate('/formularios')}
                      >
                        Voltar
                      </Button>
                      <Flex
                        alignItems="center"
                        direction={['column', 'row']}
                        gap={6}
                        mt={{ base: 6, md: 0, xl: 0 }}
                      >
                        <Button
                          leftIcon={<IoIosCloseCircle size="16px" />}
                          size="sm"
                          backgroundColor="brand.error.base"
                          _hover={{ bg: 'brand.error.dark' }}
                          onClick={onDeleteQuestionOpen}
                          isDisabled={
                            isFormDisabled || isUpdating || isActivating
                          }
                        >
                          Excluir questão
                        </Button>
                        <Button
                          type="submit"
                          size="sm"
                          leftIcon={<IoIosSave size="16px" />}
                          isLoading={isUpdating}
                          isDisabled={isFormDisabled || isActivating}
                        >
                          Salvar alterações
                        </Button>
                      </Flex>
                    </Flex>
                  </Stack>
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </Stack>
    </>
  )
}

export default QuestionDetails
