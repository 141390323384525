import React from 'react'
import { FaBan } from 'react-icons/fa'

import { Flex, Icon, Stack, Text } from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

const filters: Array<IFilter> = [
  {
    name: 'object',
    label: 'Objeto',
    type: 'text',
  },
  {
    name: 'permission',
    label: 'Permissão',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { label: 'Ativo', value: 'true' },
      { label: 'Finalizado', value: 'false' },
    ],
  },
]

const Teams: React.FC = () => {
  const {
    userTeamsTableData,
    isLoadingUserTeams,
    pagination,
    handleOnChangePageInfoUserTeams: handleOnChangePageInfo,
    handleFilters,
  } = useProfileContext()

  const header: Array<IHeader> = [
    {
      name: 'EQUIPE',
      key: 'team',
    },
    {
      name: 'OBJETO',
      key: 'object',
    },
    {
      name: 'FASE',
      key: 'phase',
    },
    {
      name: 'PERMISSÃO',
      key: 'permission',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '10%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  return userTeamsTableData.length === 0 ? (
    <Flex direction="column" gap={4}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
      >
        <DataFilter
          onlySearch
          filters={filters}
          onChangeFilter={handleFilters}
        />
      </Flex>
      <DataTable
        headers={header}
        data={userTeamsTableData}
        isLoading={isLoadingUserTeams}
      />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Flex>
  ) : (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      marginTop={8}
      gap={8}
    >
      <Icon
        as={FaBan}
        boxSize="128px"
        color="brand.primary.dark_1"
        rotate="30deg"
      />
      <Stack maxWidth="400px" align="center" spacing="4">
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Você ainda não tem equipes vinculadas!
        </Text>
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight="400"
          lineHeight="150%"
        >
          Estamos no processo de distribuição das equipes aos seus respectivos
          objetos a serem analisados. Assim que você for alocado a uma equipe e
          seus itens estiverem prontos, você receberá uma notificação por
          e-mail.
          <br />
          <br />
          Agradecemos pela sua paciência!
        </Text>
      </Stack>
    </Flex>
  )
}

export default Teams
