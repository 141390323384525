import http from '@/api/http'
import { FormQuestion } from '@/types/form.types'

import {
  TGetFormQuestionsParams,
  TGetFormQuestionsResponse,
  TGetFormQuestionByIdParams,
  TGetFormQuestionByIdResponse,
  TGetFormQuestionFieldValuesResponse,
  TPostFormQuestionParams,
  TDeleteFormQuestionParams,
  TPatchFormQuestionParams,
  TActivateFormQuestionParams,
} from './types'

export const getFormQuestions = async (filters: TGetFormQuestionsParams) => {
  const response = await http.get<TGetFormQuestionsResponse>(
    '/forms/question/',
    {
      params: filters,
    }
  )

  return response.data
}

export const getFormQuestionById = async (
  params: TGetFormQuestionByIdParams
) => {
  const response = await http.get<TGetFormQuestionByIdResponse>(
    `/forms/question/${params.coFormQuestion}/`
  )
  return response.data
}

export const getFormQuestionFieldValues = async () => {
  const response = await http.get<TGetFormQuestionFieldValuesResponse>(
    '/forms/question/field_values/'
  )
  return response.data
}

export const postFormQuestion = async (params: TPostFormQuestionParams) => {
  const response = await http.post<FormQuestion>('/forms/question/', params)
  return response.data
}

export const patchFormQuestion = async (
  coFormQuestion: number,
  params: TPatchFormQuestionParams
) => {
  const response = await http.patch<FormQuestion>(
    `/forms/question/${coFormQuestion}/`,
    params
  )
  return response.data
}

export const deleteFormQuestion = async (params: TDeleteFormQuestionParams) => {
  const response = await http.delete<void>(
    `/forms/question/${params.coFormQuestion}/`
  )
  return response.data
}

export const activateFormQuestion = async (
  params: TActivateFormQuestionParams
) => {
  const response = await http.post<void>('/forms/question/activation/', params)
  return response.data
}
