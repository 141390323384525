import React, { useEffect, useState } from 'react'
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa'
import { FaDownload } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormLabel,
  IconButton,
  Input,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { useQueryGetVolume } from '@/api/volumes/queries'
import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import { getErrorDetails } from '@/utils/error'

import FormTab from './parts/Tabs/FormTab'
import { InputDisabledProps } from './types'

const InputDisabled = ({ label, placeholder }: InputDisabledProps) => (
  <Box w="50%">
    <FormLabel fontSize="sm" opacity={0.5}>
      {label}
    </FormLabel>
    <Input
      placeholder={placeholder}
      disabled
      bg="brand.neutral.light_2"
      borderColor="brand.neutral.light_2"
      _placeholder={{ fontStyle: 'italic' }}
    />
  </Box>
)

const FormAnswersVolume = () => {
  const toast = useToast()

  const location = useLocation()
  const volumeId = location.pathname.split('/').pop()

  const tabs: ITabItem[] = [
    {
      id: 'form',
      label: 'Formulário',
      render: () => <FormTab />,
    },
  ]

  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  const { data: volume, isLoading } = useQueryGetVolume(
    { idVolume: String(volumeId) },
    {
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    let timer: string | number | NodeJS.Timer | undefined
    if (isRunning) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [isRunning])

  const handleStopwatch = () => {
    setIsRunning(prevState => !prevState)
  }

  const handleReset = () => {
    setIsRunning(false)
    setTime(0)
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`
  }

  const RenderInfoVolume = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
          boxShadow="md"
          borderRadius="md"
          py="5"
          px="5"
        >
          <VStack spacing={4}>
            <Text>Buscando dados do volume...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    return (
      <Flex
        direction="column"
        gap="4"
        py="5"
        px="5"
        boxShadow="md"
        borderRadius="md"
      >
        <Flex justify="space-between">
          <Text
            color="brand.primary.dark_1"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Volume: {volume?.dsVolumeCode || 'Não informado'}
          </Text>
          <Tag group="statusObjects" value="active" />
        </Flex>
        <Box w="full" h="full" gap="4">
          <Flex direction="row" gap="4">
            <InputDisabled
              label="Edital"
              placeholder={String(volume?.nuEdition) || 'Não informado'}
            />
            <InputDisabled label="Editora" placeholder={'Não informado'} />
            <InputDisabled
              label="Coleção"
              placeholder={volume?.coDeed?.dsCollectionTitle || 'Não informado'}
            />
          </Flex>

          <Flex direction="row" gap="4" mt="5">
            <InputDisabled
              label="Formato do volume"
              placeholder={String(volume?.coFormat) || 'Não informado'}
            />
            <InputDisabled
              label="Versão do volume"
              placeholder={'Não informado'}
            />
            <InputDisabled
              label="Exemplar do volume"
              placeholder={String(volume?.coExemplary) || 'Não informado'}
            />
          </Flex>

          <Flex align="center" justify="space-between" mt="5">
            <Button leftIcon={<FaDownload />} variant="solid">
              Baixe volume do PNLD digital
            </Button>
            <Flex align="center" minW={100}>
              <IconButton
                variant="ghost"
                aria-label="Play"
                icon={
                  !isRunning ? (
                    <FaPlayCircle fontSize={36} />
                  ) : (
                    <FaPauseCircle fontSize={36} />
                  )
                }
                mr="2"
                onClick={handleStopwatch}
              />
              <Text color="brand.neutral.dark_1" fontSize="md">
                {formatTime(time)}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    )
  }

  const RenderFormAnswers = () => {
    const [activeTabId, setActiveTabId] = useState('form')

    return (
      <Flex
        direction="column"
        gap="4"
        py="5"
        px="5"
        boxShadow="md"
        borderRadius="md"
      >
        <Tabs
          items={tabs}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="6">
      <RenderInfoVolume />
      <RenderFormAnswers />
    </Flex>
  )
}

export default FormAnswersVolume
