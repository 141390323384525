import React from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

import {
  Stack,
  Box,
  Text,
  Flex,
  AccordionPanel,
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  Tag,
} from '@chakra-ui/react'

import { IAccordionProps } from './types'

const Accordion: React.FC<IAccordionProps> = ({ items, ...rest }) => {
  return (
    <ChakraAccordion as={Stack} allowMultiple gap={4} {...rest}>
      {items.map((accordion, index) => (
        <AccordionItem
          key={`accordion-item-${index}`}
          bg="brand.neutral.light_2"
          border="unset"
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton px={3} py={0.5}>
                <Flex flex={1} align="center" justify="space-between">
                  <Box textAlign="left">
                    <Text fontWeight="bold" color="brand.primary.dark_2">
                      {accordion.title}
                    </Text>
                    <Text fontSize="xs" color="brand.neutral.dark_2">
                      {accordion.description}
                    </Text>
                  </Box>
                  {accordion.tag && <Tag mx={4}>{accordion.tag}</Tag>}
                </Flex>
                <Box color="brand.primary.dark_1">
                  {isExpanded ? <FaCaretUp /> : <FaCaretDown />}
                </Box>
              </AccordionButton>
              <AccordionPanel pb={4} bg="white">
                {accordion.children}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </ChakraAccordion>
  )
}

export default Accordion
