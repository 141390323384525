import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Text, Skeleton } from '@chakra-ui/react'

import CardsContainer from '@/components/Cards/Container/CardsContainer'
import { ICardComponentProps } from '@/components/Cards/Container/types'
import { useQuestionManagement } from '@/contexts/QuestionManagementContext'

import { IQuestionsListProps } from '../types'

export const QuestionsList = ({ isLoading }: IQuestionsListProps) => {
  const { questions, setQuestionsToRemove, removeQuestionById } =
    useQuestionManagement()
  const navigate = useNavigate()

  const questionsData: Array<ICardComponentProps> = useMemo(() => {
    return (questions || []).map(question => ({
      id: String(question?.id),
      title: question?.name,
      description: question?.type,
      status: {
        tag: question.dsStatus,
      } as ICardComponentProps['status'],
    }))
  }, [questions])

  const handleDeleteQuestion = (id: string) => {
    setQuestionsToRemove(prevState => [...prevState, Number(id)])
    removeQuestionById(Number(id))
  }
  const handleEditQuestion = (id: string) => {
    navigate(`/detalhes-da-questao/${id}`)
  }

  return (
    <>
      <Skeleton isLoaded={!isLoading} minH="200px" borderRadius="xl">
        {questionsData.length ? (
          <CardsContainer
            initialCards={questionsData}
            iconUrl={true}
            idText={true}
            statusTag={true}
            onEdit={id => handleEditQuestion(id)}
            onCopy={() => {}}
            onDelete={id => handleDeleteQuestion(id)}
          />
        ) : (
          <Text fontSize="lg" color="gray.500" textAlign="center">
            Nenhuma questão encontrada
          </Text>
        )}
      </Skeleton>
    </>
  )
}
