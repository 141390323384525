import React, { useMemo, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'

import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  IconButton,
  VStack,
  Text,
  Button,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusAnalystTeam'
import { TeamMember } from '@/types/team.types'

import { ITeamAnalystStatusMapping } from '../../types'
import AddAnalystModal from '../AddAnalystModal/AddAnalystsModal'

interface TeamAnalystTableProps {
  coTeam: number
  analysts: Array<TeamMember>
  isAnalystsLoading: boolean
  refetchTeam: () => void
}

const TeamAnalystTable: React.FC<TeamAnalystTableProps> = ({
  coTeam,
  analysts,
  isAnalystsLoading,
  refetchTeam,
}) => {
  const analystsIds = analysts.map(member => member.coUser.coUser)
  const [pageIndex, setPageIndex] = useState(0)
  const {
    isOpen: isAddAnalystOpen,
    onOpen: onAddAnalystOpen,
    onClose: onAddAnalystClose,
  } = useDisclosure()

  const pageSizeOptions = [10, 20, 30, 40, 50]
  const itemsPerPage = 5
  const totalAnalysts = analysts.length
  const totalPages = Math.ceil(totalAnalysts / itemsPerPage)

  const handleOnChangePageInfo = ({ pageIndex }: IPageInfoProps) => {
    setPageIndex(pageIndex)
  }
  const handleAddAnalyst = () => {
    onAddAnalystOpen()
  }

  const analystsFilterExample: Array<IFilter> = [
    {
      name: 'filter3',
      label: 'Status',
      type: 'select',
      options: [
        { label: 'Ativo', value: 'ativo' },
        { label: 'Inativo', value: 'Inativo' },
      ],
    },
  ]

  const mainAnalystTableHeaders: Array<IHeader> = [
    {
      name: 'Analista',
      key: 'user',
      type: 'element',
      width: '40%',
    },
    {
      name: 'Data Final de Vínculo',
      key: 'date',
      align: 'center',
      width: '20%',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '20%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  const analystsDataTable = useMemo(() => {
    const analystData = analysts ? analysts : []
    const mappedAnalysts = analystData.map((analyst, key) => {
      const analystInfo = (name: string, cpf: string, email: string) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {name}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {cpf} | {email}
            </Text>
          </Box>
        </VStack>
      )

      const editButton = (id: string) => {
        const handleEdit = () => {
          // TODO: navigate(`/configuracoes/equipes/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            height="unset"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      const statusTageValue =
        ITeamAnalystStatusMapping[analyst.stTeamMemberStatus]

      return {
        user: analystInfo(
          analyst.coUser.noUserName,
          analyst.coUser.dsCpf,
          analyst.coUser.dsInstitutionalEmail
        ),
        date: analyst.dtCreatedAt,
        status: StatusTag({ tag: statusTageValue }),
        edit: editButton(analyst.coUser.coUser as string),
        status_code: analyst.stTeamMemberStatus,
      }
    })
    return mappedAnalysts
  }, [analysts])

  return (
    <Stack gap={4}>
      <DataFilter
        onlySearch
        filters={analystsFilterExample}
        onChangeFilter={() => {}}
      />
      <DataTable
        key={'teamAnalystTable'}
        rowId="teamAnalystTableId"
        headers={mainAnalystTableHeaders}
        data={analystsDataTable}
        isLoading={isAnalystsLoading}
      />
      <Button
        leftIcon={<FaPlusCircle size="14px" color="white" />}
        size="sm"
        backgroundColor="brand.primary.dark_1"
        color="white"
        _hover={{ bg: 'brand.primary.dark_2' }}
        width="100%"
        onClick={handleAddAnalyst}
      >
        Adicionar Analista
      </Button>
      <Pagination
        pageIndex={pageIndex}
        optionsItemsPerPage={pageSizeOptions}
        itemsPerPage={itemsPerPage}
        pageCount={totalPages}
        itemsCount={totalAnalysts}
        onChangePageInfo={handleOnChangePageInfo}
      />
      <AddAnalystModal
        coTeam={coTeam}
        analystsId={analystsIds}
        isOpen={isAddAnalystOpen}
        onClose={onAddAnalystClose}
        refetchTeam={refetchTeam}
      />
    </Stack>
  )
}
export default TeamAnalystTable
