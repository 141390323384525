import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IAccessLevelTagProps, IAccessLevelTagStyles } from './types'

const styles: IAccessLevelTagStyles = {
  admin: {
    name: 'administração',
    backgroundColor: 'gray.900',
  },
  coordination: {
    name: 'coordenação',
    backgroundColor: 'gray.800',
  },
  supervision: {
    name: 'supervisão',
    backgroundColor: 'gray.700',
  },
  execution: {
    name: 'execução',
    backgroundColor: 'gray.600',
  },
  default: {
    name: 'default',
    backgroundColor: 'gray.500',
  },
}

const StatusTag: React.FC<IAccessLevelTagProps> = ({ tag, ...rest }) => {
  const { name, backgroundColor, color } = styles[tag] || styles.default

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
