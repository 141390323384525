import React, { useState, useMemo, useEffect } from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Input,
  Box,
  Skeleton,
  Flex,
  Highlight,
  Tfoot,
} from '@chakra-ui/react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  RowData,
  ColumnDef,
  CellContext,
  ColumnMeta,
} from '@tanstack/react-table'

import S from './styles'
import { IDataTableProps, IHeader, TRow } from './types'

const DataTable: React.FC<IDataTableProps<RowData>> = ({
  data,
  headers,
  isLoading,
  selectableRow,
  onRowSelectionChange,
  highlightedText,
  highlightedColumns,
  rowId = 'id',
  onRowClick,
}) => {
  const [tableData, setTableData] = useState(data)
  const [columns, setColumns] = useState<Array<ColumnDef<RowData>>>([])
  const [rowSelection, setRowSelection] = useState({})
  const [allRows, setAllRows] = useState<Array<RowData>>([])
  const [isSubHeader, setIsSubHeader] = useState(false)

  const selectableColumn = useMemo<ColumnDef<RowData>>(() => {
    return {
      id: 'select',
      header: ({ table }) => (
        <S.Checkbox
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Box>
          <S.Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            isIndeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </Box>
      ),
    }
  }, [])

  const tableCell = ({
    getValue,
    row,
    column,
    table,
  }: CellContext<unknown, unknown>) => {
    const initialValue = getValue()
    const columnMeta = column.columnDef.meta
    const cellType: string = columnMeta?.type ?? 'default'
    const [value, setValue] = useState(initialValue)

    const onBlur = () => {
      table.options.meta?.updateData(row.index, column.id, value)
    }

    const isHighlightedColumn = highlightedColumns?.includes(column.id)

    const cells = {
      editable: (
        <Input
          value={value as string}
          onChange={e => setValue(e.target.value)}
          onBlur={onBlur}
          border="none"
        />
      ),
      element: value as JSX.Element,
      default: (
        <Highlight
          query={isHighlightedColumn ? (highlightedText as string) : ''}
          styles={{ bg: 'orange.100', fontWeight: '600' }}
        >
          {String(value || '')}
        </Highlight>
      ),
    }

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    return (
      (cells as Record<string, JSX.Element | undefined>)[cellType] ||
      cells.default
    )
  }

  const table = useReactTable({
    data: tableData,
    columns,
    enableRowSelection: true,
    getRowId: row => String((row as TRow)[rowId]),
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setTableData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...(old[rowIndex] as Record<string, unknown>),
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  useEffect(() => {
    formatColumns(headers)
  }, [highlightedText])

  useEffect(() => {
    setAllRows(prevRows => {
      const newRows = [...prevRows]
      data.forEach(row => {
        const existingRow = newRows.find(
          r => (r as TRow)[rowId] === (row as TRow)[rowId]
        )
        if (!existingRow) {
          newRows.push(row)
        } else {
          Object.assign(existingRow, row)
        }
      })
      return newRows
    })
    setTableData(data)
  }, [data])

  useEffect(() => {
    onRowSelectionChange?.(getSelectedRows())
  }, [rowSelection])

  const formatColumns = (columns: Array<IHeader>) => {
    const haveSubHeader = columns.some(column => column.subHeader)
    setIsSubHeader(haveSubHeader)
    const formattedColumns: Array<ColumnDef<RowData>> = columns.map(column => {
      if (haveSubHeader) {
        return {
          header: column.name,
          accessorKey: column.subHeader ? `sub${column.key}` : column.key,
          cell: props => tableCell(props),
          footer: column.footer,
          meta: {
            type: column.type,
            width: column.width,
            align: column.align,
          } as ColumnMeta<object, string | number>,
          columns: [
            ...(column.subHeader
              ? column.subHeader.map(subHeader => ({
                  header: subHeader.name,
                  accessorKey: subHeader.key,
                  footer: subHeader.footer,
                  meta: {
                    type: subHeader.type,
                    width: subHeader.width,
                    align: subHeader.align,
                  } as ColumnMeta<object, string | number>,
                }))
              : [
                  {
                    header: '',
                    accessorKey: column.key,
                    meta: {
                      type: column.type,
                      width: column.width,
                      align: column.align,
                    } as ColumnMeta<object, string | number>,
                  },
                ]),
          ],
        }
      }

      return {
        header: column.name,
        accessorKey: column.key,
        cell: props => tableCell(props),
        footer: column.footer,
        meta: {
          type: column.type,
          width: column.width,
          align: column.align,
        } as ColumnMeta<object, string | number>,
      }
    })

    if (selectableRow) {
      formattedColumns.unshift(selectableColumn)
    }

    setColumns(formattedColumns)
  }

  const getSelectedRows = () => {
    const selectedRowsId = Object.keys(rowSelection)
    const selectedRows = allRows.filter(row => {
      const id = String((row as TRow)[rowId])
      return selectedRowsId.includes(id)
    })

    return selectedRows
  }

  const calculateBg = (index: number, isHeader = false) => {
    const isEvenIndex = index % 2 === 0
    const baseColor = isEvenIndex ? 'gray.100' : 'white'

    if (!isHeader) {
      return baseColor
    }

    if (isSubHeader) {
      return baseColor
    }

    return baseColor === 'gray.100' ? 'white' : 'gray.100'
  }

  return (
    <Skeleton isLoaded={!isLoading} borderRadius="8px">
      <Box overflowX="auto">
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <Tr key={headerGroup.id} bg={calculateBg(index, true)}>
                {headerGroup.headers.map(header => {
                  return (
                    <S.Th
                      key={header.id}
                      width={header.column.columnDef.meta?.width}
                      colSpan={header.colSpan}
                    >
                      <Flex justify={header.column.columnDef.meta?.align}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Flex>
                    </S.Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row, index) => (
              <Tr
                key={row.id}
                bg={calculateBg(index)}
                onClick={() => onRowClick?.(row)}
                _hover={
                  onRowClick
                    ? {
                        cursor: 'pointer',
                      }
                    : {}
                }
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <S.Td key={cell.id} color="gray.700">
                      <Flex justify={cell.column.columnDef.meta?.align}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Flex>
                    </S.Td>
                  )
                })}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <Tr key={footerGroup.id} p={0}>
                {footerGroup.headers.map(header => {
                  const value = flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )

                  return (
                    <S.Th
                      key={header.id}
                      px={!value ? 0 : 4}
                      py={!value ? 0 : 1}
                    >
                      {header.isPlaceholder ? null : value}
                    </S.Th>
                  )
                })}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </Box>
    </Skeleton>
  )
}

export default DataTable
