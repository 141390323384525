import http from '@/api/http'

import {
  TGetObjectResponse,
  TGetObjectsParams,
  TGetObjectsResponse,
  TGetObjectsVolumesResponse,
} from './types'

export const getObject = async (idObject: string) => {
  const response = await http.get<TGetObjectResponse>(`/objects/${idObject}/`)
  return response.data
}

export const getObjects = async (filters: TGetObjectsParams) => {
  const response = await http.get<TGetObjectsResponse>('/objects/', {
    params: filters,
  })

  return response.data
}

export const getObjectsVolumes = async (
  idObject: string,
  filters: TGetObjectsParams
) => {
  const response = await http.get<TGetObjectsVolumesResponse>(
    `/objects/${idObject}/volumes/`,
    {
      params: filters,
    }
  )

  return response.data
}
