import http from '@/api/http'

import { TGetSpecialNeedsResponse, TGetEducationLevelsResponse } from './types'

export const getSpecialNeeds = async () => {
  const response = await http.get<TGetSpecialNeedsResponse>(
    '/users/special_need/'
  )
  return response.data
}

export const getEducationLevels = async () => {
  const response = await http.get<TGetEducationLevelsResponse>(
    '/users/education_level/'
  )

  return response.data
}
