import React from 'react'
import { FaTimes } from 'react-icons/fa'

import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  Text,
  IconButton,
  ModalFooter,
} from '@chakra-ui/react'

import S from './styles'
import { IModalProps } from './types'

const Modal: React.FC<IModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  footer,
  ...rest
}) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <S.ModalHeader>
          <Text>{title}</Text>
          <IconButton
            variant="ghost"
            aria-label="Close Modal"
            icon={<FaTimes />}
            onClick={onClose}
          />
        </S.ModalHeader>
        <S.ModalBody>{children}</S.ModalBody>
        {footer && (
          <ModalFooter
            p={4}
            borderTop="1px solid"
            borderColor="gray.200"
            position="sticky"
            bottom={0}
            bg="white"
          >
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
