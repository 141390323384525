import React, { useState } from 'react'
import {
  FaChevronLeft,
  FaCalculator,
  FaCheckCircle,
  FaRegEdit,
} from 'react-icons/fa'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import {
  Stack,
  Text,
  Flex,
  Button,
  Divider,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import { Input } from '@/components/Forms'

import { IEffortCalculatorProps } from '../types'
import NumEvaluatorsModal from './Moldals/NumEvaluators'
import NumTeamsModal from './Moldals/NumTeams'

interface IFormValues {
  time: number | string
  workload: number | string
}

const tableHeaders: Array<IHeader> = [
  {
    name: 'EQUIPE',
    key: 'team',
    type: 'element',
    width: '33.33333%',
    align: 'center',
  },
  {
    name: 'AVALIADOR',
    key: 'evaluator',
    type: 'element',
    width: '33.33333%',
    align: 'center',
  },
  {
    name: 'TEMPO',
    key: 'time',
    type: 'element',
    width: '33.33333%',
    align: 'center',
  },
]

const tableData = [
  {
    id: 1,
    team: 'EQ001',
    evaluator: '1',
    time: '10 dias',
  },
  {
    id: 2,
    team: 'EQ002',
    evaluator: '2',
    time: '5 dias',
  },
  {
    id: 3,
    team: 'EQ003',
    evaluator: '3',
    time: '15 dias',
  },
]

const EffortCalculator: React.FC<IEffortCalculatorProps> = ({
  goToPrevious,
  goToNext,
}) => {
  const [hasCalculated, setHasCalculated] = useState(false)
  const {
    isOpen: isOpenTeamsModal,
    onOpen: onOpenTeamsModal,
    onClose: onCloseTeamsModal,
  } = useDisclosure()
  const {
    isOpen: isOpenEvalsModal,
    onOpen: onOpenEvalsModal,
    onClose: onCloseEvalsModal,
  } = useDisclosure()

  const validationSchema = yup.object().shape({
    time: yup.number().required('Tempo de análise obrigatório'),
    workload: yup.number().required('Carga horária obrigatório'),
  })

  const handleSubmit = (values: IFormValues) => {
    setHasCalculated(true)
  }

  return (
    <Stack gap={4}>
      <Stack gap={3}>
        <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
          Calculadora de Dimensionamento das Equipes
        </Text>
        <Text fontSize="xs">
          A calculadora de dimensionamento quantifica o esforço necessário para
          as equipes avaliarem os volumes que serão distribuÍdos. <br /> Nesse
          sentido, adicione as informações necessárias para realizar o cálculo:
        </Text>
      </Stack>
      <Formik
        initialValues={{
          time: '',
          workload: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack gap={4}>
            <Flex gap={4}>
              <Input
                name="time"
                label="Tempo de Análise do Objeto"
                type="number"
                placeholder="Dias úteis"
                helperText="Dias úteis"
                flex={1}
              />
              <Input
                name="workload"
                label="Carga Horária do Analista"
                type="number"
                placeholder="Horas semanais"
                helperText="Horas semanais"
                flex={1}
              />
            </Flex>
            <Flex justify={!hasCalculated ? 'space-between' : 'flex-end'}>
              <Button
                display={hasCalculated ? 'none' : 'block'}
                size="sm"
                key="go-back"
                variant="ghost"
                leftIcon={<FaChevronLeft />}
                color="brand.primary.dark_1"
                onClick={goToPrevious}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                bg="brand.primary.dark_1"
                color="white"
                leftIcon={<FaCalculator />}
              >
                Calcular
              </Button>
            </Flex>
          </Stack>
        </Form>
      </Formik>
      {hasCalculated && (
        <>
          <Divider my={4} />
          <Stack gap={4}>
            <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
              Resultado
            </Text>
            <DataTable headers={tableHeaders} data={tableData} />
            <Flex color="brand.neutral.dark_2" fontSize="sm" fontWeight="bold">
              <Flex flex={1} justify="center" align="center">
                <Text>4 EQUIPES</Text>
                <IconButton
                  icon={<FaRegEdit />}
                  aria-label="Editar equipes"
                  variant="ghost"
                  color="brand.primary.dark_1"
                  onClick={onOpenTeamsModal}
                />
              </Flex>
              <Flex flex={1} justify="center" align="center">
                <Text>24 AVALIADORES</Text>
                <IconButton
                  icon={<FaRegEdit />}
                  aria-label="Editar avaliadores"
                  variant="ghost"
                  color="brand.primary.dark_1"
                  onClick={onOpenEvalsModal}
                />
              </Flex>
              <Flex flex={1} justify="center" align="center">
                <Text>20 DIAS/EQUIPE</Text>
              </Flex>
            </Flex>
            <Flex justify="space-between">
              <Button
                size="sm"
                key="go-back"
                variant="ghost"
                leftIcon={<FaChevronLeft />}
                color="brand.primary.dark_1"
                onClick={goToPrevious}
              >
                Voltar
              </Button>
              <Button
                size="sm"
                bg="brand.primary.dark_1"
                _hover={{ bg: 'brand.primary.dark_2' }}
                color="white"
                leftIcon={<FaCheckCircle />}
              >
                Salvar
              </Button>
            </Flex>
          </Stack>
        </>
      )}
      <NumTeamsModal isOpen={isOpenTeamsModal} onClose={onCloseTeamsModal} />
      <NumEvaluatorsModal
        isOpen={isOpenEvalsModal}
        onClose={onCloseEvalsModal}
      />
    </Stack>
  )
}

export default EffortCalculator
