import { useState } from 'react'

import { useToast } from '@chakra-ui/react'

import { useQueryGetFormQuestions } from '@/api/forms/questions/queries'
import { IFilter } from '@/components/DataFilter'
import { IHeader } from '@/components/DataTable/types'
import { IPageInfoProps } from '@/components/Pagination/types'
import { getErrorDetails } from '@/utils/error'

export const useQuestionsController = () => {
  const toast = useToast()

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const [filters, setFilters] = useState({
    answerType: '',
    formType: '',
    status: '',
  })

  const { data, isLoading } = useQueryGetFormQuestions(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
      ...filters,
    },
    {
      onSuccess: response => {
        setPagination(prev => ({
          ...prev,
          itemsCount: response.pagination.count,
          pageCount: response.pagination.totalPages,
          page: response.pagination.currentPage,
          pageSize: response.pagination.pageSize,
        }))
      },
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    setPagination(prev => {
      const isPageSizeChanged = itemsPerPage !== prev.pageSize

      return {
        ...prev,
        page: isPageSizeChanged ? 1 : pageIndex + 1,
        pageSize: itemsPerPage,
      }
    })
  }

  const handleFilterChange = (newFilters: typeof filters) => {
    setFilters(newFilters)
    setPagination(prev => ({
      ...prev,
      page: 1,
    }))
  }

  const tableHeaders: Array<IHeader> = [
    {
      name: 'Questão',
      key: 'question',
      type: 'element',
      width: '35%',
    },
    {
      name: 'Tipo de Formulário',
      key: 'formType',
      type: 'element',
      width: '20%',
      align: 'center',
    },
    {
      name: 'Tipo de Resposta',
      key: 'answerType',
      type: 'element',
      width: '20%',
      align: 'center',
    },
    {
      name: 'Status',
      key: 'status',
      type: 'element',
      width: '20%',
      align: 'center',
    },
    {
      name: 'Ações',
      key: 'actions',
      type: 'element',
      width: '5%',
      align: 'center',
    },
  ]

  const filterOptions: Array<IFilter> = [
    {
      name: 'answerType',
      label: 'Tipo de resposta',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'formType',
      label: 'Formato do volume',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { label: 'active', value: 'Ativa' },
        { label: 'inactive', value: 'Inativa' },
      ],
    },
  ]

  return {
    pagination,
    questions: data?.results || [],
    isLoading,
    tableHeaders,
    filterOptions,
    handleOnChangePageInfo,
    handleFilterChange,
  }
}
