import { BaseFields } from './common.types'

export interface FormQuestion extends BaseFields {
  coFormQuestion: number
  alternatives: Array<Alternative>
  answerDetails: AnswerDetails
  answerType: AnswerType
  coAnswerModel: number
  coExemplary: number
  coFormFailureType: number
  coFormat: number
  dsHelpText: string
  dsQuestion: string
  dsVolumeVersion: string
  exemplary: Exemplary
  failureType: FailureType
  format: Format
  forms: Array<FormDetails>
  stActive: boolean
  stInformPage: string
  tpForm: string
}

export interface Form extends BaseFields {
  coForm: number
  coExemplary: number
  coFormat: number
  coObject: number
  coPhase: Phase
  coUser: number
  coUserApproval: number
  dsForm: string
  dsNotice?: string
  dsObject?: string
  dsVolumeVersion: string
  nuVersion: number
  questions: Array<QuestionItem>
  stLink: string
  tpForm: string
  stFormActive: boolean
}

export interface FormDetails extends BaseFields {
  form: Form
  nuOrder: number
  coFormQuestion: number
}

export interface Phase {
  coStage: number
  dsStage: string
  nuItem: number | null
  nuOrder: number
  dsKey: string | null
  coStageSup: number | null
}

export interface QuestionItem {
  nuOrder: number
  coFormQuestions: number
  question: Question
}

export interface Question extends BaseFields {
  coFormQuestion: number
  dsQuestion: string
  stActive: boolean
  dsVolumeVersion: string
  dsHelpText: string | null
  coFormat: number
  coExemplary: number
  coFormFailureType: number
  failureType: FailureType
  format: Format
  exemplary: Exemplary
  stInformPage: boolean
  answerType: AnswerType
  answerDetails: AnswerDetails
  alternatives: Array<Alternative>
}

export enum AnswerEnum {
  bool = 1,
  text = 2,
  numeric = 3,
  multipleChoice = 4,
}

export interface FailureType {
  coFormFailureType: number
  dsFailureType: string
  stFailureType: string
}

export interface Format {
  coFormat: number
  dsFormat: string
}

export interface Exemplary {
  coExemplary: number
  dsExemplary: string
}

export interface AnswerType {
  tpAnswer: number
  dsAnswerType: string
}

export interface AnswerDetails {
  coAnswerDetails: number
  stGeneratesDiligence: boolean
  stHasAttachment: boolean
  stMandatoryAttachment: boolean
  stDiligenceAnswer: string
  nuMinimum: number
  nuMaximum: number
}

export interface Alternative {
  coAlternative: number
  dsAlternative: string
  stGeneratesDiligence: boolean
  stHasAttachment: boolean
  stMandatoryAttachment: boolean
}
