import React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabItem, ITabInfo } from '@/components/Tabs/types'

const Panel = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const initialPath = location.pathname.split('/').pop()

  const tabs: Array<ITabItem> = [
    {
      id: 'principal',
      label: 'Principal',
    },
    // {
    //   id: 'notificacoes',
    //   label: 'Notificações',
    // },
    {
      id: 'objetos',
      label: 'Objetos',
    },
  ]

  const handleTabClick = ({ id }: ITabInfo) => {
    navigate(`/painel/${id}`)
  }

  return (
    <Flex direction="column" flexGrow={1} gap="6">
      <Tabs items={tabs} initialTab={initialPath} onTabClick={handleTabClick} />
      <Outlet />
    </Flex>
  )
}

export default Panel
