import React, { useState } from 'react'
import { FaCheckSquare } from 'react-icons/fa'

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import Tag from '@/components/Tag'

import { tabs } from './controller'

const Phase3: React.FC = () => {
  const [activeTabId, setActiveTabId] = useState('volumes')
  return (
    <Flex direction="column" gap={8}>
      <Flex direction="row" justify="space-between">
        <Flex direction="column" gap={1}>
          <Text
            color="#475671"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Análise de Atributos
          </Text>
          <Box>
            <Tag group="statusObjects" value="ongoing" />
          </Box>
        </Flex>
        <Flex>
          <Button
            colorScheme="gray"
            variant="outline"
            borderRadius="4"
            leftIcon={<Icon as={FaCheckSquare} color="#475671" />}
          >
            <Text color="#475671">Finalizar fase</Text>
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Tabs
          items={tabs}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
          variant="rounded"
          size="sm"
          colorScheme="gray"
        />
      </Flex>
    </Flex>
  )
}

export default Phase3
