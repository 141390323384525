import React, { useMemo, useRef, useState } from 'react'

import { Form, Formik, FormikProps, FormikValues } from 'formik'

import {
  Text,
  Button,
  Flex,
  Stack,
  Divider,
  Checkbox,
  useToast,
} from '@chakra-ui/react'

import {
  useQueryGetFormQuestionFieldValues,
  useQueryGetFormQuestions,
} from '@/api/forms/questions/queries'
import { Input, BasicSelect } from '@/components/Forms'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import { useQuestionManagement } from '@/contexts/QuestionManagementContext'
import { FormQuestion } from '@/types/form.types'
import { getErrorDetails } from '@/utils/error'

import { IModalProps } from '../types'

export const ModalExistingQuestion = ({
  isOpen,
  onClose,
  onSuccess,
}: IModalProps): JSX.Element => {
  const toast = useToast()
  const { addNewQuestions, setQuestionsToAdd } = useQuestionManagement()
  const formikRef = useRef<FormikProps<FormikValues>>(null)
  const [selectedQuestions, setSelectedQuestions] = useState<FormQuestion[]>([])
  const [madeFirstRequest, setMadeFirstRequest] = useState(true)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
  })
  const [filters, setFilters] = useState({
    dsQuestion: '',
    coAnswerModel: '',
    coFormat: '',
    dsVolumeVersion: '',
    coExemplary: '',
    coFailure: '',
  })

  const { data: fieldValues, isLoading: isFieldValuesLoading } =
    useQueryGetFormQuestionFieldValues()
  const { data: questionsResult, isFetching: isLoadingQuestions } =
    useQueryGetFormQuestions(
      {
        page: pagination.page,
        pageSize: pagination.pageSize,
        ...filters,
      },
      {
        enabled: madeFirstRequest,
        onSuccess: data => {
          setPagination({
            ...pagination,
            itemsCount: data.pagination.count,
            pageCount: data.pagination.totalPages,
          })
        },
        onError: error => {
          toast({
            title: 'Erro ao buscar questões',
            description: getErrorDetails(error),
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        },
      }
    )

  const questions = useMemo(() => {
    return questionsResult?.results
  }, [questionsResult])

  const selectOptions = useMemo(() => {
    return {
      formats:
        fieldValues?.formats.map(format => ({
          value: format.coFormat.toString(),
          label: format.dsFormat,
        })) ?? [],
      exemplaries:
        fieldValues?.exemplaries.map(exemplary => ({
          value: exemplary.coExemplary.toString(),
          label: exemplary.dsExemplary,
        })) ?? [],
      answerTypes:
        fieldValues?.answerTypes.map(answer => ({
          value: answer.tpAnswer.toString(),
          label: answer.dsAnswerType,
        })) ?? [],
      volumeVersion:
        fieldValues?.versions?.map(version => ({
          value: version,
          label: version,
        })) ?? [],
    }
  }, [fieldValues])

  const handleSearchQuestions = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    setPagination(prev => {
      const isPageSizeChanged = itemsPerPage !== prev.pageSize

      return {
        ...prev,
        page: isPageSizeChanged ? 1 : pageIndex + 1,
        pageSize: itemsPerPage,
      }
    })
  }

  const handleCheckboxChange = (question: FormQuestion, isChecked: boolean) => {
    setSelectedQuestions(prevSelected => {
      const updatedSelected = new Set(prevSelected)
      if (isChecked) {
        updatedSelected.add(question)
      } else {
        updatedSelected.delete(question)
      }
      return Array.from(updatedSelected)
    })
  }

  const handleSubmit = (values: FormikValues) => {
    setMadeFirstRequest(true)
    setFilters({
      dsQuestion: values.dsQuestion,
      coAnswerModel: values.coAnswerModel,
      coFormat: values.coFormat,
      dsVolumeVersion: values.dsVolumeVersion,
      coExemplary: values.coExemplary,
      coFailure: values.coFailure,
    })
  }

  const handleAddQuestions = () => {
    if (selectedQuestions.length === 0) {
      toast({
        title: 'Selecione ao menos uma questão',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    const formattedQuestions = selectedQuestions.map(question => {
      return {
        id: question.coFormQuestion,
        name: question.dsQuestion,
        type: question.answerType.dsAnswerType,
        dsStatus: question.stActive ? 'Ativo' : 'Inativo',
      }
    })

    addNewQuestions(formattedQuestions)
    setQuestionsToAdd(prevState => [
      ...prevState,
      ...formattedQuestions.map(question => question.id),
    ])
    onSuccess?.()
  }

  return (
    <Modal
      title="Adicionar questão existente"
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <Stack spacing="4">
        <Formik
          innerRef={formikRef}
          initialValues={{
            dsQuestion: '',
            coAnswerModel: '',
            coFormat: '',
            dsVolumeVersion: '',
            coExemplary: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <Stack gap={4}>
              <Input
                name="dsQuestion"
                label="Pesquisar"
                placeholder="Pesquisar"
              />
              <Flex gap={4}>
                <BasicSelect
                  name="coAnswerModel"
                  label="Tipo de resposta"
                  placeholder="--"
                  options={selectOptions.answerTypes}
                  isLoading={isFieldValuesLoading}
                />
                <BasicSelect
                  isDisabled
                  name="coFailure"
                  label="Código da Falha"
                  placeholder="--"
                  options={[]}
                  isLoading={isFieldValuesLoading}
                />
              </Flex>
              <Flex gap={4}>
                <BasicSelect
                  name="coFormat"
                  label="Formato do Volume"
                  placeholder="--"
                  options={selectOptions.formats}
                  isLoading={isFieldValuesLoading}
                />
                <BasicSelect
                  name="dsVolumeVersion"
                  label="Versao do Volume"
                  placeholder="--"
                  options={selectOptions.volumeVersion}
                  isLoading={isFieldValuesLoading}
                />
                <BasicSelect
                  name="coExemplary"
                  label="Exemplar do Volume"
                  placeholder="--"
                  options={selectOptions.exemplaries}
                  isLoading={isFieldValuesLoading}
                />
              </Flex>
              <Button
                type="submit"
                onClick={handleSearchQuestions}
                isLoading={isLoadingQuestions}
                isDisabled={isFieldValuesLoading}
              >
                Buscar
              </Button>
            </Stack>
          </Form>
        </Formik>
        <Divider />
        {questions && (
          <>
            <Stack
              gap={4}
              maxH="216px"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'gray.300',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'gray.400',
                  borderRadius: '24px',
                },
              }}
            >
              {questions.map(question => (
                <Flex
                  key={`question-${question.coFormQuestion}`}
                  p={4}
                  align="center"
                  justify="space-between"
                  border="1px solid"
                  borderColor="brand.neutral.light_1"
                  borderRadius="md"
                  boxShadow="md"
                >
                  <Stack gap={0} color="brand.neutral.dark_2">
                    <Text fontSize="sm">{question.coFormQuestion}</Text>
                    <Text fontWeight="bold">{question.dsQuestion}</Text>
                    <Text fontSize="sm">
                      {question.answerType.dsAnswerType}
                    </Text>
                  </Stack>
                  <Checkbox
                    size="lg"
                    _checked={{
                      '& .chakra-checkbox__control': {
                        background: 'brand.primary.dark_1',
                        borderColor: 'brand.primary.dark_1',
                      },
                    }}
                    onChange={e =>
                      handleCheckboxChange(question, e.target.checked)
                    }
                  />
                </Flex>
              ))}
            </Stack>
            <Flex justify="space-between" align="center">
              <Pagination
                pageIndex={pagination.page - 1}
                itemsPerPage={pagination.pageSize}
                pageCount={pagination.pageCount}
                itemsCount={pagination.itemsCount}
                onChangePageInfo={handleOnChangePageInfo}
              />
              <Text>
                <strong>{pagination.itemsCount}</strong> questões encontrada(s).
              </Text>
            </Flex>
          </>
        )}
        <Flex justifyContent="right" gap="12px" alignItems="center">
          <Button variant="outline" borderRadius="full" onClick={onClose}>
            Cancelar
          </Button>
          <Button borderRadius="full" onClick={handleAddQuestions}>
            Adicionar
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
