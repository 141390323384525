import React, { useMemo } from 'react'
import { FaPlusCircle, FaRegEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import {
  Stack,
  Text,
  Flex,
  Button,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import Tag from '@/components/Tag'

import { useQuestionsController } from './controller'
import RegisterQuestionModal from './parts/RegisterQuestionModal'

interface IInfoProps {
  title: string
  subtitle: string
}

const Info = ({ title, subtitle }: IInfoProps) => (
  <Stack gap={0.5} color="brand.neutral.dark_2">
    <Text fontSize="sm">{title}</Text>
    <Text fontSize="xs">{subtitle}</Text>
  </Stack>
)

interface IActionsProps {
  coQuestion: number
}

const Actions = ({ coQuestion }: IActionsProps) => {
  const navigate = useNavigate()
  return (
    <IconButton
      variant="ghost"
      aria-label="Editar"
      icon={<FaRegEdit />}
      onClick={() => navigate(`/detalhes-da-questao/${coQuestion}`)}
    />
  )
}

const QuestionsList = () => {
  const {
    isOpen: isRegisterQuestionOpen,
    onOpen: onRegisterQuestionOpen,
    onClose: onRegisterQuestionClose,
  } = useDisclosure()

  const {
    pagination,
    questions,
    isLoading,
    tableHeaders,
    filterOptions,
    handleOnChangePageInfo,
  } = useQuestionsController()

  const tableData = useMemo(() => {
    if (!questions?.length) return []

    return questions.map(question => ({
      id: question.coFormQuestion,
      question: (
        <Info
          title={question.dsQuestion}
          subtitle={question.coFormQuestion.toString()}
        />
      ),
      formType: question.format.dsFormat,
      answerType: question.answerType.dsAnswerType,
      status: (
        <Tag
          group="statusQuestions"
          value={question.stActive ? 'active' : 'inactive'}
        />
      ),
      actions: <Actions coQuestion={question.coFormQuestion} />,
    }))
  }, [questions])

  return (
    <>
      <RegisterQuestionModal
        isOpen={isRegisterQuestionOpen}
        onClose={onRegisterQuestionClose}
      />

      <Stack p={4} spacing={8} boxShadow="md" borderRadius="md">
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
            Lista de questões
          </Text>
          <Button
            leftIcon={<FaPlusCircle />}
            borderRadius="6px"
            size="sm"
            onClick={onRegisterQuestionOpen}
          >
            Cadastrar nova questão
          </Button>
        </Flex>

        <DataFilter filters={filterOptions} onChangeFilter={() => {}} />

        <DataTable
          rowId="id"
          headers={tableHeaders}
          data={tableData}
          isLoading={isLoading}
        />

        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Stack>
    </>
  )
}

export default QuestionsList
