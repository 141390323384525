import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { getNoticeObject } from './services'
import { TGetNoticeObjectResponse } from './types'

export const useQueryGetNoticeObjects = (
  options?: UseQueryOptions<TGetNoticeObjectResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getNoticeObject()

  return useQuery<TGetNoticeObjectResponse, AxiosError<Error>>(
    useQueryGetNoticeObjects.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryGetNoticeObjects.getFullQueryKey = (): QueryKey => ['co_notice']

useQueryGetNoticeObjects.queryKey = ['co_notice']
