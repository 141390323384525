import React from 'react'

import { Flex } from '@chakra-ui/react'

import ManagementVolumes from './ManagementVolumes'

const Volumes: React.FC = () => {
  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      <ManagementVolumes />
    </Flex>
  )
}

export default Volumes
