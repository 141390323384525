import React from 'react'

import { Global } from '@emotion/react'

import Rawline100 from '@/assets/fonts/rawline-100.ttf'
import Rawline100Italic from '@/assets/fonts/rawline-100i.ttf'
import Rawline200 from '@/assets/fonts/rawline-200.ttf'
import Rawline200Italic from '@/assets/fonts/rawline-200i.ttf'
import Rawline300 from '@/assets/fonts/rawline-300.ttf'
import Rawline300Italic from '@/assets/fonts/rawline-300i.ttf'
import Rawline400 from '@/assets/fonts/rawline-400.ttf'
import Rawline400Italic from '@/assets/fonts/rawline-400i.ttf'
import Rawline500 from '@/assets/fonts/rawline-500.ttf'
import Rawline500Italic from '@/assets/fonts/rawline-500i.ttf'
import Rawline600 from '@/assets/fonts/rawline-600.ttf'
import Rawline600Italic from '@/assets/fonts/rawline-600i.ttf'
import Rawline700 from '@/assets/fonts/rawline-700.ttf'
import Rawline700Italic from '@/assets/fonts/rawline-700i.ttf'
import Rawline800 from '@/assets/fonts/rawline-800.ttf'
import Rawline800Italic from '@/assets/fonts/rawline-800i.ttf'
import Rawline900 from '@/assets/fonts/rawline-900.ttf'
import Rawline900Italic from '@/assets/fonts/rawline-900i.ttf'

const Fonts: React.FC = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 100;
        src: url(${Rawline100}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 100;
        src: url(${Rawline100Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 200;
        src: url(${Rawline200}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 200;
        src: url(${Rawline200Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 300;
        src: url(${Rawline300}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 300;
        src: url(${Rawline300Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 400;
        src: url(${Rawline400}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 400;
        src: url(${Rawline400Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 500;
        src: url(${Rawline500}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 500;
        src: url(${Rawline500Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 600;
        src: url(${Rawline600}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 600;
        src: url(${Rawline600Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 700;
        src: url(${Rawline700}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 700;
        src: url(${Rawline700Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 800;
        src: url(${Rawline800}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 800;
        src: url(${Rawline800Italic}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: normal;
        font-weight: 900;
        src: url(${Rawline900}) format('truetype');
      }
      @font-face {
        font-family: 'Rawline';
        font-style: italic;
        font-weight: 900;
        src: url(${Rawline900Italic}) format('truetype');
      }
    `}
  />
)

export default Fonts
