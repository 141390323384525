import React from 'react'

import { Flex, Stack, Text } from '@chakra-ui/react'

import { IFilter } from '@/components/DataFilter'
import { IHeader } from '@/components/DataTable/types'
import StatusTag from '@/components/Tag/StatusQuestions'

import { IBoxInfoTableProps, IInfoFormProps } from './types'

export const getFormStatus = (stLink: string) => {
  switch (stLink) {
    case 'AGUARDANDO_VINCULO':
      return 'awaitingLink'
    case 'AGUARDANDO_VALIDACAO':
      return 'awaitingApproval'
    case 'VINCULADO':
      return 'linked'
    case 'FINALIZADO':
      return 'finished'
    default:
      return 'awaitingApproval'
  }
}

export const InfoFormTable = ({ formId, status }: IInfoFormProps) => {
  return (
    <Flex gap={2} alignItems="center">
      <Text fontSize="sm">{formId}</Text>
      {StatusTag({ tag: getFormStatus(status) })}
    </Flex>
  )
}

export const BoxInfoTable = ({
  title,
  subtitle,
  alignCenter,
}: IBoxInfoTableProps) => {
  return (
    <Stack
      align={alignCenter ? 'center' : 'flex-start'}
      gap={0.5}
      color="brand.neutral.dark_2"
    >
      <Text fontSize="sm">{title}</Text>
      <Text fontSize="xs">{subtitle}</Text>
    </Stack>
  )
}

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Formulário',
    key: 'form',
    type: 'element',
    width: '40%',
  },
  {
    name: 'Objeto',
    key: 'object',
    type: 'element',
    width: '20%',
  },
  {
    name: 'Status da questão',
    key: 'status',
    type: 'element',
    width: '20%',
    align: 'center',
  },
  {
    name: 'Data de adição',
    key: 'date',
    width: '20%',
    align: 'center',
    type: 'element',
  },
]

export const filtersTableForms: Array<IFilter> = [
  {
    name: 'answerType',
    label: 'Filtro 1',
    type: 'select',
    options: [
      { label: 'Option 1', value: 'option1' },
      { label: 'Option 2', value: 'option2' },
    ],
  },
  {
    name: 'formType',
    label: 'Filtro 2',
    type: 'select',
    options: [
      { label: 'Option 1', value: 'option1' },
      { label: 'Option 2', value: 'option2' },
    ],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { label: 'active', value: 'Ativa' },
      { label: 'inactive', value: 'Inativa' },
    ],
  },
]
