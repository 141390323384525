import React, { useState } from 'react'
import { FaSync } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { useQueryGetObject } from '@/api/objects/queries'
import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import { getErrorDetails } from '@/utils/error'

import OpenReanalysisVolumesModal from './parts/OpenReanalysisVolumesModal'
import TableVolumesDetailsObject from './TableVolumesDetailsObject'

const DetailsObjectVolumes = () => {
  const toast = useToast()

  const location = useLocation()
  const objectId = location.pathname.split('/').pop()

  // TODO: RQ-12 Waiting for api to implement validation
  const isProfileCoordinator = true

  const {
    isOpen: isConfirmReanalysisVolumesOpen,
    onOpen: onConfirmReanalysisVolumesOpen,
    onClose: onConfirmReanalysisVolumesClose,
  } = useDisclosure()

  const handleOpenReanalysisVolumesModal = () => {
    onConfirmReanalysisVolumesOpen()
  }

  const tabsDefault: ITabItem[] = [
    {
      id: 'pending',
      label: 'Pendentes',
      render: () => <TableVolumesDetailsObject />,
    },
    {
      id: 'finished',
      label: 'Finalizados',
      render: () => <TableVolumesDetailsObject />,
    },
  ]

  const tabsCoordinator: ITabItem[] = [
    {
      id: 'pending',
      label: 'Pendentes',
      render: () => <TableVolumesDetailsObject />,
    },
    {
      id: 'inProgress',
      label: 'Em andamento',
      render: () => <TableVolumesDetailsObject />,
    },
    {
      id: 'finished',
      label: 'Finalizados',
      render: () => <TableVolumesDetailsObject />,
    },
  ]

  const { data: object, isLoading } = useQueryGetObject(
    { idObject: String(objectId) },
    {
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const ObjectInfo = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
          boxShadow="md"
          borderRadius="md"
          py="5"
          px="5"
        >
          <VStack spacing={4}>
            <Text>Buscando dados do objeto...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    return (
      <>
        <OpenReanalysisVolumesModal
          isOpen={isConfirmReanalysisVolumesOpen}
          onClose={onConfirmReanalysisVolumesClose}
        />

        <Flex
          direction="column"
          gap="4"
          py="5"
          px="5"
          boxShadow="md"
          borderRadius="md"
        >
          <Flex justify="space-between">
            <Text
              color="#475671"
              fontSize="20px"
              fontWeight="700"
              lineHeight="120%"
            >
              Objeto {object?.coObject}: {object?.dsObject}
            </Text>
            {object?.dsStatus && (
              <Tag group="statusObjects" value={object?.dsStatus} />
            )}
          </Flex>

          <Box w="full" h="full" gap="4">
            <Flex direction="row" gap="4">
              <Box w="50%">
                <FormLabel fontSize="sm">Edital</FormLabel>
                <Input
                  placeholder={`${object?.notice?.dsTitle || 'Não informado'}`}
                  disabled
                  bg="brand.neutral.light_2"
                  borderColor="brand.neutral.light_2"
                  _placeholder={{ fontStyle: 'italic' }}
                />
              </Box>
              <Box w="50%">
                <FormLabel fontSize="sm">Código do Objeto</FormLabel>
                <Input
                  placeholder={String(object?.coObject) || 'Não informado'}
                  disabled
                  bg="brand.neutral.light_2"
                  borderColor="brand.neutral.light_2"
                  _placeholder={{ fontStyle: 'italic' }}
                />
              </Box>
              <Box w="50%">
                <FormLabel fontSize="sm">Fase</FormLabel>
                <Input
                  placeholder={object?.dsStage || 'Não informado'}
                  disabled
                  bg="brand.neutral.light_2"
                  borderColor="brand.neutral.light_2"
                  _placeholder={{ fontStyle: 'italic' }}
                />
              </Box>
            </Flex>
            {isProfileCoordinator && (
              <Flex mt={5} justifyContent="flex-end">
                <Button
                  leftIcon={<FaSync />}
                  variant="solid"
                  onClick={handleOpenReanalysisVolumesModal}
                >
                  Abrir reanálise dos volumes
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
      </>
    )
  }

  const Volumes = () => {
    const [activeTabId, setActiveTabId] = useState('pending')

    return (
      <Flex direction="column" gap="4" p="5" boxShadow="md" borderRadius="md">
        <Tabs
          items={isProfileCoordinator ? tabsCoordinator : tabsDefault}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="6">
      <ObjectInfo />
      <Volumes />
    </Flex>
  )
}

export default DetailsObjectVolumes
