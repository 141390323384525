import React, { useEffect } from 'react'

import { useFormikContext } from 'formik'
import { FieldArray } from 'formik'

import { DeleteIcon } from '@chakra-ui/icons'
import {
  Stack,
  Flex,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormLabel,
  Checkbox,
  Text,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'

import { Input } from '@/components/Forms'
import { AnswerEnum } from '@/types/form.types'

import { IRegisterQuestionFormValues, IAnswerOption } from './types'

interface AnswerTypeFieldsProps {
  answerType: string
  fieldPrefix?: string
  isAlternativeQuestion?: boolean
  hasAlternatives?: boolean
  readOnly?: boolean
}

const defaultOption = {
  value: '',
  label: '',
  generatesAlternative: false,
  generatesDiligence: false,
  hasAttachment: false,
  isAttachmentRequired: false,
}

const AnswerTypeFields: React.FC<AnswerTypeFieldsProps> = ({
  answerType,
  fieldPrefix = '',
  isAlternativeQuestion = false,
  hasAlternatives = false,
  readOnly = false,
}) => {
  const { values, setFieldValue } =
    useFormikContext<IRegisterQuestionFormValues>()

  if (!answerType) return null

  const checkboxStyles = {
    'span.chakra-checkbox__control': {
      bg: 'white',
      _checked: {
        bg: 'white',
        color: '#475671',
        borderColor: 'gray.300',
      },
    },
  }

  const getFieldPath = (field: string) =>
    fieldPrefix ? `${fieldPrefix}${field}` : field

  const getValue = (field: string) => {
    if (!fieldPrefix) return values[field as keyof IRegisterQuestionFormValues]

    const parts = fieldPrefix.match(/(\w+)\[(\d+)\]\./)
    if (!parts) return null

    const [, arrayName, indexStr] = parts
    const index = Number(indexStr)

    if (
      arrayName === 'alternativeQuestions' &&
      values.alternativeQuestions?.[index]
    ) {
      return values.alternativeQuestions[index][
        field as keyof (typeof values.alternativeQuestions)[0]
      ]
    }

    return null
  }

  useEffect(() => {
    if (answerType === AnswerEnum.multipleChoice.toString()) {
      const optionsFieldPath = getFieldPath('options')
      const options = (getValue('options') as IAnswerOption[]) || []

      if (options.length < 2) {
        const newOptions = [...options]
        while (newOptions.length < 2) {
          newOptions.push({ ...defaultOption })
        }
        setFieldValue(optionsFieldPath, newOptions)
      }
    }
  }, [answerType, values])

  const renderBooleanFields = () => (
    <Stack spacing={4}>
      <Flex justify="space-between">
        <Box>
          <FormLabel mb={2}>
            <Text color="brand.primary.dark_1" fontWeight="bold">
              Qual das respostas gera diligência?
            </Text>
          </FormLabel>
          <RadioGroup
            // TODO: Alterar quando a parte de diligência estiver pronta
            /* onChange={val =>
                setFieldValue(getFieldPath('defaultYesNo'), val === 'true')
              }
              value={getValue('defaultYesNo') ? 'true' : 'false'} */
            isDisabled={readOnly || true}
          >
            <Stack direction="row" spacing={5}>
              <Radio value="true">
                <Text fontSize="sm">Resposta positiva</Text>
              </Radio>
              <Radio value="false">
                <Text fontSize="sm">Resposta negativa</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        {!isAlternativeQuestion && hasAlternatives && (
          <Box mt={{ base: 4, md: 0 }}>
            <FormLabel mb={2}>
              <Text color="brand.primary.dark_1" fontWeight="bold">
                Qual das respostas gera questão alternativa?
              </Text>
            </FormLabel>
            <RadioGroup
              onChange={val =>
                setFieldValue(getFieldPath('defaultYesNo'), val === 'true')
              }
              value={getValue('defaultYesNo') ? 'true' : 'false'}
              isDisabled={readOnly}
            >
              <Stack direction="row" spacing={5}>
                <Radio value="true">
                  <Text fontSize="sm">Resposta positiva</Text>
                </Radio>
                <Radio value="false">
                  <Text fontSize="sm">Resposta negativa</Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        )}
      </Flex>
    </Stack>
  )

  const renderTextFields = () => (
    <Stack spacing={4}>
      <Box>
        <FormLabel mb={0}>Configurações de Resposta</FormLabel>
        <Flex gap="4" mt={2} justify="space-between">
          <Checkbox
            isChecked={getValue('generatesDiligence') as boolean}
            onChange={e =>
              setFieldValue(
                getFieldPath('generatesDiligence'),
                e.target.checked
              )
            }
            size="lg"
            sx={checkboxStyles}
            isDisabled={readOnly}
          >
            <Text fontSize="sm">Gera diligência?</Text>
          </Checkbox>
          <Checkbox
            isChecked={getValue('hasAttachment') as boolean}
            onChange={e =>
              setFieldValue(getFieldPath('hasAttachment'), e.target.checked)
            }
            size="lg"
            sx={checkboxStyles}
            isDisabled={readOnly}
          >
            <Text fontSize="sm">Tem anexo?</Text>
          </Checkbox>
          <Checkbox
            isChecked={getValue('isAttachmentRequired') as boolean}
            onChange={e =>
              setFieldValue(
                getFieldPath('isAttachmentRequired'),
                e.target.checked
              )
            }
            size="lg"
            sx={checkboxStyles}
            isDisabled={readOnly || !(getValue('hasAttachment') as boolean)}
          >
            <Text fontSize="sm">O anexo é obrigatório?</Text>
          </Checkbox>
        </Flex>
      </Box>
    </Stack>
  )

  const renderMultipleChoiceFields = () => {
    const optionsFieldPath = getFieldPath('options')
    const options = (getValue('options') as IAnswerOption[]) || []

    return (
      <Box>
        <FieldArray
          name={optionsFieldPath}
          render={arrayHelpers => (
            <Stack spacing={4}>
              {options.map((option, index) => (
                <Flex key={index} position="relative" borderRadius="md">
                  <Tooltip label="Remover opção">
                    <IconButton
                      aria-label="Remover opção"
                      icon={<DeleteIcon />}
                      size="sm"
                      color="brand.error.base"
                      onClick={() => {
                        if (options.length > 2) {
                          arrayHelpers.remove(index)
                        }
                      }}
                      variant="ghost"
                      position="absolute"
                      top={2}
                      right={2}
                      isDisabled={readOnly || options.length <= 2}
                    />
                  </Tooltip>

                  <Stack width="100%">
                    <Text fontWeight="bold" color="brand.primary.dark_1">
                      Resposta {index + 1}
                    </Text>

                    <Flex gap="4" flexDirection={{ base: 'column', md: 'row' }}>
                      <Box w="60%">
                        <Input
                          label="Resposta"
                          name={`${optionsFieldPath}.${index}.label`}
                          placeholder="---"
                          isDisabled={readOnly}
                        />
                      </Box>

                      <Box flex="1">
                        <Input
                          label="Valor"
                          name={`${optionsFieldPath}.${index}.value`}
                          placeholder="---"
                          isDisabled={readOnly}
                        />
                      </Box>
                    </Flex>
                    <Flex mt={2} gap="4" justify="space-between">
                      {[
                        ...(!isAlternativeQuestion && hasAlternatives
                          ? ['generatesAlternative']
                          : []),
                        'generatesDiligence',
                        'hasAttachment',
                        'isAttachmentRequired',
                      ].map((checkboxField, cIndex) => (
                        <Checkbox
                          key={cIndex}
                          isChecked={
                            option[
                              checkboxField as keyof typeof option
                            ] as boolean
                          }
                          onChange={e => {
                            setFieldValue(
                              `${optionsFieldPath}.${index}.${checkboxField}`,
                              e.target.checked
                            )
                          }}
                          isDisabled={
                            readOnly ||
                            (checkboxField === 'isAttachmentRequired' &&
                              !option.hasAttachment)
                          }
                          sx={checkboxStyles}
                          size="lg"
                        >
                          <Text fontSize="sm">
                            {checkboxField === 'generatesDiligence'
                              ? 'Gera diligência?'
                              : checkboxField === 'hasAttachment'
                              ? 'Tem anexo?'
                              : checkboxField === 'isAttachmentRequired'
                              ? 'O anexo é obrigatório?'
                              : 'Gera questão alternativa?'}
                          </Text>
                        </Checkbox>
                      ))}
                    </Flex>
                  </Stack>
                </Flex>
              ))}

              {!readOnly && (
                <Button
                  size="sm"
                  variant={isAlternativeQuestion ? 'outline' : 'solid'}
                  onClick={() => {
                    if (options.length > 4) return
                    arrayHelpers.push({
                      value: '',
                      label: '',
                      generatesAlternative: false,
                      generatesDiligence: false,
                      hasAttachment: false,
                      isAttachmentRequired: false,
                    })
                  }}
                  width="100%"
                  isDisabled={options.length > 4}
                >
                  {isAlternativeQuestion
                    ? 'Adicionar resposta nesta questão alternativa'
                    : 'Adicionar resposta a questão principal'}
                </Button>
              )}
            </Stack>
          )}
        />
      </Box>
    )
  }

  switch (answerType) {
    case AnswerEnum.bool.toString():
      return renderBooleanFields()
    case AnswerEnum.text.toString():
    case AnswerEnum.numeric.toString():
      return renderTextFields()
    case AnswerEnum.multipleChoice.toString():
      return renderMultipleChoiceFields()
    default:
      return null
  }
}

export default AnswerTypeFields
