import React from 'react'
import { BsFillPencilFill } from 'react-icons/bs'
import { FaCheckCircle } from 'react-icons/fa'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { Box, Button, Flex, Stack, SkeletonText } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import { ProfileProvider } from '@/contexts/ProfileContext/ProfileContext'

import { useProfileController } from './controller'
import { ModalProfile } from './Modal'
import S from './styles'

const tabs: Array<ITabItem> = [
  {
    id: 'informacoes-pessoais',
    label: 'Informações pessoais',
  },
  {
    id: 'vinculos',
    label: 'Vínculos',
  },
]

const Profile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    userData,
    isLoadingUserData,
    userValues,
    updateMyAccount,
    isUpdatingAccount,
    dataMyAccount,
    specialNeedsOptions,
    educationLevelsOptions,
    countries,
    states,
    cities,
    isLoadingCountries,
    isLoadingStates,
    isLoadingCities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    isStateSelectDisabled,
    isCitySelectDisabled,
    isOpen,
    onOpen,
    onClose,
    handleTabClick,
    handleSubmit,
  } = useProfileController()

  const initialPath = location.pathname.split('/').pop()

  const validationSchema = yup.object().shape({
    noUserName: yup.string().required('O nome é obrigatório'),
    noUserSocialName: yup.string(),
    dsCpf: yup.string().required('O CPF é obrigatório'),
    dtBirthDate: yup.string().required('A data de nascimento é obrigatória'),
    coSpecialNeed: yup.string().required('A deficiência é obrigatória'),
    coEducationLevel: yup
      .string()
      .required('O nível de escolaridade é obrigatório'),
    dsInstitution: yup.string().required('A instituição é obrigatória'),
    dsInstitutionalEmail: yup
      .string()
      .required('O email institucional é obrigatório'),
    dsRolePositionJob: yup.string().required('O cargo é obrigatório'),
    dsGovbrEmail: yup.string().required('O campo é obrigatório'),
    dsCellphone: yup.string().required('O telefone é obrigatório'),
    dsCountry: yup.string().required('O país é obrigatório'),
    dsUfProvince: yup.string().when('dsCountry', {
      is: (value: string) => !!value,
      then: schema => schema.required('O estado é obrigatório'),
    }),
    dsCity: yup.string().when('dsUfProvince', {
      is: (value: string) => !!value,
      then: schema => schema.required('A cidade é obrigatória'),
    }),
  })

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding={5}
        gap={5}
        borderRadius={5}
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Flex
          color="white"
          borderRight="1px"
          align={{ base: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          justify={{ base: 'center' }}
        >
          <S.TopContainer>
            <S.AvatarContainer>
              <S.StyledAvatar src={userValues?.dsPhotoUrl} />
              <S.EditAvatarButton
                aria-label="Alterar foto"
                icon={<BsFillPencilFill />}
                isDisabled={isLoadingUserData}
                onClick={onOpen}
              />
            </S.AvatarContainer>
            <Stack>
              <SkeletonText isLoaded={!isLoadingUserData} noOfLines={2}>
                <S.UserNameText>
                  {userData?.noUserName || 'Nome do usuário'}
                </S.UserNameText>
                <S.UserEmailText>
                  {userData?.dsGovbrEmail || 'Email do usuário'}
                </S.UserEmailText>
              </SkeletonText>
            </Stack>
          </S.TopContainer>
        </Flex>
        <Tabs
          items={tabs}
          initialTab={initialPath}
          onTabClick={handleTabClick}
        />
        <ProfileProvider
          value={{
            userData,
            isLoadingUserData,
            userValues,
            dataMyAccount,
            specialNeedsOptions,
            educationLevelsOptions,
            countries,
            states,
            cities,
            isLoadingCountries,
            isLoadingStates,
            isLoadingCities,
            setSelectedCountry,
            setSelectedState,
            setSelectedCity,
            isStateSelectDisabled,
            isCitySelectDisabled,
            isOpen,
            onOpen,
            onClose,
          }}
        >
          <Formik
            enableReinitialize
            initialValues={userValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Stack gap={4}>
                <Outlet />
                <Flex justify="flex-end">
                  <Button
                    type="submit"
                    leftIcon={<FaCheckCircle />}
                    size="sm"
                    isLoading={isUpdatingAccount}
                    isDisabled={isLoadingUserData}
                  >
                    Salvar alterações
                  </Button>
                </Flex>
              </Stack>
            </Form>
          </Formik>
        </ProfileProvider>
        <ModalProfile isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  )
}

export default Profile
