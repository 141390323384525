import http from '@/api/http'

import {
  TCreaTeamResponse,
  TCreateTeam,
  TDeleteTeamParams,
  TDeleteTeamResponse,
  TGetTeamResponse,
  TGetTeamsPaginatedParams,
  TGetTeamsPaginatedResponse,
  TGetTeamParams,
  TGetTeamsResponse,
  TUpdateTeamAnalystParams,
  TUpdateTeamAnalystResponse,
  TGetDeedDistributionParams,
  TGetTeamDeedDistributionResponse,
  TGetTeamObjectReportParams,
  TGetTeamObjectReportResponse,
} from './types'

export const getTeams = async () => {
  const response = await http.get<TGetTeamsResponse>('/team/')

  return response.data
}

export const getTeamsPaginated = async (filters: TGetTeamsPaginatedParams) => {
  const response = await http.get<TGetTeamsPaginatedResponse>('/team/', {
    params: filters,
  })

  return response.data
}

export const getTeam = async ({ coTeam }: TGetTeamParams) => {
  const response = await http.get<TGetTeamResponse>(`/team/${coTeam}/`)

  return response.data
}

export const postCreateTeam = async (team: TCreateTeam) => {
  const response = await http.post<TCreaTeamResponse>('/team/', team)

  return response.data
}

export const postDeleteTeam = async (
  params: TDeleteTeamParams
): Promise<TDeleteTeamResponse> => {
  const response = await http.delete<TDeleteTeamResponse>(
    '/team/delete_multiple/',
    { params: { co_team: params } }
  )
  return response.data
}

export const postUpdateTeamAnalyst = async (
  params: TUpdateTeamAnalystParams
) => {
  const response = await http.post<TUpdateTeamAnalystResponse>(
    '/team/add_analyst/',
    params
  )

  return response.data
}

export const getTeamDeedDistribution = async (
  params: TGetDeedDistributionParams
) => {
  const response = await http.get<TGetTeamDeedDistributionResponse>(
    '/team/team_deed_distribution/',
    {
      params,
    }
  )

  return response.data
}

export const getTeamObjectReport = async (
  params: TGetTeamObjectReportParams
) => {
  const response = await http.get<TGetTeamObjectReportResponse>(
    '/team/team_object_report/',
    {
      params,
    }
  )

  return response.data
}

export const getUserProfiles = async () => {
  const response = await http.get('/users/profile/')

  return response.data
}
