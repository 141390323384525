import React, { useEffect, useMemo, useRef } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { FaPlusCircle, FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Form, Formik, FormikProps, FormikValues } from 'formik'
import * as yup from 'yup'

import {
  Flex,
  Button,
  Text,
  Stack,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'

import { useMutationPostForm } from '@/api/forms/form/mutations'
import { useQueryFieldValues } from '@/api/forms/form/queries'
import { BasicSelect, Input } from '@/components/Forms'
import { useQuestionManagement } from '@/contexts/QuestionManagementContext'

import { ModalQuestion } from './AddQuestionModal'
import { EmptyQuestions } from './EmptyQuestion'
import { QuestionsList } from './QuestionsList'

const CreateForm = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { questions, resetQuestions, getUniqueQuestions } =
    useQuestionManagement()
  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onCloseModal,
  } = useDisclosure()

  const { data: fieldValues, isLoading: isFieldValuesLoading } =
    useQueryFieldValues()

  const { mutate: registerForm, isLoading: isRegisteringForm } =
    useMutationPostForm({
      onSuccess: () => {
        formikRef.current?.resetForm()
        toast({
          title: 'Formulário cadastrado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        navigate('/formularios/')
      },
    })

  const selectOptions = useMemo(() => {
    return {
      objects: (fieldValues?.objects || [])?.map(object => ({
        value: object.coObject,
        label: object.dsObject,
      })),
      stages: (fieldValues?.stages || [])?.map(stage => ({
        value: stage.coStage,
        label: stage.dsStage,
      })),
      formats: (fieldValues?.formats || [])?.map(format => ({
        value: format.coFormat,
        label: format.dsFormat,
      })),
      versions: (fieldValues?.versions || [])?.map(version => ({
        value: version,
        label: version,
      })),
      exemplaries: (fieldValues?.exemplaries || [])?.map(exemplary => ({
        value: exemplary.coExemplary,
        label: exemplary.dsExemplary,
      })),
    }
  }, [fieldValues])

  const handleAddQuestion = () => {
    onModalOpen()
  }

  const handleRegisterForm = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const handleSubmit = (values: FormikValues) => {
    const { questionsToAdd } = getUniqueQuestions()

    const payload = {
      dsForm: values.dsForm,
      coObject: Number(values.coObject),
      coPhase: Number(values.coPhase),
      coFormat: Number(values.coFormat),
      dsVolumeVersion: values.dsVolumeVersion,
      coExemplary: Number(values.coExemplary),
      coUser: 1,
      ncoFormQuestion: questionsToAdd,
    }

    registerForm(payload)
  }

  useEffect(() => {
    resetQuestions()
  }, [])

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Cadastrar novos formulários
          </Text>
        </Flex>
        <Formik
          innerRef={formikRef}
          initialValues={{
            dsForm: '',
            coObject: '',
            coPhase: '',
            coFormat: '',
            dsVolumeVersion: '',
            coExemplary: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            dsForm: yup.string().required('Nome do formulário é obrigatório'),
            coObject: yup.string().required('Objeto é obrigatório'),
            coPhase: yup.string().required('Fase é obrigatória'),
            coFormat: yup.string().required('Formato é obrigatório'),
            dsVolumeVersion: yup.string().required('Versão é obrigatória'),
            coExemplary: yup.string().required('Exemplar é obrigatório'),
          })}
        >
          <Form>
            <Input
              name="dsForm"
              label="Nome do formulário"
              placeholder="Digite o nome..."
            />
            <Flex gap={4}>
              <BasicSelect
                name="coObject"
                label="Objeto"
                placeholder="Selecione uma opção"
                options={selectOptions.objects}
                isLoading={isFieldValuesLoading}
              />
              <BasicSelect
                name="coPhase"
                label="Fase"
                placeholder="Selecione uma opção"
                options={selectOptions.stages}
                isLoading={isFieldValuesLoading}
              />
            </Flex>
            <Flex gap={4}>
              <BasicSelect
                name="coFormat"
                label="Formato do Volume"
                placeholder="Selecione uma opção"
                options={selectOptions.formats}
                isLoading={isFieldValuesLoading}
              />
              <BasicSelect
                name="dsVolumeVersion"
                label="Versao do Volume"
                placeholder="Selecione uma opção"
                options={selectOptions.versions}
                isLoading={isFieldValuesLoading}
              />
              <BasicSelect
                name="coExemplary"
                label="Exemplar do Volume"
                placeholder="Selecione uma opção"
                options={selectOptions.exemplaries}
                isLoading={isFieldValuesLoading}
              />
            </Flex>
          </Form>
        </Formik>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Questões
          </Text>
        </Flex>
        {questions.length === 0 ? <EmptyQuestions /> : <QuestionsList />}
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          padding="5"
          gap="8"
        >
          <Button
            size="sm"
            leftIcon={<BsChevronLeft />}
            variant="ghost"
            fontSize="sm"
            color="brand.primary.dark_1"
            isDisabled={isRegisteringForm}
            onClick={() => navigate('/formularios/')}
          >
            Voltar
          </Button>
          <Flex gap={2}>
            <Button
              variant="outline"
              leftIcon={<FaPlusCircle />}
              isDisabled={isRegisteringForm}
              onClick={handleAddQuestion}
            >
              Adicionar Questão
            </Button>
            <Button
              leftIcon={<FaSave />}
              isLoading={isRegisteringForm}
              onClick={handleRegisterForm}
            >
              Cadastrar Formulário
            </Button>
          </Flex>
        </Flex>
      </Stack>
      <ModalQuestion isOpen={isModalOpen} onClose={onCloseModal} />
    </>
  )
}

export default CreateForm
