import React from 'react'

import { useFormikContext } from 'formik'

import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Stack,
  Text,
  FormControl,
  IconButton,
  Tooltip,
  Divider,
} from '@chakra-ui/react'

import { Input } from '@/components/Forms'

import AnswerTypeFields from './AnswerTypeFields'
import NestedFieldSelect from './NestedFieldSelect'
import { ISelectOptions, IRegisterQuestionFormValues } from './types'

interface AlternativeQuestionProps {
  index: number
  selectOptions: ISelectOptions
  onRemove: () => void
  readOnly?: boolean
}

const AlternativeQuestion: React.FC<AlternativeQuestionProps> = ({
  index,
  selectOptions,
  onRemove,
  readOnly = false,
}) => {
  const { values, errors, touched } =
    useFormikContext<IRegisterQuestionFormValues>()

  const fieldPath = (field: string) => `alternativeQuestions[${index}].${field}`
  const answerType = values.alternativeQuestions?.[index]?.answerModel

  const hasError = (field: string) => {
    const fieldError =
      errors?.alternativeQuestions?.[index]?.[
        field as keyof (typeof errors.alternativeQuestions)[0]
      ]
    const fieldTouched =
      touched?.alternativeQuestions?.[index]?.[
        field as keyof (typeof touched.alternativeQuestions)[0]
      ]
    return !!fieldError && !!fieldTouched
  }

  return (
    <Box
      bg="brand.primary.light_2"
      p={4}
      borderRadius="md"
      mb={4}
      position="relative"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <Text color="brand.primary.dark_1" fontWeight="bold">
          Questão alternativa {index + 1}
        </Text>
        {!readOnly && (
          <Tooltip label="Remover questão">
            <IconButton
              aria-label="Remover questão"
              icon={<DeleteIcon />}
              size="sm"
              colorScheme="red"
              onClick={onRemove}
              variant="ghost"
            />
          </Tooltip>
        )}
      </Flex>

      <Stack gap={4}>
        <FormControl isInvalid={hasError('title')}>
          <Input
            name={fieldPath('title')}
            label="Título da questão"
            placeholder="---"
            isDisabled={readOnly}
          />
        </FormControl>

        <Flex gap="4" flexDirection={{ base: 'column', md: 'row', xl: 'row' }}>
          <FormControl isInvalid={hasError('formatVolume')}>
            <NestedFieldSelect
              name={fieldPath('formatVolume')}
              options={selectOptions.formats}
              label="Formato do volume"
              placeholder="---"
              isDisabled={readOnly}
            />
          </FormControl>

          <FormControl isInvalid={hasError('versionVolume')}>
            <NestedFieldSelect
              name={fieldPath('versionVolume')}
              options={selectOptions.volumeVersion}
              label="Versão do volume"
              placeholder="---"
              isDisabled={readOnly}
            />
          </FormControl>

          <FormControl isInvalid={hasError('exemplaryVolume')}>
            <NestedFieldSelect
              name={fieldPath('exemplaryVolume')}
              options={selectOptions.exemplaries}
              label="Exemplar do volume"
              placeholder="---"
              isDisabled={readOnly}
            />
          </FormControl>
        </Flex>

        <Flex gap="4" flexDirection={{ base: 'column', md: 'row', xl: 'row' }}>
          <NestedFieldSelect
            name={fieldPath('diligence')}
            options={selectOptions.failureTypes}
            label="Código da falha"
            placeholder="---"
            isDisabled={true}
          />

          <FormControl isInvalid={hasError('answerModel')}>
            <NestedFieldSelect
              name={fieldPath('answerModel')}
              options={selectOptions.answerModels}
              label="Tipo de resposta"
              placeholder="---"
              isDisabled={readOnly}
            />
          </FormControl>

          <FormControl isInvalid={hasError('showPages')}>
            <NestedFieldSelect
              name={fieldPath('showPages')}
              options={selectOptions.showPages}
              label="Informar as páginas?"
              placeholder="---"
              isDisabled={readOnly}
            />
          </FormControl>
        </Flex>

        <FormControl isInvalid={hasError('description')}>
          <Input
            name={fieldPath('description')}
            type="textarea"
            label="Descrição"
            placeholder="Descrição"
            resize="none"
            helperText="Descreva aqui uma ajuda para o analista responder a questão"
            isDisabled={readOnly}
          />
        </FormControl>
      </Stack>

      {answerType && (
        <Box>
          <Divider my={4} borderColor="gray.300" />
          <AnswerTypeFields
            answerType={answerType}
            fieldPrefix={`alternativeQuestions[${index}].`}
            isAlternativeQuestion={true}
            hasAlternatives={false}
            readOnly={readOnly}
          />
        </Box>
      )}
    </Box>
  )
}

export default AlternativeQuestion
