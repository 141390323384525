import React, { useMemo, useState } from 'react'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { FaCalendarTimes, FaCalendarCheck, FaPlusCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import { SearchIcon, EditIcon } from '@chakra-ui/icons'
import {
  Flex,
  Button,
  Text,
  IconButton,
  useDisclosure,
  useToast,
  VStack,
  Box,
} from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import { useMutationUnlinkUsers } from '@/api/user/management/mutations'
import {
  useQueryProfileNames,
  useQueryUsersPaginated,
} from '@/api/user/management/queries'
import DataFilter from '@/components/DataFilter'
import { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tabs from '@/components/Tabs'
import { ITabInfo } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import { formatDate, reverseDate } from '@/utils/date'
import { getErrorDetails } from '@/utils/error'

import BlockedUsersModal from './parts/BlockedUsersModal'
import PreRegistrationModal from './parts/PreRegistrationModal'
import RelinkModal from './parts/RelinkModal'
import S from './parts/styles'
import { IUser, IUserStatusMapping } from './types'

const User: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const initialPath = location.pathname.split('/').pop()

  const {
    isOpen: isRelinkOpen,
    onOpen: onRelinkOpen,
    onClose: onRelinkClose,
  } = useDisclosure()

  const {
    isOpen: isUsersBlockedOpen,
    onOpen: onUsersBlockedOpen,
    onClose: onUsersBlockedClose,
  } = useDisclosure()

  const {
    isOpen: isUnlinkOpen,
    onOpen: onUnlinkOpen,
    onClose: onUnlinkClose,
  } = useDisclosure()

  const {
    isOpen: isRegistrationOpen,
    onOpen: onRegistrationOpen,
    onClose: onRegistrationClose,
  } = useDisclosure()

  const [selectedUsers, setSelectedUsers] = useState<Array<RowData>>([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })
  const [filter, setFilter] = useState({
    search: '',
    startDate: '',
    endDate: '',
    status: '',
    cpf: '',
    email: '',
  })
  const {
    data: usersData = {
      pagination: {
        count: 0,
        totalPages: 1,
        currentPage: 1,
        pageSize: 10,
      },
      results: [],
    },
    isFetching: isFetchingUsersData,
    refetch: refetchUsersData,
  } = useQueryUsersPaginated(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
      noUserName: filter.search,
      initialDate: filter.startDate,
      finalDate: filter.endDate,
      dsStatus: filter.status,
      dsCpf: filter.cpf,
      dsGovbrEmail: filter.email,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage || 1,
          pageSize: data.pagination.pageSize || 10,
          itemsCount: data.pagination.count || 0,
          pageCount: data.pagination.totalPages || 1,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao buscar usuários',
          description: 'Tente novamente mais tarde',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const { mutate: unlinkUsers, isLoading: isUnlinkLoading } =
    useMutationUnlinkUsers({
      onSuccess: () => {
        toast({
          title: 'Vínculo encerrado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onUnlinkClose()
        refetchUsersData()
      },
      onError: error => {
        toast({
          title: 'Erro ao encerrar vínculo',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const usersDataTable = useMemo(() => {
    const users = usersData?.results || []

    const mappedUsers = users.map(user => {
      const userInfo = (name: string, cpf: string, email: string) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="gray.700" textAlign="start">
              {name}
            </Text>
          </Box>
          <Flex>
            <Text fontSize="xs" color="gray.700">
              {cpf} | {email}
            </Text>
          </Flex>
        </VStack>
      )

      const editButton = (id: string) => {
        const handleEdit = () => {
          navigate(`/configuracoes/usuario/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            height="unset"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      const statusTagValue = IUserStatusMapping[user.dsStatus]

      return {
        user: userInfo(user.noUserName, user.dsCpf, user.dsGovbrEmail),
        coUser: user.coUser,
        bondEnd: formatDate(user.dtRenewal || '') || '-',
        accessLevel: user.profile?.[0]?.name || '-',
        status: <Tag group="statusUserSystem" value={statusTagValue} />,
        edit: editButton(user.coUser as string),
        statusCode: user.dsStatus,
        name: user.noUserName,
        cpf: user.dsCpf,
        email: user.dsGovbrEmail,
      }
    })

    return mappedUsers
  }, [usersData])

  const filters: Array<IFilter> = [
    {
      name: 'cpf',
      label: 'CPF',
      type: 'text',
      icon: <SearchIcon />,
    },
    {
      name: 'email',
      label: 'E-mail',
      type: 'text',
      icon: <SearchIcon />,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          value: 'ATIVO',
          label: 'Ativo',
        },
        {
          value: 'INDISPONIVEL',
          label: 'Indisponível',
        },
        {
          value: 'DISPONIVEL',
          label: 'Disponível',
        },
        {
          value: 'PRE_CADASTRO',
          label: 'Pré-cadastro',
        },
      ],
    },
  ]

  const mainTableHeaders: Array<IHeader> = [
    {
      name: 'USUÁRIO',
      key: 'user',
      type: 'element',
      width: '50%',
    },
    {
      name: 'FIM DO VÍNCULO',
      key: 'bondEnd',
      align: 'center',
      width: '15%',
    },
    {
      name: 'PERMISSÃO',
      key: 'accessLevel',
      align: 'center',
      width: '15%',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '15%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  const blockedUsersTableData = useMemo(() => {
    const activeUsers = selectedUsers.filter(
      user => (user as IUser).statusCode === 'Ativo'
    )

    return activeUsers
  }, [selectedUsers])

  const tabs = [
    {
      id: 'usuario',
      label: 'Usuário',
    },
    {
      id: 'equipe',
      label: 'Equipe',
    },
    // {
    //   id: 'permissoes',
    //   label: 'Permissões',
    // },
  ]
  const handleTabClick = (id: string) => {
    navigate(`/configuracoes/${id}`)
  }

  const hasSelectedUsers = () => {
    if (!selectedUsers.length) {
      toast({
        title: 'Nenhum usuário selecionado',
        description: 'Selecione pelo menos um usuário',
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
      return false
    }

    return true
  }

  const handleCancelLink = () => {
    const usersNonBlocked = selectedUsers.filter(
      user => (user as IUser).statusCode !== 'Ativo'
    )

    if (!usersNonBlocked.length) {
      toast({
        title: 'Nenhum usuário válido selecionado',
        description: 'Selecione pelo menos um usuário que não esteja ativo',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })

      onUnlinkClose()

      return
    }

    const usersUnlink = usersNonBlocked.map(
      user => (user as { coUser: number }).coUser
    )

    const payload = {
      coUser: usersUnlink,
    }
    unlinkUsers(payload)
  }

  const handleUnlinkUsers = () => {
    if (!blockedUsersTableData.length) {
      onUnlinkOpen()
      return
    }

    onUsersBlockedOpen()
  }

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      startDate: reverseDate(filters.startDate) || '',
      endDate: reverseDate(filters.endDate) || '',
      status: filters.status || '',
      cpf: filters.cpf || '',
      email: filters.email || '',
    })
  }

  return (
    <>
      <Tabs
        items={tabs}
        initialTab={initialPath}
        onTabClick={({ id }: ITabInfo) => {
          handleTabClick(id)
        }}
      />
      <Flex direction="column" gap="6">
        <Box p={4} boxShadow="md">
          <DataFilter filters={filters} onChangeFilter={handleFilters} />
        </Box>
        <Flex
          direction="column"
          p="5"
          gap="4"
          borderRadius="0.3125rem"
          boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <Flex
            justify="space-between"
            direction={{ base: 'column', lg: 'row' }}
            gap={2}
          >
            <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
              Configurações de usuários
            </Text>
            <Flex gap={2} direction={{ base: 'column', lg: 'row' }}>
              <Button
                size="sm"
                leftIcon={<FaCalendarTimes />}
                fontSize="sm"
                variant="error"
                onClick={() => {
                  if (hasSelectedUsers()) {
                    handleUnlinkUsers()
                  }
                }}
              >
                Desvincular usuários
              </Button>
              <Button
                size="sm"
                variant="success"
                leftIcon={<FaCalendarCheck />}
                fontSize="sm"
                onClick={() => {
                  if (hasSelectedUsers()) {
                    onRelinkOpen()
                  }
                }}
              >
                Vincular usuários
              </Button>
              <Button
                size="sm"
                leftIcon={<FaPlusCircle />}
                fontSize="sm"
                onClick={onRegistrationOpen}
              >
                Pré-cadastrar um novo usuário
              </Button>
            </Flex>
          </Flex>
          <DataTable
            rowId="coUser"
            headers={mainTableHeaders}
            data={usersDataTable}
            selectableRow
            onRowSelectionChange={rows => setSelectedUsers(rows)}
            isLoading={isFetchingUsersData}
          />
          <Pagination
            pageIndex={pagination.page - 1}
            optionsItemsPerPage={pagination.pageSizeOptions}
            itemsPerPage={pagination.pageSize}
            pageCount={pagination.pageCount}
            itemsCount={pagination.itemsCount}
            onChangePageInfo={handleOnChangePageInfo}
          />
        </Flex>
        <RelinkModal
          isOpen={isRelinkOpen}
          onClose={onRelinkClose}
          users={selectedUsers}
          refetchUsers={refetchUsersData}
        />
        <BlockedUsersModal
          isOpen={isUsersBlockedOpen}
          onClose={onUsersBlockedClose}
          users={blockedUsersTableData}
          onConfirm={() => {
            onUsersBlockedClose()
            onUnlinkOpen()
          }}
        />
        <Modal
          key="modal-desvincular-em-lote"
          title="Desvincular usuário em lote"
          isOpen={isUnlinkOpen}
          onClose={onUnlinkClose}
          isCentered
          size="xl"
        >
          <Text fontSize="xs">
            Quando encerrarmos a vinculação de um usuário, ele não terá mais
            acesso aos objetos associados e não poderá criar novas. No entanto,
            o perfil e o acesso às informações sobre as atividades desses
            usuários permanecerão disponíveis para consulta. Podendo ser
            novamente caso um novo contrato seja estabelecido. <br />
            <br /> Você tem certeza de que deseja encerrar este vínculo?
          </Text>
          <S.ModalActionButtons>
            <Button
              key="go-back"
              size="sm"
              leftIcon={<BsXCircle />}
              color="white"
              bg="brand.error.base"
              _hover={{
                bg: 'brand.error.dark',
              }}
              isLoading={isUnlinkLoading}
              onClick={handleCancelLink}
            >
              Sim, quero encerrar o vínculo
            </Button>
            <Button
              key="confirm"
              size="sm"
              leftIcon={<BsCheckCircle />}
              color="white"
              bg="brand.primary.dark_1"
              _hover={{
                bg: 'brand.primary.dark_2',
              }}
              isDisabled={isUnlinkLoading}
              onClick={onUnlinkClose}
            >
              Não, mantenha o vínculo
            </Button>
          </S.ModalActionButtons>
        </Modal>
        <PreRegistrationModal
          isOpen={isRegistrationOpen}
          onClose={onRegistrationClose}
          refetchUsers={refetchUsersData}
        />
      </Flex>
    </>
  )
}

export default User
