import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import {
  FaCheckCircle,
  FaPlusCircle,
  FaSave,
  FaTimesCircle,
} from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Form, Formik, FormikProps, FormikValues } from 'formik'
import * as yup from 'yup'

import {
  Flex,
  Button,
  Text,
  Stack,
  useToast,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react'

import {
  useMutationPatchForm,
  useMutationAddQuestions,
  useMutationRemoveQuestions,
  useMutationActivateForm,
} from '@/api/forms/form/mutations'
import { useQueryFieldValues, useQueryForm } from '@/api/forms/form/queries'
import { BasicSelect, Input } from '@/components/Forms'
import Switch from '@/components/Switch'
import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import { status } from '@/components/Tag/StatusChecklist/types'
import { useQuestionManagement } from '@/contexts/QuestionManagementContext'
import { getErrorDetails } from '@/utils/error'

import { ModalQuestion } from './AddQuestionModal'
import { ModalApproveForm } from './ApproveFormModal'
import { ModalDeleteForm } from './DeleteFormModal'
import { QuestionsList } from './QuestionsList'
import Versions from './Versions'

const ChecklistDetails = () => {
  const { coForm } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { setQuestions, resetQuestions, getUniqueQuestions } =
    useQuestionManagement()
  const [status, setStatus] = useState<status>('default')
  const [approved, setApproved] = useState(false)
  const [linked, setLinked] = useState(false)
  const formikRef = useRef<FormikProps<FormikValues>>(null)
  const {
    isOpen: isModalQuestionOpen,
    onOpen: onModalQuestionOpen,
    onClose: onCloseQuestionModal,
  } = useDisclosure()
  const {
    isOpen: isModalDeleteOpen,
    onOpen: onModalDeleteOpen,
    onClose: onCloseDeleteModal,
  } = useDisclosure()
  const {
    isOpen: isModalApproveOpen,
    onOpen: onModalApproveOpen,
    onClose: onCloseApproveModal,
  } = useDisclosure()

  const {
    data: formData,
    isFetching: isFormLoading,
    refetch: refetchFormData,
  } = useQueryForm(
    { coForm: Number(coForm) },
    {
      onSuccess: data => {
        setStatus((data?.stLink as status) || 'default')
        setLinked(data?.stFormActive || false)
        setApproved(Boolean(data?.coUserApproval))
      },
    }
  )
  const { data: fieldValues, isLoading: isFieldValuesLoading } =
    useQueryFieldValues()
  const { mutate: updateForm, isLoading: isUpdatingForm } =
    useMutationPatchForm({
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
      onSuccess: () => {
        refetchFormData()
        toast({
          title: 'Formulário atualizado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  const { mutate: activateForm, isLoading: isActivingForm } =
    useMutationActivateForm({
      onSuccess: () => {
        toast({
          title: `Formulário ${linked ? 'ativado' : 'desativado'} com sucesso!`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })
  const { mutate: addQuestions, isLoading: isAddingQuestions } =
    useMutationAddQuestions({
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro ao adicionar questões.',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })
  const { mutate: removeQuestions, isLoading: isRemovingQuestions } =
    useMutationRemoveQuestions({
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro ao remover questões.',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const messages = [
    { condition: isAddingQuestions, text: 'Adicionando questões...' },
    { condition: isRemovingQuestions, text: 'Removendo questões...' },
    { condition: isUpdatingForm, text: 'Atualizando questões...' },
  ]

  const loadingMessage = messages.find(m => m.condition)?.text || ''

  const detailsTabs: Array<ITabItem> = [
    {
      id: 'questions',
      label: 'Questões',
      render: () => <QuestionsList isLoading={isFormLoading} />,
    },
    {
      id: 'version',
      label: 'Versões',
      render: () => <Versions />,
    },
  ]

  const selectOptions = useMemo(() => {
    return {
      objects: (fieldValues?.objects || [])?.map(object => ({
        value: object.coObject,
        label: object.dsObject,
      })),
      stages: (fieldValues?.stages || [])?.map(stage => ({
        value: stage.coStage,
        label: stage.dsStage,
      })),
      formats: (fieldValues?.formats || [])?.map(format => ({
        value: format.coFormat,
        label: format.dsFormat,
      })),
      versions: (fieldValues?.versions || [])?.map(version => ({
        value: version,
        label: version,
      })),
      exemplaries: (fieldValues?.exemplaries || [])?.map(exemplary => ({
        value: exemplary.coExemplary,
        label: exemplary.dsExemplary,
      })),
    }
  }, [fieldValues])

  const handleAddQuestion = () => {
    onModalQuestionOpen()
  }
  const handleDeleteForm = () => {
    onModalDeleteOpen()
  }
  const handleApproveForm = () => {
    onModalApproveOpen()
  }
  const handleApproved = () => {
    setApproved(true)
  }
  const handleActivateForm = (isChecked: boolean) => {
    setLinked(isChecked)
    activateForm({
      coForm: Number(coForm),
      stFormActive: isChecked,
    })
  }

  const handleUpdateForm = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const handleSubmit = (values: FormikValues) => {
    const { questionsToAdd, questionsToRemove } = getUniqueQuestions()

    if (questionsToAdd.length > 0) {
      addQuestions({
        coForm: Number(coForm),
        ncoFormQuestion: questionsToAdd,
      })
    }

    if (questionsToRemove.length > 0) {
      removeQuestions({
        coForm: Number(coForm),
        ncoFormQuestion: questionsToRemove,
      })
    }

    const payload = {
      coForm: Number(coForm),
      dsForm: values.dsForm,
      coObject: Number(values.coObject),
      coPhase: Number(values.coStage),
      coFormat: Number(values.coFormat),
      dsVolumeVersion: values.dsVolumeVersion,
      coExemplary: Number(values.coExemplary),
    }
    updateForm(payload)
  }

  useEffect(() => {
    const formattedQuestions = (formData?.questions || []).map(item => {
      const { question } = item
      return {
        id: question.coFormQuestion,
        name: question.dsQuestion,
        type: question.answerType.dsAnswerType,
        dsStatus: question.stActive ? 'active' : 'inactive',
      }
    })
    setQuestions(formattedQuestions)
  }, [formData])

  useEffect(() => {
    resetQuestions()
  }, [])

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex direction="row" justifyContent="space-between">
          <Flex justify="space-between" direction="column" gap={4}>
            <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
              {formData?.dsForm || 'Formulário'}
            </Text>
            <Skeleton isLoaded={!isFormLoading}>
              <Tag group="statusChecklist" value={status} />
            </Skeleton>
          </Flex>
          <Flex direction="row" alignItems="center" gap={8}>
            <Switch
              value={linked}
              label="Ativar formulário"
              isLoading={isActivingForm}
              disabled={isActivingForm}
              onChange={handleActivateForm}
            />
            <Button
              size="sm"
              variant={!approved ? 'success' : 'disabled'}
              disabled={approved}
              leftIcon={<FaCheckCircle />}
              onClick={handleApproveForm}
              alignItems="center"
            >
              Aprovar versão
            </Button>
          </Flex>
        </Flex>
        <Formik
          innerRef={formikRef}
          initialValues={{
            ...formData,
            coStage: formData?.coPhase?.coStage,
          }}
          enableReinitialize
          validationSchema={yup.object().shape({
            dsForm: yup.string().required('Nome do formulário é obrigatório'),
            coObject: yup.string().required('Objeto é obrigatório'),
            coStage: yup.string().required('Fase é obrigatória'),
            coFormat: yup.string().required('Formato é obrigatório'),
            dsVolumeVersion: yup.string().required('Versão é obrigatória'),
            coExemplary: yup.string().required('Exemplar é obrigatório'),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <Input
              name="dsForm"
              label="Nome do formulário"
              placeholder="Digite o nome..."
              isLoading={isFieldValuesLoading || isFormLoading}
            />
            <Flex gap={4}>
              <BasicSelect
                name="coObject"
                label="Objeto"
                placeholder="Selecione uma opção"
                options={selectOptions.objects}
                isLoading={isFieldValuesLoading || isFormLoading}
              />
              <BasicSelect
                name="coStage"
                label="Fase"
                placeholder="Selecione uma opção"
                options={selectOptions.stages}
                isLoading={isFieldValuesLoading || isFormLoading}
              />
            </Flex>
            <Flex gap={4}>
              <BasicSelect
                name="coFormat"
                label="Formato do Volume"
                placeholder="Selecione uma opção"
                options={selectOptions.formats}
                isLoading={isFieldValuesLoading || isFormLoading}
              />
              <BasicSelect
                name="dsVolumeVersion"
                label="Versao do Volume"
                placeholder="Selecione uma opção"
                options={selectOptions.versions}
                isLoading={isFieldValuesLoading || isFormLoading}
              />
              <BasicSelect
                name="coExemplary"
                label="Exemplar do Volume"
                placeholder="Selecione uma opção"
                options={selectOptions.exemplaries}
                isLoading={isFieldValuesLoading || isFormLoading}
              />
            </Flex>
          </Form>
        </Formik>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Tabs variant="rounded" items={detailsTabs} initialTab="principal" />
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          padding="5"
          gap="8"
        >
          <Button
            size="sm"
            leftIcon={<BsChevronLeft />}
            variant="ghost"
            fontSize="sm"
            color="brand.primary.dark_1"
            onClick={() => navigate('/formularios/')}
          >
            Voltar
          </Button>
          <Flex gap={2}>
            <Button
              variant="error"
              leftIcon={<FaTimesCircle />}
              onClick={handleDeleteForm}
              isDisabled={isUpdatingForm}
            >
              Excluir formulário
            </Button>
            <Button
              variant="outline"
              leftIcon={<FaPlusCircle />}
              isDisabled={isUpdatingForm}
              onClick={handleAddQuestion}
            >
              Adicionar Questão
            </Button>
            <Button
              leftIcon={<FaSave />}
              variant="solid"
              isLoading={
                isAddingQuestions || isRemovingQuestions || isUpdatingForm
              }
              loadingText={loadingMessage}
              onClick={handleUpdateForm}
            >
              Salvar alterações
            </Button>
          </Flex>
        </Flex>
      </Stack>
      <ModalQuestion
        coForm={Number(coForm)}
        isOpen={isModalQuestionOpen}
        onClose={onCloseQuestionModal}
        onSuccess={refetchFormData}
      />
      <ModalDeleteForm
        coForm={Number(coForm)}
        isOpen={isModalDeleteOpen}
        onClose={onCloseDeleteModal}
        onSuccess={() => navigate('/formularios/')}
      />
      <ModalApproveForm
        coForm={Number(coForm)}
        isOpen={isModalApproveOpen}
        onClose={onCloseApproveModal}
      />
    </>
  )
}

export default ChecklistDetails
