import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  IconButton,
  VStack,
  Text,
  Stack,
  useToast,
} from '@chakra-ui/react'

import {
  useQueryTeamDeedDistribution,
  useQueryTeamObjectReport,
} from '@/api/teams/queries'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusVolumeTeam'

import { ITeamVolumesStatusMapping } from '../../types'

const volumeVolumeTable = ({
  coTeam,
  coObject,
}: {
  coTeam: number | undefined
  coObject: number | undefined
}) => {
  const navigate = useNavigate()
  const toast = useToast()

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1
    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const volumesFilter: Array<IFilter> = [
    {
      name: 'filter1',
      label: 'Status',
      type: 'select',
      options: [
        { label: 'Ativo', value: 'ativo' },
        { label: 'Inativo', value: 'Inativo' },
      ],
    },
  ]

  const volumeTableHeaders: Array<IHeader> = [
    {
      name: 'Volume',
      key: 'volume',
      type: 'element',
      width: '40%',
    },
    {
      name: 'Analista 1',
      key: 'analyst1',
      align: 'center',
      width: '20%',
    },
    {
      name: 'Analista 2',
      key: 'analyst2',
      align: 'center',
      width: '20%',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '20%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  const {
    data: deedDistribution,
    isLoading: isLoadingDeedData,
    isError,
    isFetching: isFetchingTeam,
    refetch: refetchTeamData,
  } = useQueryTeamDeedDistribution(
    { coTeam: coTeam as unknown as number },
    {
      enabled: !!coTeam,
      onError: () => {
        toast({
          title: 'Erro ao buscar dados da distribuição das  obras',
          description: 'Não foi possível obter distribuição das obras.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )
  const coDeed = deedDistribution
    ? (deedDistribution.results[0].coDeed as number)
    : undefined

  const {
    data: reportData = {
      pagination: {
        count: 0,
        totalPages: 1,
        currentPage: 1,
        pageSize: 10,
      },
      results: [],
    },
    isLoading: loadingReportObject,
    isFetching: isFetchingReport,
    error: errorReport,
  } = useQueryTeamObjectReport(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
      deedDsCode: coDeed as number,
      objectCode: coObject as number,
    },
    {
      enabled: !!coDeed,
      onError: () => {
        toast({
          title: 'Erro ao buscar dados do volume',
          description: 'Não foi possível obter os dados dos volumes',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const volumesDataTable = reportData
    ? useMemo(() => {
        const volumes =
          reportData.results?.flatMap(
            reportObject => reportObject.ncoVolumes || []
          ) || []

        const mappedVolumes = volumes.map(volume => {
          const volumeInfo = (
            name: string,
            editora: string,
            colecao: string,
            versao: string
          ) => (
            <VStack justify="flex-start">
              <Box w="full">
                <Text
                  fontSize="sm"
                  color="brand.neutral.dark_2"
                  textAlign="start"
                >
                  {name}
                </Text>
                <Text
                  fontSize="sm"
                  color="brand.neutral.dark_2"
                  textAlign="start"
                >
                  {editora} | {colecao} | {versao}
                </Text>
              </Box>
            </VStack>
          )

          const editButton = (id: number) => {
            const handleEdit = () => {
              // TODO: navigate(`/configuracoes/equipes/detalhes/${id}`)
            }

            return (
              <IconButton
                aria-label="Editar"
                variant="ghost"
                size="sm"
                height="unset"
                icon={<EditIcon />}
                color="brand.primary.dark_1"
                onClick={() => handleEdit()}
              />
            )
          }

          const statusTagValue =
            ITeamVolumesStatusMapping[reportData.results[0].noStageObject]
          return {
            volume: volumeInfo(
              volume.noVolume,
              volume.noPublisher,
              volume.coCollection,
              volume.version
            ),
            analyst1: volume.noAnalyst1?.noUserName ?? 'N/A',
            analyst2: volume.noAnalyst2?.noUserName ?? 'N/A',
            status: StatusTag({ tag: statusTagValue }),
            edit: editButton(volume.volume),
          }
        })
        return mappedVolumes
      }, [reportData])
    : []

  return (
    <>
      <Stack gap={4}>
        <DataFilter
          onlySearch
          filters={volumesFilter}
          onChangeFilter={() => {}}
        />
        <DataTable
          rowId="teamsVolumesTableId"
          headers={volumeTableHeaders}
          data={volumesDataTable}
          isLoading={loadingReportObject || isFetchingReport}
        />
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Stack>
    </>
  )
}
export default volumeVolumeTable
