import React from 'react'
import { useParams } from 'react-router-dom'

import { useFormikContext } from 'formik'

import { Box, Text } from '@chakra-ui/react'

import { useQuestionDetailsController } from '@/pages/Checklists/QuestionDetails/controller'
import { TQuestionDetailsFormValues } from '@/pages/Checklists/QuestionDetails/types'
import AnswerTypeFields from '@/pages/Checklists/Questions/parts/RegisterQuestionModal/AnswerTypeFields'

const AnswersTab: React.FC = () => {
  const { coQuestion } = useParams()
  const { questionData } = useQuestionDetailsController(coQuestion as string)
  const { values } = useFormikContext<TQuestionDetailsFormValues>()

  const answerType =
    values.answerModel || questionData?.answerType?.tpAnswer?.toString() || ''
  const isFormConnected = (questionData?.forms?.length ?? 0) > 0

  return (
    <Box>
      {!answerType ? (
        <Text color="gray.500">
          Selecione um tipo de resposta para ver as opções disponíveis.
        </Text>
      ) : (
        <Box>
          <AnswerTypeFields
            answerType={answerType}
            isAlternativeQuestion={false}
            readOnly={isFormConnected}
          />
        </Box>
      )}
    </Box>
  )
}

export default AnswersTab
