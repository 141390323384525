import React from 'react'

import AccessLevelTag from '@/components/Tag/AccessLevel'
import { accessLevel } from '@/components/Tag/AccessLevel/types'
import { status as statusAnalysisVolume } from '@/components/Tag/StatusAnalysisVolume/types'
import StatusAnalystTeamTag from '@/components/Tag/StatusAnalystTeam'
import { status as statusAnalystTeam } from '@/components/Tag/StatusAnalystTeam/types'
import StatusCardTeamTag from '@/components/Tag/StatusCardTeam'
import { status as statusCardTeam } from '@/components/Tag/StatusCardTeam/types'
import StatusChecklistTag from '@/components/Tag/StatusChecklist'
import { status as statusChecklist } from '@/components/Tag/StatusChecklist/types'
import StatusObjectTag from '@/components/Tag/StatusObject'
import { statusObject } from '@/components/Tag/StatusObject/types'
import StatusQuestionsTag from '@/components/Tag/StatusQuestions'
import { status as statusQuestions } from '@/components/Tag/StatusQuestions/types'
import StatusTeamSystemTag from '@/components/Tag/StatusTeamSystem'
import { status as statusTeamSystem } from '@/components/Tag/StatusTeamSystem/types'
import StatusUserSystemTag from '@/components/Tag/StatusUserSystem'
import { status as statusSystem } from '@/components/Tag/StatusUserSystem/types'
import StatusUserTeamTag from '@/components/Tag/StatusUserTeam'
import StatusVolumeTeamTag from '@/components/Tag/StatusUserTeam'
import { status as statusTeam } from '@/components/Tag/StatusUserTeam/types'
import { status as statusVolumeTeam } from '@/components/Tag/StatusUserTeam/types'

import StatusAnalysisVolume from './StatusAnalysisVolume'
import { ITagProps } from './types'

const Tag = ({ group, value }: ITagProps) => {
  const tags = {
    accessLevel: <AccessLevelTag tag={value as accessLevel} />,
    statusCardTeam: <StatusCardTeamTag tag={value as statusCardTeam} />,
    statusTeamSystem: <StatusTeamSystemTag tag={value as statusTeamSystem} />,
    statusAnalystTeam: (
      <StatusAnalystTeamTag tag={value as statusAnalystTeam} />
    ),
    statusUserSystem: <StatusUserSystemTag tag={value as statusSystem} />,
    statusUserTeam: <StatusUserTeamTag tag={value as statusTeam} />,
    statusObjects: <StatusObjectTag tag={value as statusObject} />,
    statusVolumes: <StatusVolumeTeamTag tag={value as statusVolumeTeam} />,
    statusQuestions: <StatusQuestionsTag tag={value as statusQuestions} />,
    statusChecklist: <StatusChecklistTag tag={value as statusChecklist} />,
    statusAnalysisVolume: (
      <StatusAnalysisVolume tag={value as statusAnalysisVolume} />
    ),
  }
  return tags[group]
}

export default Tag
