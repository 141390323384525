import {
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  getTeam,
  getTeamDeedDistribution,
  getTeamObjectReport,
  getTeams,
  getTeamsPaginated,
  getUserProfiles,
} from './services'
import {
  TGetTeamsPaginatedParams,
  TGetTeamsPaginatedResponse,
  TGetTeamsResponse,
  TGetTeamParams,
  TGetTeamResponse,
  TGetDeedDistributionParams,
  TGetTeamDeedDistributionResponse,
  TGetTeamObjectReportParams,
  TGetTeamObjectReportResponse,
  TGetUserProfilesResponse,
} from './types'

export const useQueryTeams = (options?: UseQueryOptions<TGetTeamsResponse>) => {
  const queryFunction = () => getTeams()

  return useQuery<TGetTeamsResponse>(
    useQueryTeams.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryTeams.getFullQueryKey = (): QueryKey => ['teams']

useQueryTeams.queryKey = ['teams']

export const useQueryTeamsPaginated = (
  input: TGetTeamsPaginatedParams,
  options?: UseQueryOptions<TGetTeamsPaginatedResponse>
) => {
  const queryFunction = () => getTeamsPaginated(input)

  return useQuery<TGetTeamsPaginatedResponse>(
    useQueryTeamsPaginated.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryTeamsPaginated.getFullQueryKey = (
  input: TGetTeamsPaginatedParams
): QueryKey => ['teams_paginated', input]

useQueryTeamsPaginated.queryKey = (
  input: TGetTeamsPaginatedResponse
): QueryKey => ['teams_paginated', input]

export const useQueryTeam = (
  input: TGetTeamParams,
  options?: UseQueryOptions<TGetTeamResponse>
) => {
  const queryFunction = () => getTeam(input)

  return useQuery<TGetTeamResponse>(
    useQueryTeam.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryTeam.getFullQueryKey = (): QueryKey => ['Team']

useQueryTeam.queryKey = ['Team']

export const useQueryTeamDeedDistribution = (
  input: TGetDeedDistributionParams,
  options?: UseQueryOptions<TGetTeamDeedDistributionResponse>
) => {
  const queryFunction = () => getTeamDeedDistribution(input)

  return useQuery<TGetTeamDeedDistributionResponse>(
    useQueryTeamDeedDistribution.getFullQueryKey(input),
    queryFunction,
    options
  )
}
useQueryTeamDeedDistribution.getFullQueryKey = (
  input: TGetDeedDistributionParams
): QueryKey => ['deed_distribution', input]

useQueryTeamDeedDistribution.queryKey = (
  input: TGetDeedDistributionParams
): QueryKey => ['deed_distribution', input]

export const useQueryTeamObjectReport = (
  input: TGetTeamObjectReportParams,
  options?: UseQueryOptions<TGetTeamObjectReportResponse>
) => {
  const queryFunction = () => getTeamObjectReport(input)

  return useQuery<TGetTeamObjectReportResponse>(
    useQueryTeamObjectReport.getFullQueryKey(input),
    queryFunction,
    options
  )
}
useQueryTeamObjectReport.getFullQueryKey = (
  input: TGetTeamObjectReportParams
): QueryKey => ['object_report', input]

useQueryTeamObjectReport.queryKey = (
  input: TGetTeamObjectReportParams
): QueryKey => ['object_report', input]

export const useQueryUserProfiles = (
  options?: UseQueryOptions<TGetUserProfilesResponse>
) => {
  const queryFunction = () => getUserProfiles()

  return useQuery<TGetUserProfilesResponse>(
    useQueryTeams.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryUserProfiles.getFullQueryKey = (): QueryKey => ['profiles']

useQueryUserProfiles.queryKey = ['profiles']
