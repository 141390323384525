import http from '@/api/http'

import {
  TGetUsersResponse,
  TGetUserParams,
  TGetUserResponse,
  TCreateUserInBatchParams,
  TCreateUserInBatchResponse,
  TRenewalUsersParams,
  TRenewalUserResponse,
  TUnlinkUserParams,
  TUnlinkUserResponse,
  TGetUsersPaginatedParams,
  TGetUsersPaginatedResponse,
  TGetMeResponse,
  TGetUserProfileNameResponses,
  TUpdateMyAccountParams,
  TGetUserTeamsPaginatedParams,
  TGetUserTeamsPaginatedResponse,
} from './types'

export const getUsers = async () => {
  const response = await http.get<TGetUsersResponse>('/users/management/')

  return response.data
}

export const getUsersPaginated = async (filters: TGetUsersPaginatedParams) => {
  const response = await http.get<TGetUsersPaginatedResponse>(
    '/users/management/',
    {
      params: filters,
    }
  )

  return response.data
}

export const getUser = async ({ coUser }: TGetUserParams) => {
  const response = await http.get<TGetUserResponse>(
    `/users/management/${coUser}/`
  )

  return response.data
}

export const postUserPreRegistration = async (
  users: TCreateUserInBatchParams
) => {
  const response = await http.post<TCreateUserInBatchResponse>(
    '/users/management/pre_registration/',
    users
  )

  return response.data
}

export const postRenewalUser = async (
  params: TRenewalUsersParams
): Promise<TRenewalUserResponse> => {
  const response = await http.post<TRenewalUserResponse>(
    '/users/management/renewal/',
    params
  )

  return response.data
}

export const postUnlinkUser = async (
  params: TUnlinkUserParams
): Promise<TUnlinkUserResponse> => {
  const response = await http.post<TUnlinkUserResponse>(
    '/users/management/unlink/',
    params
  )

  return response.data
}

export const getProfileNames = async () => {
  const response = await http.get<TGetUserProfileNameResponses>(
    '/users/profile/'
  )

  return response.data
}

export const getMe = async () => {
  const response = await http.get<TGetMeResponse>('/users/myaccount/getme/')

  return response.data
}

export const getMyAccount = async ({ coUser }: TGetUserParams) => {
  const response = await http.get<TGetUserResponse>(
    `/users/myaccount/${coUser}/`
  )

  return response.data
}

export const patchMyAccount = async ({
  coUser,
  ...data
}: TUpdateMyAccountParams) => {
  const response = await http.patch<TGetUserResponse>(
    `/users/myaccount/${coUser}/`,
    data
  )

  return response.data
}

export const getUserTeams = async ({
  coUser,
  ...filters
}: TGetUserTeamsPaginatedParams) => {
  const response = await http.get<TGetUserTeamsPaginatedResponse>(
    `/users/${coUser}/teams/`,
    {
      params: filters,
    }
  )

  return response.data
}
