import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusTeamSystemProps, IStatusTeamSystemTagStyles } from './types'

const styles: IStatusTeamSystemTagStyles = {
  finished: {
    name: 'Finalizado',
    backgroundColor: '#E3F5E1',
    color: '#168821',
  },
  in_progress: {
    name: 'Em andamento',
    backgroundColor: '#DDE1E5',
    color: '#475671',
  },
  not_started: {
    name: 'Não inciado',
    backgroundColor: '#FFF3F2',
    color: '#E60000',
  },
  inactive: {
    name: 'Inativo',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
  pending: {
    name: 'Pendente',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
}

function StatusTag({ tag, ...rest }: IStatusTeamSystemProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.not_started

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
