import http from '@/api/http'

import {
  PostAuthGovBrParams,
  PostAuthGovBrResponse,
  GetAuthGovBrGetUrlParams,
  GetAuthGovBrGetUrlResponse,
  PostAuthLocalLoginParams,
  PostAuthLocalLoginResponse,
  PostAuthRefreshParams,
  PostAuthRefreshResponse,
  PostAuthLogoutParams,
  PostAuthLogoutResponse,
} from './types'

export const postAuthGovBr = async (params: PostAuthGovBrParams) => {
  const response = await http.post<PostAuthGovBrResponse>(
    '/auth/govbr/',
    params
  )

  return response.data
}

export const getAuthGovBrGetUrl = async (params: GetAuthGovBrGetUrlParams) => {
  const response = await http.get<GetAuthGovBrGetUrlResponse>(
    '/auth/govbr/get-url/'
  )

  return response.data
}

export const postAuthLocalLogin = async (params: PostAuthLocalLoginParams) => {
  const response = await http.post<PostAuthLocalLoginResponse>(
    '/auth/local/login/',
    params
  )

  return response.data
}

export const postAuthRefresh = async (params: PostAuthRefreshParams) => {
  const response = await http.post<PostAuthRefreshResponse>(
    '/auth/refresh/',
    params
  )

  return response.data
}

export const postAuthLogout = async (params: PostAuthLogoutParams) => {
  const response = await http.post<PostAuthLogoutResponse>(
    '/auth/logout/',
    params
  )

  return response.data
}
