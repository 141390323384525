import { ReactNode } from 'react'

import {
  IStatusAnalystTeamProps,
  status as analystStatus,
} from '@/components/Tag/StatusAnalystTeam/types'
import {
  IStatusTeamSystemProps,
  status as teamStatus,
} from '@/components/Tag/StatusTeamSystem/types'
import {
  IStatusVolumeTeamProps,
  status as teamVolumes,
} from '@/components/Tag/StatusVolumeTeam/types'
import { TeamMember } from '@/types/team.types'

export interface ITeamProps {
  id: string
  title: string
  description: string
  date: string
  supervisor: ITeamUser
  status: IStatusTeamSystemProps
  children?: ReactNode
  onDelete?: (id: string) => void
}

export interface ITeam {
  id?: number
  status_code?: string
  date?: string
}

export type ITeamUser = {
  label: string
  value: string
}

export interface ITeamDetailsProps {
  teamId: string
}

export interface ITeamAnalystsDetailProps {
  id: string
  name: string
  cpf: string
  email: string
  date: string
  status: IStatusAnalystTeamProps
}
export interface ITeamVolumeDetailProps {
  id: string
  name: string
  editora: string
  colecao: string
  analyst: ITeamUser[]
  versao: string
  exemplar: string
  formato: string
  status: IStatusVolumeTeamProps
}

export const ITeamStatusMapping: Record<string, teamStatus> = {
  FINALIZADO: 'finished',
  PENDENTE: 'pending',
  'EM ANDAMENTO': 'in_progress',
  'NÃO INICIADO': 'not_started',
  INATIVO: 'inactive',
}
export const ITeamAnalystStatusMapping: Record<string, analystStatus> = {
  INATIVO: 'inactive',
  ATIVO: 'active',
}

export const ITeamVolumesStatusMapping: Record<string, teamVolumes> = {
  CONCLUIDO: 'finished',
  'NÃO INICIADO': 'not_started',
  'EM ANDAMENTO': 'in_progress',
}

export interface ITeamAnalystProps {
  analysts: TeamMember[]
}
