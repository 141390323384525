import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react'

import { CurrentUser } from '@/types/user.types'

interface AuthContextType {
  userAuth: CurrentUser | null
  rolesProfile: string[] | undefined
  logoutUserAuth: () => void
  handleUserAuth: (userData: CurrentUser | null) => void
}

const UserAuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userAuth, setUserAuth] = useState<CurrentUser | null>(null)
  const [rolesProfile, setRolesProfile] = useState<string[] | undefined>([])

  const handleUserAuth = (userData: CurrentUser | null) => {
    if (userData || rolesProfile?.length === 0) {
      const arrayRolesFormated = userData?.profile?.map(item => item.name)

      setUserAuth(userData)
      setRolesProfile(arrayRolesFormated)
      localStorage.setItem('sare_user_auth', JSON.stringify(userData))
    } else {
      setUserAuth(null)
      setRolesProfile([])
      localStorage.removeItem('sare_user_auth')
    }
  }

  const logoutUserAuth = () => {
    setUserAuth(null)
    setRolesProfile([])
    localStorage.removeItem('sare_user_auth')
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('sare_user_auth')
    const storedUserFormated = storedUser && JSON.parse(storedUser)
    const arrayRolesFormated = storedUserFormated?.profile?.map(
      (item: { name: string }) => item.name
    )

    if (storedUser) {
      setUserAuth(JSON.parse(storedUser))
      setRolesProfile(arrayRolesFormated)
    }
  }, [])

  return (
    <UserAuthContext.Provider
      value={{ userAuth, rolesProfile, handleUserAuth, logoutUserAuth }}
    >
      {children}
    </UserAuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(UserAuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
