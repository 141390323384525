import React from 'react'

import { useFormikContext } from 'formik'

import { BasicSelect } from '@/components/Forms'
import { IBasicSelectProps } from '@/components/Forms/BasicSelect/types'

interface NestedFieldSelectProps extends Omit<IBasicSelectProps, 'onChange'> {}

export const NestedFieldSelect: React.FC<NestedFieldSelectProps> = props => {
  const { setFieldValue } = useFormikContext()

  return (
    <BasicSelect
      {...props}
      onChange={(value: string) => {
        setFieldValue(props.name, value)
      }}
    />
  )
}

export default NestedFieldSelect
