import React from 'react'

import { Flex } from '@chakra-ui/react'

import AnalysisVolume from '../AnalysisVolume'
import FormAnswersVolume from '../FormAnswersVolume'

const DetailsVolume = () => {
  // TODO: RQ-12 Waiting for api to implement validation
  const roleProfile = 'supervisor'

  const renderDetailsVolume = () => {
    if (roleProfile === 'supervisor') {
      return <FormAnswersVolume />
    } else {
      return <AnalysisVolume />
    }
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      {renderDetailsVolume()}
    </Flex>
  )
}

export default DetailsVolume
